import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectUser = get('user.user');
export const selectUserPermissions = createSelector(
  selectUser,
  (user) => (user ? user.permissions || [] : []),
);
export const selectIsAuthenticated = get('user.isAuthenticated');

export const selectUserRole = createSelector(
  selectUser,
  (user) => (user ? user.role : ''),
);
export const selectIsAdmin = createSelector(
  selectUserRole,
  (role) => 'ADMIN' === role,
);
