import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route, Switch,} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import Navbar from './component/navbar';
import Token from './container/token';
import {
  Countries,
  Dashboard,
  EditCountry,
  EditFunder,
  EditPartner,
  EditProject,
  EditType,
  EditVenue,
  EntityNotFound,
  EventCreate,
  EventEdit,
  EventMail,
  Events,
  Funders,
  IFrameSearch,
  NotFound,
  Partners,
  People,
  Person,
  Projects,
  Publications,
  PublicSearch,
  Settings,
  Types,
  ProjectCalls,
  EditProjectCall,
  Venues,
} from './container/admin';
import MyPublications from './container/researcher/publication/MyPublications';
import {history} from './state/store';
import requireAuth from './container/auth';
import {FETCH_USER_ID} from './state/app/appActions';
import Loader from './component/loader/loader';
import 'react-notifications-component/dist/theme.css';
import Profile from './container/profile/profile';
import {selectIsAdmin} from './state/user/selectors';

import AbstractOfTalk from './container/admin/publications/AbstractOfTalk';
import Book from './container/admin/publications/Book';
import Bookchapter from './container/admin/publications/Bookchapter';
import ConferencePaper from './container/admin/publications/ConferencePaper';
import Journal from './container/admin/publications/Journal';
import Patent from './container/admin/publications/Patent';
import Proceeding from './container/admin/publications/Proceeding';
import Report from './container/admin/publications/Report';
import Talk from './container/admin/publications/Talk';
import These from './container/admin/publications/These';
import PublicationSelector from './component/admin/publication/PublicationSelector';
import FileLoading from './container/public/FileLoading';
import {addPublicPrivate} from './utils';

const App = (props) => {
  const { loading, fetchUserId, isAdmin } = props;
  useEffect(() => {
    fetchUserId();
  }, [fetchUserId]);

  return (
    loading
      ? <Loader centered />
      : (
        <>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/iframe" component={IFrameSearch} />
               {/* loading pdf files */}
               <Route path="/files/public/publications/:pathpart+" component={() => <FileLoading sPrivate={false}
                backendPathGenerator = {(pathpart) => `publications/files/${pathpart}`}
              />} />
               <Route path="/files/private/publications/:pathpart+" component={() => <FileLoading isPrivate={true}
                backendPathGenerator = {(pathpart) => `publications/files/${pathpart}`}
              />} />
              { /* loading project media repo files */ }
              <Route path="/files/private/project/:pathpart+" component={() => <FileLoading backendPathGenerator = {(pathpart) => `files/downloadFile/project/${addPublicPrivate(pathpart, 'private')}`} isPrivate={true} />} />
              <Route path="/files/public/project/:pathpart+" component={() => <FileLoading backendPathGenerator = {(pathpart) => `files/downloadFile/project/${addPublicPrivate(pathpart, 'public')}`} isPrivate={false} />} />
             
              { /* loading publication media repo files */ }
              <Route path="/files/private/:pathpart+" component={() => <FileLoading backendPathGenerator = {(pathpart) => `files/getFile/${addPublicPrivate(pathpart, 'private')}`} isPrivate={true} />} />
              <Route path="/files/public/:pathpart+" component={() => <FileLoading backendPathGenerator = {(pathpart) => `files/getFile/${addPublicPrivate(pathpart, 'public')}`} isPrivate={false} />} />
              
              <Route component={() => (
                <>
                  <ReactNotification />
                  <Navbar />
                  <Switch>
                    <Route exact path="/" component={PublicSearch} />
                    <Route exact path="/token/:token" component={Token} />
                    <Route exact path="/admin/" component={requireAuth(Dashboard, 'PEOPLE_STATISTICS')} />
                    <Route exact path="/admin/people" component={requireAuth(People, 'PEOPLE_READ')} />
                    <Route exact path="/admin/people/new" component={requireAuth(Person, 'PEOPLE_WRITE')} />
                    <Route exact path="/admin/people/:id([0-9]+)" component={requireAuth(Person, 'PEOPLE_WRITE')} />
                    <Route exact path="/admin/projects" component={requireAuth(Projects, 'PROJECTS_READ')} />
                    <Route exact path="/admin/projects/:id([0-9]+)" component={requireAuth(EditProject, 'PROJECTS_WRITE')} label="project_create" />
                    <Route exact path="/admin/projects/new" component={requireAuth(EditProject, 'PROJECTS_WRITE')} />
                    <Route exact path="/admin/events" component={requireAuth(Events, 'EVENTS_READ')} />
                    <Route exact path="/admin/events/new" component={requireAuth(EventCreate, 'EVENTS_WRITE')} />
                    <Route exact path="/admin/events/send-email" component={requireAuth(EventMail, 'EVENTS_WRITE')} />
                    <Route exact path="/admin/events/:id([0-9]+)" component={requireAuth(EventEdit, 'EVENTS_WRITE')} />
                    <Route exact path="/admin/publications" component={requireAuth(Publications, 'PUBLICATIONS_DATA_READ')} />
                    <Route exact path="/admin/settings" component={requireAuth(Settings, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/countries" component={requireAuth(Countries, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/countries/new" component={requireAuth(EditCountry, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/countries/:id([0-9]+)" component={requireAuth(EditCountry, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/funders" component={requireAuth(Funders, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/funders/new" component={requireAuth(EditFunder)} />
                    <Route exact path="/admin/settings/funders/:id([0-9]+)" component={requireAuth(EditFunder, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/projecttypes" component={requireAuth(Types, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/projecttypes/:id" component={requireAuth(EditType, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/projectCalls" component={requireAuth(ProjectCalls, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/projectCalls/:id" component={requireAuth(EditProjectCall, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/partners" component={requireAuth(Partners, 'SETTINGS_READ')} />
                    <Route exact path="/admin/settings/partners/:id" component={requireAuth(EditPartner, 'SETTINGS_WRITE')} />
                    <Route exact path="/admin/settings/venues" component={requireAuth(Venues, 'VENUES_READ')} />
                    <Route exact path="/admin/settings/venues/new" component={requireAuth(EditVenue, 'VENUES_WRITE')} />
                    <Route exact path="/admin/settings/venues/:type/:id" component={requireAuth(EditVenue, 'VENUES_WRITE')} />
                    <Route exact path="/researcher/new" component={requireAuth(PublicationSelector, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/profile" component={requireAuth(Profile)} />
                    <Route exact path="/researcher" component={requireAuth(MyPublications, 'PUBLICATIONS_DATA_READ')} />
                    <Route exact path="/admin/projects/notfound" component={EntityNotFound} />
                    <Route exact path="/admin/publications/:type/error" component={EntityNotFound} />
                    <Route exact path="/researcher/publications/:type/error" component={EntityNotFound} />

                    <Route exact path="/researcher/publications/addAbstractOfTalk" component={requireAuth(AbstractOfTalk, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/updateAbstractOfTalk/:id" component={requireAuth(() => <AbstractOfTalk isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addBook" component={requireAuth(Book, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateBook/:id" component={requireAuth(() => <Book isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addBookchapter" component={requireAuth(Bookchapter, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateBookchapter/:id" component={requireAuth(() => <Bookchapter isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addConferencePaper" component={requireAuth(ConferencePaper, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateConferencePaper/:id" component={requireAuth(() => <ConferencePaper isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addJournal" component={requireAuth(Journal, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateJournal/:id" component={requireAuth(() => <Journal isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addPatent" component={requireAuth(Patent, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updatePatent/:id" component={requireAuth(() => <Patent isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addProceeding" component={requireAuth(Proceeding, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateProceeding/:id" component={requireAuth(() => <Proceeding isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addReport" component={requireAuth(Report, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateReport/:id" component={requireAuth(() => <Report isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addTalk" component={requireAuth(Talk, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateTalk/:id" component={requireAuth(() => <Talk isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />
                    <Route exact path="/researcher/publications/addThese" component={requireAuth(These, 'PUBLICATIONS_DATA_WRITE')} isResearcher />
                    <Route exact path="/researcher/publications/updateThese/:id" component={requireAuth(() => <These isResearcher={!isAdmin} />, 'PUBLICATIONS_DATA_WRITE')} />

                    <Route component={NotFound} />
                  </Switch>
                </>
              )}
              />
            </Switch>

          </ConnectedRouter>
        </>
      ));
};


App.propTypes = {
  fetchUserId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.app.isLoading,
  error: state.app.error,
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserId: () => dispatch((FETCH_USER_ID())),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
