export const FETCH_ALL_PROJECT_CALLS = 'FETCH_ALL_PROJECT_CALLS';
export const FETCH_ALL_PROJECT_CALLS_SUCCESS = 'FETCH_ALL_PROJECT_CALLS_SUCCESS';
export const FETCH_ALL_PROJECT_CALLS_ERROR = 'FETCH_ALL_PROJECT_CALLS_ERROR';
export const DELETE_PROJECT_CALL = 'DELETE_PROJECT_CALL';
export const DELETE_PROJECT_CALL_ERROR = 'DELETE_PROJECT_CALL_ERROR';
export const DELETE_PROJECT_CALL_SUCCESS = 'DELETE_PROJECT_CALL_SUCCESS';
export const SET_PROJECT_CALL_FILTER_NAME = 'SET_PROJECT_CALL_FILTER_NAME';

export const fetchAllProjectCalls = () => ({ type: FETCH_ALL_PROJECT_CALLS });
export const fetchAllProjectCallsSuccess = (data) => ({ type: FETCH_ALL_PROJECT_CALLS_SUCCESS, data });
export const fetchAllProjectCallsError = (error) => ({ type: FETCH_ALL_PROJECT_CALLS_ERROR, error });

export const deleteProjectCall = (projectCallId) => ({ type: DELETE_PROJECT_CALL, projectCallId });
export const deleteProjectCallSuccess = () => ({ type: DELETE_PROJECT_CALL_SUCCESS });
export const deleteProjectCallError = (error) => ({ type: DELETE_PROJECT_CALL_ERROR, error });
export const setProjectCallFilterName = (data) => ({ type: SET_PROJECT_CALL_FILTER_NAME, data });
