import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  fetchOneVenue as fetchOneVenueAction,
  updateVenue as updateVenueAction,
} from '../../../state/venue/venueActions';
import { fetchAllVenues as fetchAllVenuesAction } from '../../../state/venues/venuesActions';
import '../../../styles/forms.scss';
import { Loader } from '../../../component/index';

function EditVenue(props) {
  const { type, id } = useParams();
  const {
    loading,
    venue,
    fetchVenue,
    updateVenue,
    fetchAllVenues,
    journals,
    conferences,
  } = props;
  const allCategories = [
    { value: 1, label: 'TOP' },
    { value: 2, label: 'VERY GOOD' },
    { value: 3, label: 'GOOD/OK' },
    { value: 4, label: 'TO AVOID' },
    { value: 5, label: 'SPECIAL' },
    { value: 6, label: 'NONE' },
  ];
  const journalAttributes = [
    { value: 'international', label: 'international' },
    { value: 'reviewed', label: 'reviewed' },
    { value: 'scientific', label: 'scientific' },
  ];
  const conferenceAttributes = [
    { value: 'international', label: 'international' },
    { value: 'reviewed', label: 'reviewed' },
    { value: 'published', label: 'published' },
  ];

  const [stateAttrOptions, setAttributesOption] = useState([]);
  const [venues, setVenues] = useState({ source: [], field: '' });

  const allVenueTypes = [{ value: 'journal', label: 'journal' }, { value: 'conference', label: 'conference' }];


  useEffect(() => {
    fetchAllVenues();
    if (id && type) {
      fetchVenue({ id, type });
    }
  }, [
    fetchVenue,
    fetchAllVenues,
    id,
    type,
  ]);

  const attributesOptions = () => {
    let options;
    if (type === 'journal') {
      options = journalAttributes;
    } else if (type === 'conference') {
      options = conferenceAttributes;
    }
    return options;
  };

  const getInitial = () => {
    let initialValues;
    if (type && id) {
      initialValues = {
        id,
        venue: type === 'journal' ? venue.title : venue.conference,
        type: allVenueTypes.find((vt) => vt.value === type).value,
        acronym: venue.acronym,
        publisher: venue.publisher,
        proceedings: venue.proceedings || '',
        attributes: venue.attributes ? venue.attributes.split(',').map((attr) => ({ label: attr, value: attr })) : [],
        category: venue.category,
      };
    } else {
      initialValues = {
        venue: '',
        type: '',
        acronym: '',
        publisher: '',
        attributes: [],
        category: null,
      };
    }
    return initialValues;
  };

  const validationSchema = Yup.object()
    .shape({
      venue: Yup.string()
        .notOneOf(
          venues.source.map((v) => v[venues.field]).filter((elem) => elem !== venue.title),
          'Given name is already taken',
        )
        .required('Venue name required')
        .nullable(),
      type: Yup.string()
        .required('Venue type is required')
        .nullable(),
      attributes: Yup.array()
        .nullable(),
      acronym: Yup.string()
        .required('Acronym is required')
        .nullable(),
      category: Yup.number()
        .nullable(),
    });

  const handleTypeChange = (val, setFieldValue) => {
    let attrOptions = [];
    if (val.value === 'journal') {
      attrOptions = journalAttributes;
      setVenues({ source: journals, field: 'title' });
    } else if (val.value === 'conference') {
      attrOptions = conferenceAttributes;
      setVenues({ source: conferences, field: 'conference' });
    }
    setFieldValue('type', val.value);
    setAttributesOption(attrOptions);
  };

  const confirmationText = id && type ? 'Save' : 'Create';

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{id && type ? 'Edit venue' : 'Create venue'}</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <>
                    <Formik
                      initialValues={getInitial()}
                      validationSchema={validationSchema}
                      enableReinitialize
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        updateVenue({
                          ...values,
                          id,
                          type: type || values.type,
                          attributes: values.attributes
                            .map((v) => v.value)
                            .join(','),
                        });
                        setSubmitting(false);
                      }}
                    >
                      { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Venue name</Form.Label>
                                <Form.Text muted>
                                Use $Y for recurring venues (e.g. 'Asiacrypt $Y' = Asiacrypt 2023), use $E for editions (e.g. '$E International Workshop on...' = 26th Workshop on...)
                                </Form.Text>
                                <Form.Control
                                  name="venue"
                                  value={values.venue}
                                  defaultValue={values.venue}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.venue && errors.venue ? 'error' : null}
                                />
                                { touched.venue && errors.venue ? (<div className="error-message">{errors.venue}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Acronym</Form.Label>
                                <Form.Text muted>
                                Do not use $Y or $E here
                                </Form.Text>
                                <Form.Control
                                  name="acronym"
                                  value={values.acronym}
                                  defaultValue={values.acronym}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.acronym && errors.acronym ? 'error' : null}
                                />
                                { touched.acronym && errors.acronym ? (<div className="error-message">{errors.acronym}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Publisher</Form.Label>
                                <Form.Control
                                  name="publisher"
                                  value={values.publisher}
                                  defaultValue={values.publisher}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.publisher && errors.publisher ? 'error' : null}
                                />
                                { touched.publisher && errors.publisher ? (<div className="error-message">{errors.publisher}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Category</Form.Label>
                                <Select
                                  name="category"
                                  options={allCategories}
                                  value={values.category ? (
                                    allCategories.find((cat) => cat.value === values.category)
                                  ) : undefined}
                                  onChange={(val) => setFieldValue('category', val.value)}
                                />
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          {
                            !id && !type ? (
                              <Form.Row>
                                <Col>
                                  <Form.Group>
                                    <Form.Label>Venue type</Form.Label>
                                    <Select
                                      name="type"
                                      options={allVenueTypes}
                                      value={allVenueTypes.find((av) => av.value === values.type)}
                                      onChange={(val) => handleTypeChange(val, setFieldValue)}
                                      className={touched.type && errors.type ? 'error' : null}
                                    />
                                    { touched.type && errors.type ? (<div className="error-message">{errors.type}</div>) : null}
                                  </Form.Group>
                                </Col>
                              </Form.Row>
                            )
                              : <></>
                          }
                          {
                            values.type === 'conference' ? (
                              <Form.Row>
                                <Col>
                                  <Form.Group>
                                    <Form.Label>Proceedings</Form.Label>
                                    <Form.Control
                                      name="proceedings"
                                      value={values.proceedings}
                                      defaultValue={values.proceedings}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={touched.proceedings && errors.proceedings ? 'error' : null}
                                    />
                                    { touched.proceedings && errors.proceedings ? (<div className="error-message">{errors.proceedings}</div>) : null}
                                  </Form.Group>
                                </Col>
                              </Form.Row>
                            )
                              : <></>
                          }
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Attributes</Form.Label>
                                <Select
                                  name="attributes"
                                  options={id && type ? attributesOptions() : stateAttrOptions}
                                  isMulti
                                  value={values.attributes}
                                  onChange={(val) => setFieldValue('attributes', val)}
                                />
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? 'Submitting...' : confirmationText}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditVenue.propTypes = {
  loading: PropTypes.bool.isRequired,
  journals: PropTypes.arrayOf(PropTypes.any).isRequired,
  conferences: PropTypes.arrayOf(PropTypes.any).isRequired,
  venue: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAllVenues: PropTypes.func.isRequired,
  fetchVenue: PropTypes.func.isRequired,
  updateVenue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.venue.isLoading,
  venue: state.venue.data,
  journals: state.venues.journals,
  conferences: state.venues.conferences,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllVenues: () => dispatch(fetchAllVenuesAction()),
  fetchVenue: (data) => dispatch(fetchOneVenueAction(data)),
  updateVenue: (data) => dispatch(updateVenueAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVenue);
