import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card, Col, Container, Form, Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../../component/loader/loader';
import {
  fetchAllEvents as fetchAllEventsAction,
  sendMail as sendMailAction,
} from '../../../state/events/eventsActions';

export const EventMail = (props) => {
  const [emailAddresses, setEmailAddresses] = useState([]);

  const initValues = {
    event: null,
    email: '',
  };

  const {
    loading, events, fetchAllEvents, sendEmail,
  } = props;
  useEffect(() => {
    fetchAllEvents();
  }, [fetchAllEvents]);

  const eventOptions = events && Array.isArray(events) ? events.map((event) => ({
    label: event.title,
    value: event,
  })) : [];

  const handleEventChange = (event, setFieldValue) => {
    setFieldValue('event', event.value);
  };
  // change with the real list coming from the input field
  const handleSubmit = (values, { setSubmitting }) => {
    sendEmail({ values, emailAddresses });
    setSubmitting(false);
  };

  const handleEmailChange = (val, setFieldValue) => {
    const newEmails = val
      .split(',')
      .map((email) => email.trim()
        .split(' '))
      .flat().filter((e) => e.trim().length > 1);
    emailAddresses.push(newEmails);
    const emails = _.uniq(emailAddresses.flat());
    setEmailAddresses(emails);
    setFieldValue('email', emails);
  };

  const handleChange = (val, setFieldValue) => {
    const changedValues = val ? (
      val.map((value) => value.value)
    ) : [];
    setEmailAddresses(changedValues);
    setFieldValue('email', changedValues);
  };

  const validationSchema = Yup.object()
    .shape({
      event: Yup.object()
        .required('Event must be selected')
        .nullable(),
      email: Yup.array()
        .of(Yup.string().email((val) => `- "${val.value}" is not a valid email  `))
        .required('There must be atleast one E-mail (required)')
        .nullable(),
    });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Send mail</Card.Header>
            {
              loading ? (
                <Loader />
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={initValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    // eslint-disable-next-line no-shadow
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Col style={{ marginTop: '2em' }}>
                          <Form.Label>Events</Form.Label>
                          <Select
                            name="event"
                            options={eventOptions}
                            isDisabled={loading}
                            onChange={(val) => handleEventChange(val, setFieldValue)}
                            className={touched.event && errors.event ? 'error' : null}
                          />
                          { touched.event && errors.event ? (
                            <div className="error-message">
                              {errors.event}
                            </div>
                          ) : null}
                        </Col>
                        <Col style={{ margin: '2em 0' }}>
                          <Form.Label>Email</Form.Label>
                          <CreatableSelect
                            name="email"
                            isClearable
                            isMulti
                            onChange={(val) => handleChange(val, setFieldValue)}
                            onCreateOption={(val) => handleEmailChange(val, setFieldValue)}
                            value={emailAddresses.map((value) => ({ label: value, value }))}
                            className={touched.email && errors.email ? 'error' : null}
                          />
                          { touched.email && errors.email ? (
                            <div className="error-message">
                              {errors.email}
                            </div>
                          ) : null}
                        </Col>
                        <Col style={{ marginTop: '1em' }}>
                          <Button
                            type="submit"
                            variant="primary"
                            className="btn btn-success ml-auto"
                            block
                            disabled={isSubmitting}
                          >
                            {
                              isSubmitting ? 'Sending' : 'send'
                            }
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              )
            }
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

EventMail.propTypes = {
  loading: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchAllEvents: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.events.isLoading,
  events: state.events.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllEvents: () => dispatch(fetchAllEventsAction()),
  sendEmail: (data) => dispatch(sendMailAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventMail);
