import {
  CREATE_ABSTRACT_OF_TALK,
  CREATE_ABSTRACT_OF_TALK_ERROR,
  CREATE_ABSTRACT_OF_TALK_SUCCESS,
  FETCH_ABSTRACT_OF_TALK,
  FETCH_ABSTRACT_OF_TALK_ERROR,
  FETCH_ABSTRACT_OF_TALK_SUCCESS,
  LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_SUCCESS,
  UPDATE_ABSTRACT_OF_TALK,
  UPDATE_ABSTRACT_OF_TALK_ERROR,
  UPDATE_ABSTRACT_OF_TALK_SUCCESS,
} from './actions';
import {calculatePageCount} from "../../../../utils";

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateAbstractOfTalkRespones = (response) => ({
  types: 'abstractOfAbstractOfTalk',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  from: response.publication.from,
  to: response.publication.to,
  proceedingsId: response.publication.id,
  conference: response.publication.conference.id,
  state: response.publication.state,
  city: response.publication.city,
  country: response.publication.country,
  projects: response.projects,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
});

const publicationAbstractOfTalk = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ABSTRACT_OF_TALK_SUCCESS:
    case FETCH_ABSTRACT_OF_TALK_SUCCESS:
      return {
        ...state,
        details: dehydrateAbstractOfTalkRespones(action.payload),
        isLoading: false,
      };

    case CREATE_ABSTRACT_OF_TALK_ERROR:
    case UPDATE_ABSTRACT_OF_TALK_ERROR:
    case FETCH_ABSTRACT_OF_TALK_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_ABSTRACT_OF_TALK_SUCCESS:
    case LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_ABSTRACT_OF_TALK:
    case FETCH_ABSTRACT_OF_TALK:
    case UPDATE_ABSTRACT_OF_TALK:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationAbstractOfTalk;
