import React, { useState, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  ADD_WORKPACKETS_TO_PROJECT,
  FETCH_PROJECT_WORKPACKETS,
  DELETE_PROJECT_WORKPACKET,
  UPDATE_PROJECT_WORKPACKET,
} from '../../../state/project/projectActions';

/* eslint-disable jsx-a11y/label-has-associated-control */
const WorkPackageContainer = (props) => {
  const {
    projectId,
    fetchWorkPackets,
    addWorkPacket,
    deleteWorkPacket,
    workPackets,
    isWpLoading,
    updateWorkPacket,
  } = props;
  const initialValues = {
    id: '',
    title: '',
    number: '',
    description: '',
    startPM: '',
    endPM: '',
    cosicLead: false,
    cosicParticipation: false,
  };
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [initialValuesEdit, setEditFormValues] = useState(initialValues);
  const [wpDeleteId, setWorkPacketDeleteId] = useState(initialValues);

  const handleCloseEdit = () => {
    setShow(false);
    setEditFormValues(initialValues);
  };
  const handleCloseDelete = () => {
    setWorkPacketDeleteId(null);
    setShowDelete(false);
  };
  const handleShow = (id) => {
    const workPackage = workPackets.find((obj) => (obj.id === id));
    setEditFormValues(workPackage);
    setShow(true);
  };
  const handleShowDelete = (wpId) => {
    setWorkPacketDeleteId(wpId);
    setShowDelete(true);
  };
  const handleDelete = () => {
    deleteWorkPacket(wpDeleteId);
    handleCloseDelete();
  };

  useEffect(() => {
    fetchWorkPackets(projectId);
  }, [
    fetchWorkPackets,
    projectId,
  ]);

  const displayForm = (intent) => (
    <Formik
      initialValues={intent === 'EDIT' ? initialValuesEdit : initialValues}
      validate={(values) => {
        const errors = {};
        if (!values.number) {
          errors.number = 'Required';
        } else if (!values.title) {
          errors.title = 'Required';
        } else if (!values.startPM) {
          errors.startPM = 'Required';
        } else if (!values.endPM) {
          errors.endPM = 'Required';
        } else if (values.startPM > values.endPM) {
          errors.description = 'Start value must be smaller than startPM';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const data = {
          ...values,
          projectId,
        };
        if (intent !== 'EDIT') {
          delete data.id;
          addWorkPacket(data);
        } else {
          updateWorkPacket(data);
          setSubmitting(false);
          handleCloseEdit();
        }
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Label>WP Number</Form.Label>
              <Form.Control
                type="number"
                name="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number}
                className={touched.number && errors.number ? 'error' : null}
              />
              {errors.number && touched.number && errors.number}
            </Col>
            <Col sm={4}>
              <Form.Label>WP Name</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className={touched.title && errors.title ? 'error' : null}
              />
              {errors.title && touched.title && errors.title}
            </Col>
            <Col>
              <Form.Label>Start month (nr)</Form.Label>
              <Form.Control
                type="number"
                name="startPM"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.startPM}
                className={touched.startPM && errors.startPM ? 'error' : null}
              />
              {errors.startPM && touched.startPM && errors.startPM}
            </Col>
            <Col>
              <Form.Label>End month (nr)</Form.Label>
              <Form.Control
                type="number"
                name="endPM"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.endPM}
                className={touched.endPM && errors.endPM ? 'error' : null}
              />
              {errors.endPM && touched.endPM && errors.endPM}
            </Col>
            <Col>
              <Form.Label>COSIC is lead</Form.Label>
              <Form.Check
                name="cosicLead"
                onChange={(e) => setFieldValue('cosicLead', e.target.checked)}
                onBlur={(e) => setFieldValue('cosicLead', e.target.checked)}
                checked={values.cosicLead}
                value={values.cosicLead}
                className={touched.cosicLead && errors.cosicLead ? 'error' : null}
              />
              {errors.cosicLead && touched.cosicLead && errors.cosicLead}
            </Col>

            <Col>
              <Form.Label>COSIC is participant</Form.Label>
              <Form.Check
                name="cosicParticipation"
                onChange={(e) => setFieldValue('cosicParticipation', e.target.checked)}
                onBlur={(e) => setFieldValue('cosicParticipation', e.target.checked)}
                checked={values.cosicParticipation}
                value={values.cosicParticipation}
                className={touched.cosicParticipation && errors.cosicParticipation ? 'error' : null}
              />
              {
                errors.cosicParticipation
                && touched.cosicParticipation
                && errors.cosicParticipation
              }
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label>WP Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                className={touched.description && errors.description ? 'error' : null}
              />
              {errors.description && touched.description && errors.description}
            </Col>
          </Form.Row>
          <Form.Row style={{ marginTop: '2em' }}>
            <Button
              type="submit"
              variant="primary"
              className="btn btn-success ml-auto"
              disabled={isSubmitting || isWpLoading}
              block
            >
              {intent === 'EDIT' ? 'Edit' : 'Add'}
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );

  const wpModalFormEdit = () => (
    <Modal
      show={show}
      onHide={handleCloseEdit}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>{displayForm('EDIT')}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const wpModalFormDelete = () => (
    <Modal
      show={showDelete}
      onHide={handleCloseDelete}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete WorkPacket?</Modal.Title>
      </Modal.Header>
      <Modal.Body>This action is permanent.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDelete}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const populateWorkPackets = () => (
    workPackets ? (
      workPackets.sort((a, b) => a.number - b.number).map((wp) => (
        <tr key={wp.id}>
          <th>{wp.number}</th>
          <td>{wp.title}</td>
          <td>{wp.startPM}</td>
          <td>{wp.endPM}</td>
          <td>{wp.cosicLead ? 'Yes' : 'No'}</td>
          <td>{wp.cosicParticipation ? 'Yes' : 'No'}</td>
          <td className="text-right">
            <button
              style={{ width: '48%', float: 'left' }}
              className="btn btn-sm btn-secondary ml-auto"
              onClick={() => handleShow(wp.id)}
            >
              Edit
            </button>
            <button
              style={{ width: '48%', float: 'right' }}
              className="btn btn-sm btn-danger ml-auto"
              onClick={() => handleShowDelete(wp.id)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))
    ) : <div />
  );

  return (
    <Container fluid>
      {wpModalFormEdit()}
      {wpModalFormDelete()}
      <Row>
        <Col>
          <Card className="filter-card">
            <table className="table table-card table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">WP Nr.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Start month</th>
                  <th scope="col">End month</th>
                  <th scope="col">Lead</th>
                  <th scope="col">participant</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {populateWorkPackets()}
                {isWpLoading ? (
                  <tr>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                    <th>
                      <i>Loading...</i>
                    </th>
                  </tr>
                ) : <tr />}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {displayForm('ADD')}
        </Col>
      </Row>
    </Container>
  );
};

WorkPackageContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  workPackets: PropTypes.arrayOf(PropTypes.any),
  isWpLoading: PropTypes.bool.isRequired,
  addWorkPacket: PropTypes.func.isRequired,
  deleteWorkPacket: PropTypes.func.isRequired,
  fetchWorkPackets: PropTypes.func.isRequired,
  updateWorkPacket: PropTypes.func.isRequired,
};

WorkPackageContainer.defaultProps = {
  workPackets: [],
};

const mapStateToProps = (state) => ({
  loading: state.project.isLoading,
  isWpLoading: state.project.workPackets.isLoading,
  workPackets: state.project.workPackets.data,
});

const mapDispatchToProps = (dispatch) => ({
  addWorkPacket: (data) => dispatch(ADD_WORKPACKETS_TO_PROJECT(data)),
  deleteWorkPacket: (id) => dispatch(DELETE_PROJECT_WORKPACKET(id)),
  fetchWorkPackets: (id) => dispatch(FETCH_PROJECT_WORKPACKETS(id)),
  updateWorkPacket: (data) => dispatch(UPDATE_PROJECT_WORKPACKET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkPackageContainer);
