import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_BOOK,
  createBookError,
  createBookSuccess,
  FETCH_BOOK,
  fetchBookError,
  fetchBookSuccess,
  LOAD_BOOK_REQUIREMENTS,
  loadBookRequirementsError,
  loadBookRequirementsSuccess,
  UPDATE_BOOK,
  updateBookError,
  updateBookSuccess,
  fetchBook as fetchBookAction,
} from './actions';
import {
  ensurePeople,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createBook as createBookMutation,
  QuerybookById as fetchBookQuery,
  updateBook as updateBookMutation,
} from '../../../../graphql/queries/publications';

export function* createBook({ payload }) {
  const response = yield call(axios.post, '/', { query: createBookMutation(payload) });
  yield put(createBookSuccess());

  const { id } = response.data.data.createBook;
  yield put(push(`/researcher/publications/updateBook/${id}`));
  showSuccessMessage('Book created successfully');
}

export function* fetchBook({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchBookQuery(payload.id) });
  yield put(fetchBookSuccess(response.data.data.bookById));
}

export function* updateBook({ payload }) {
  const response = yield call(axios.post, '/', { query: updateBookMutation(payload) });
  yield put(updateBookSuccess(response.data.data.updateBook));
  showSuccessMessage('Book updated successfully');
}

export function* loadBook({ payload }) {
  if (payload.id) {
    yield put(fetchBookAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadBookRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
  ]);
}

export default function* () {
  yield takeLatest(
    CREATE_BOOK,
    catchErrors({
      onErrorAction: createBookError,
      overrideErrorMessage: 'Could not create publication of type book',
    })(createBook),
  );

  yield takeLatest(
    FETCH_BOOK,
    catchErrors({
      onErrorAction: fetchBookError,
      overrideErrorMessage: 'Could not fetch book',
    })(fetchBook),
  );

  yield takeLatest(
    LOAD_BOOK_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadBookRequirementsError,
    })(loadBook),
  );

  yield takeLatest(
    UPDATE_BOOK,
    catchErrors({
      onErrorAction: updateBookError,
      overrideErrorMessage: 'Could not update publication of type book',
    })(updateBook),
  );
}
