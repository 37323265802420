import moment from 'moment';
import {
  ADD_HISTORY_STATUS,
  ADD_HISTORY_STATUS_ERROR,
  ADD_HISTORY_STATUS_SUCCESS,
  ADD_MEMBERSHIP,
  ADD_MEMBERSHIP_ERROR,
  ADD_MEMBERSHIP_SUCCESS,
  REMOVE_HISTORY_STATUS,
  REMOVE_HISTORY_STATUS_ERROR,
  REMOVE_HISTORY_STATUS_SUCCESS,
  REMOVE_MEMBERSHIP,
  REMOVE_MEMBERSHIP_ERROR,
  REMOVE_MEMBERSHIP_SUCCESS,
  FETCH_PERSON_PUBLICATIONS,
  FETCH_PERSON_PUBLICATIONS_ERROR,
  FETCH_PERSON_PUBLICATIONS_SUCCESS,
  FETCH_PERSON_AWARD,
  FETCH_PERSON_AWARD_SUCCESS,
  FETCH_PERSON_AWARD_ERROR,
  MUTATE_PERSON_ADD_AWARD,
  MUTATE_PERSON_ADD_AWARD_SUCCESS,
  MUTATE_PERSON_ADD_AWARD_ERROR,
  MUTATE_PERSON_REMOVE_AWARD,
  MUTATE_PERSON_REMOVE_AWARD_SUCCESS,
  MUTATE_PERSON_REMOVE_AWARD_ERROR,
  MUTATE_PERSON_UPDATE_AWARD,
  MUTATE_PERSON_UPDATE_AWARD_SUCCESS,
  MUTATE_PERSON_UPDATE_AWARD_ERROR,
} from './personActions';

const defaultInitialValues = (person) => ({
  id: person.id || undefined,
  active: person.active || false,
  biography: person.biography || '',
  birthDate: person.birthDate ? moment(person.birthDate)
    .format('YYYY-MM-DD') : '',
  currentStatus: person.currentStatus || '',
  email: person.email || '',
  gender: person.gender || '',
  firstname: person.firstname || '',
  needsDesk: person.needsDesk || false,
  nationality: (person.nationality && person.nationality.id) || '',
  postal: person.postal || '',
  website: person.website || '',
  privateEmail: person.privateEmail || '',
  privatePhone: person.privatePhone || '',
  privateAddress: person.privateAddress || '',
  mobile: person.mobile || '',
  room: person.room || '',
  lastname: person.lastname || '',
  speaksDutch: person.speaksDutch || false,
  phone: person.phone || '',
  kulType: (person.kulType && person.kulType.id) || '',
  uNumber: person.uNumber || '',
  userid: person.userid || '',
  partner: (person.partner && person.partner.id) || '',
});

const defaultState = {
  isLoading: false,
  isProjectsLoading: true,
  data: {},
  projects: [],
  publications: {},
  teams: [],
  zaps: [],
  promotors: [],
  files: [],
  programVenues: [],
  isFilesLoading: true,
  error: '',
  initialValues: defaultInitialValues({}),
};

const person = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_ONE_PERSON':
    case 'SET_PERSON_ROLE':
    case 'CREATE_PERSON':
    case 'MUTATE_PERSON':
    case 'MUTATE_PERSON_ALUMNUS_DATA':
    case 'MUTATE_PERSON_PHD':
    case 'MUTATE_PERSON_UPDATE_INTERNSHIP':
    case 'FETCH_PERSON_INTERNSHIP':
    case FETCH_PERSON_AWARD:
    case 'MUTATE_PERSON_ADD_INTERNSHIP':
    case 'MUTATE_PERSON_REMOVE_INTERNSHIP':
    case MUTATE_PERSON_ADD_AWARD:
    case MUTATE_PERSON_REMOVE_AWARD:
    case MUTATE_PERSON_UPDATE_AWARD:
    case 'MUTATE_PERSON_UPDATE_RESEARCHDATA':
    case 'MUTATE_PERSON_UPDATE_PGMCOMM':
    case 'MUTATE_PERSON_ADD_PGMCOMM':
    case 'MUTATE_PERSON_REMOVE_PGMCOMM':
    case 'FETCH_ALL_PGMCOMM_VENUES':
      return { ...state, isLoading: true };
    case 'UPLOAD_RESEARCHDATA_IMAGE':
      return { ...state, isResearchImageLoading: true };
    case 'UPLOAD_RESEARCHDATA_IMAGE_SUCCESS':
      return { ...state, key: state.key + 1, isResearchImageLoading: false };
    case 'UPLOAD_RESEARCHDATA_IMAGE_ERROR':
      return { ...state, error: action.error, isResearchImageLoading: false };

    case 'FETCH_ONE_PERSON_SUCCESS':
      return {
        ...state,
        data: action.data.peopleDetail,
        initialValues: defaultInitialValues(action.data.peopleDetail),
        isLoading: false,
      };
    case 'CLEAR_PERSON_FORM':
      return {
        ...state,
        initialValues: defaultInitialValues({}),
        data: {
          researchData: {},
        },
      };

    case 'SET_PERSON_ROLE_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          role: action.data.setRoleForPeople.role,
        },
        isLoading: false,
      };
      case 'CREATE_PERSON_ERROR':
        return {
          ...state,
          isLoading: false,
        };
    case 'CREATE_PERSON_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          id: action.data.createPeople.id,
          userid: action.data.createPeople.userid,
          firstname: action.data.createPeople.firstname,
          lastname: action.data.createPeople.lastname,
          email: action.data.createPeople.email,
          postal: action.data.createPeople.postal,
          room: action.data.createPeople.room,
          phone: action.data.createPeople.phone,
          kulType: action.data.createPeople.kulType,
          needsDesk: action.data.createPeople.needsDesk,
          active: action.data.createPeople.active,
          nationality: action.data.createPeople.nationality,
          gender: action.data.createPeople.gender,
          birthDate: action.data.createPeople.birthDate,
          speaksDutch: action.data.createPeople.speaksDutch,
          privateAddress: action.data.createPeople.privateAddress,
          mobile: action.data.createPeople.mobile,
          privateEmail: action.data.createPeople.privateEmail,
          website: action.data.createPeople.website,
          biography: action.data.createPeople.biography,
          partner: action.data.createPeople.partner,
          uNumber: action.data.createPeople.uNumber,
        },
        isLoading: false,
      };
    case 'MUTATE_PERSON_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          userid: action.data.updatePeople.userid,
          firstname: action.data.updatePeople.firstname,
          lastname: action.data.updatePeople.lastname,
          email: action.data.updatePeople.email,
          postal: action.data.updatePeople.postal,
          room: action.data.updatePeople.room,
          phone: action.data.updatePeople.phone,
          kulType: action.data.updatePeople.kulType,
          needsDesk: action.data.updatePeople.needsDesk,
          active: action.data.updatePeople.active,
          nationality: action.data.updatePeople.nationality,
          gender: action.data.updatePeople.gender,
          birthDate: action.data.updatePeople.birthDate,
          speaksDutch: action.data.updatePeople.speaksDutch,
          privateAddress: action.data.updatePeople.privateAddress,
          mobile: action.data.updatePeople.mobile,
          privatePhone: action.data.updatePeople.privatePhone,
          privateEmail: action.data.updatePeople.privateEmail,
          website: action.data.updatePeople.website,
          biography: action.data.updatePeople.biography,
          partner: action.data.updatePeople.partner,
          uNumber: action.data.updatePeople.uNumber,
        },
        initialValues: defaultInitialValues(action.data.updatePeople),
        isLoading: false,
      };

    case 'MUTATE_PERSON_PHD_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          preliminaryPhdTopic: action.data.updatePeoplePhdData.preliminaryPhdTopic,
          finalPhdTopic: action.data.updatePeoplePhdData.finalPhdTopic,
          phdStartDate: action.data.updatePeoplePhdData.phdStartDate,
          phdEndDate: action.data.updatePeoplePhdData.phdEndDate,
          nineMonthPresentation: action.data.updatePeoplePhdData.nineMonthPresentation,
          intermediatePresentationDate:
            action.data.updatePeoplePhdData.intermediatePresentationDate,
          privateDefenseDate: action.data.updatePeoplePhdData.privateDefenseDate,
          publicDefenseDate: action.data.updatePeoplePhdData.publicDefenseDate,
          phdCommitteeMembers: action.data.updatePeoplePhdData.phdCommitteeMembers,
        },
        isLoading: false,
      };
    case 'MUTATE_PERSON_ALUMNUS_DATA_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          alumnusData: {
            alumnus: action.data.updatePeopleAlumnusData.alumnusData?.alumnus,
            formerPostdoc: action.data.updatePeopleAlumnusData.alumnusData?.formerPostdoc,
            formerColleague: action.data.updatePeopleAlumnusData.alumnusData?.formerColleague,
            hiddenWebsite: action.data.updatePeopleAlumnusData.alumnusData?.hiddenWebsite,
            currentSituation: action.data.updatePeopleAlumnusData.alumnusData?.currentSituation,
            careerAfter: action.data.updatePeopleAlumnusData.alumnusData?.careerAfter,
            currentUrl: action.data.updatePeopleAlumnusData.alumnusData?.currentUrl,
          },
        },
        isLoading: false,
      };

    case 'MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          researchData: action.data.researchData,
        },
        isLoading: false,
      };

    case 'MUTATE_PERSON_UPDATE_PGMCOMM_SUCCESS':
    case 'MUTATE_PERSON_ADD_PGMCOMM_SUCCESS':
    case 'MUTATE_PERSON_REMOVE_PGMCOMM_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          programCommittees: action.data.programCommittees,
        },
        isLoading: false,
      };

    case 'MUTATE_PERSON_UPDATE_INTERNSHIP_SUCCESS':
    case 'MUTATE_PERSON_ADD_INTERNSHIP_SUCCESS':
    case 'MUTATE_PERSON_REMOVE_INTERNSHIP_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          internshipOrResearches: action.data.internshipOrResearches,
        },
        isLoading: false,
      };

    case 'FETCH_PERSON_INTERNSHIP_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          internshipOrResearches: action.data.internshipOrResearches,
        },
        isLoading: false,
      };

    case FETCH_PERSON_AWARD_SUCCESS:
    case MUTATE_PERSON_ADD_AWARD_SUCCESS:
    case MUTATE_PERSON_REMOVE_AWARD_SUCCESS:
    case MUTATE_PERSON_UPDATE_AWARD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          awards: action.data.awards,
        },
        isLoading: false,
      };

    case 'FETCH_TEAMS_SUCCESS':
      return { ...state, teams: action.data.teams };
    case 'FETCH_ZAPS_SUCCESS':
      return { ...state, zaps: action.data.zap };
    case 'FETCH_PROMOTORS_SUCCESS':
      return { ...state, promotors: action.data.promotors };
    case 'FETCH_PROMOTORS_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_ALL_PGMCOMM_VENUES_SUCCESS':
      return { ...state, programVenues: action.data.programVenues };


    case 'FETCH_ONE_PERSON_ERROR':
    case 'MUTATE_PERSON_ERROR':
    case 'MUTATE_PERSON_PHD_ERROR':
    case 'MUTATE_PERSON_ALUMNUS_DATA_ERROR':
    case 'MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR':
    case 'MUTATE_PERSON_ADD_INTERNSHIP_ERROR':
    case 'MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR':
    case MUTATE_PERSON_ADD_AWARD_ERROR:
    case MUTATE_PERSON_REMOVE_AWARD_ERROR:
    case MUTATE_PERSON_UPDATE_AWARD_ERROR:
    case 'FETCH_PERSON_INTERNSHIP_ERROR':
    case FETCH_PERSON_AWARD_ERROR:
    case 'FETCH_TEAMS_ERROR':
    case 'MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR':
    case 'MUTATE_PERSON_UPDATE_PGMCOMM_ERROR':
    case 'MUTATE_PERSON_ADD_PGMCOMM_ERROR':
    case 'MUTATE_PERSON_REMOVE_PGMCOMM_ERROR':
    case 'FETCH_ALL_PGMCOMM_VENUES_ERROR':
      return { ...state, error: action.error, isLoading: false };

    case 'FETCH_ONE_PERSON_PROJECTS':
      return { ...state, isProjectsLoading: true };
    case 'FETCH_ONE_PERSON_PROJECTS_SUCCESS':
      return { ...state, projects: action.data.projectsByPeopleUserId, isProjectsLoading: false };
    case 'FETCH_ONE_PERSON_PROJECTS_ERROR':
      return { ...state, error: action.error, isProjectsLoading: false };
    case 'MUTATE_PERSON_PROFILE':
      return { ...state, isLoading: true };
    case 'MUTATE_PERSON_PROFILE_SUCCESS':
      return { ...state, data: action.data.updatePeople, isLoading: false };
    case 'MUTATE_PERSON_PROFILE_ERROR':
      return { ...state, error: action.error, isLoading: false };

    case ADD_HISTORY_STATUS:
      return { ...state, isLoading: true };
    case ADD_HISTORY_STATUS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case ADD_HISTORY_STATUS_SUCCESS:
      return { ...state, isLoading: false };

    case REMOVE_HISTORY_STATUS:
      return { ...state, isLoading: true };
    case REMOVE_HISTORY_STATUS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case REMOVE_HISTORY_STATUS_SUCCESS:
      return { ...state, isLoading: false };

    case ADD_MEMBERSHIP:
      return { ...state, isLoading: true };
    case ADD_MEMBERSHIP_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case ADD_MEMBERSHIP_SUCCESS:
      return { ...state, isLoading: false };

    case REMOVE_MEMBERSHIP:
      return { ...state, isLoading: true };
    case REMOVE_MEMBERSHIP_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case REMOVE_MEMBERSHIP_SUCCESS:
      return { ...state, isLoading: false };

    case FETCH_PERSON_PUBLICATIONS:
      return { ...state, isLoading: true };
    case FETCH_PERSON_PUBLICATIONS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case FETCH_PERSON_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        publications: action.data,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default person;
