import {createAction} from 'redux-actions';

export const DELETE_PUBLICATION = 'DELETE_PUBLICATION';
export const DELETE_PUBLICATION_ERROR = 'DELETE_PUBLICATION_ERROR';
export const DELETE_PUBLICATION_SUCCESS = 'DELETE_PUBLICATION_SUCCESS';

export const LINK_PROJECT_WITH_PUBLICATION = 'LINK_PROJECT_WITH_PUBLICATION';
export const LINK_PROJECT_WITH_PUBLICATION_ERROR = 'LINK_PROJECT_WITH_PUBLICATION_ERROR';
export const LINK_PROJECT_WITH_PUBLICATION_SUCCESS = 'LINK_PROJECT_WITH_PUBLICATION_SUCCESS';

export const UNLINK_PROJECT_FROM_PUBLICATION = 'UNLINK_PROJECT_FROM_PUBLICATION';
export const UNLINK_PROJECT_FROM_PUBLICATION_ERROR = 'UNLINK_PROJECT_FROM_PUBLICATION_ERROR';
export const UNLINK_PROJECT_FROM_PUBLICATION_SUCCESS = 'UNLINK_PROJECT_FROM_PUBLICATION_SUCCESS';

export const CLEAR_PUBLICATION_DEFAULTS = () => ({ type: 'CLEAR_PUBLICATION_DEFAULTS' });
export const FETCH_ALL_PUBLICATIONS = () => ({ type: 'FETCH_ALL_PUBLICATIONS' });
export const FETCH_ALL_PUBLICATIONS_SUCCESS = (data) => ({ type: 'FETCH_ALL_PUBLICATIONS_SUCCESS', data });
export const FETCH_ALL_PUBLICATIONS_ERROR = (error) => ({ type: 'FETCH_ALL_PUBLICATIONS_ERROR', error });

export const SET_PUBLICATION_FILTER_YEARS = (data) => ({ type: 'SET_PUBLICATION_FILTER_YEARS', data });
export const SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM = (data) => ({ type: 'SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM', data });
export const SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO = (data) => ({ type: 'SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO', data });
export const SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM = (data) => ({ type: 'SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM', data });
export const SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO = (data) => ({ type: 'SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO', data });
export const SET_PUBLICATION_FILTER_PUBLICATIONTYPES = (data) => ({ type: 'SET_PUBLICATION_FILTER_PUBLICATIONTYPES', data });
export const SET_PUBLICATION_FILTER_AUTHORS = (data) => ({ type: 'SET_PUBLICATION_FILTER_AUTHORS', data });


export const FETCH_PUBLICATION_PEOPLE = () => ({ type: 'FETCH_PUBLICATION_PEOPLE' });
export const FETCH_PUBLICATION_PEOPLE_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_PEOPLE_SUCCESS', data });
export const FETCH_PUBLICATION_PEOPLE_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_PEOPLE_ERROR', error });

export const FETCH_PUBLICATION_VOLUMES = () => ({ type: 'FETCH_PUBLICATION_VOLUMES' });
export const FETCH_PUBLICATION_VOLUMES_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_VOLUMES_SUCCESS', data });
export const FETCH_PUBLICATION_VOLUMES_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_VOLUMES_ERROR', error });

export const FETCH_PUBLICATION_TEAMS = () => ({ type: 'FETCH_PUBLICATION_TEAMS' });
export const FETCH_PUBLICATION_TEAMS_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_TEAMS_SUCCESS', data });
export const FETCH_PUBLICATION_TEAMS_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_TEAMS_ERROR', error });

export const FETCH_PUBLICATION_PROCEEDINGS = () => ({ type: 'FETCH_PUBLICATION_PROCEEDINGS' });
export const FETCH_PUBLICATION_PROCEEDINGS_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_PROCEEDINGS_SUCCESS', data });
export const FETCH_PUBLICATION_PROCEEDINGS_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_PROCEEDINGS_ERROR', error });

export const CREATE_PUBLICATION = (data) => ({ type: 'CREATE_PUBLICATION', data });
export const UPDATE_PUBLICATION = (data) => ({ type: 'UPDATE_PUBLICATION', data });
export const FETCH_PUBLICATION_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_SUCCESS', data });
export const FETCH_PUBLICATION_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_ERROR', error });

export const FETCH_ONE_PUBLICATION = (id, type) => ({ type: 'FETCH_ONE_PUBLICATION', data: { id, type } });
export const SET_PUBLICATION_FILTER_STATUS = (data) => ({ type: 'SET_PUBLICATION_FILTER_STATUS', data });
export const SET_PUBLICATION_FILTER_INTERNATIONAL = (data) => ({ type: 'SET_PUBLICATION_FILTER_INTERNATIONAL', data });
export const SET_PUBLICATION_FILTER_REVIEWED = (data) => ({ type: 'SET_PUBLICATION_FILTER_REVIEWED', data });
export const SET_PUBLICATION_FILTER_AUTHOR_LIMIT = (data) => ({ type: 'SET_PUBLICATION_FILTER_AUTHOR_LIMIT', data });
export const SET_PUBLICATION_FILTER_TEAM = (data) => ({ type: 'SET_PUBLICATION_FILTER_TEAM', data });

export const FETCH_PUBLICATION_COUNTRIES_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_COUNTRIES_SUCCESS', data });
export const FETCH_PUBLICATION_COUNTRIES_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_COUNTRIES_ERROR', error });

export const FETCH_PUBLICATION_FILES = (id, pubType, category) => ({
  type: 'FETCH_PUBLICATION_FILES', id, pubType, category,
});
export const FETCH_PUBLICATION_FILES_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_FILES_SUCCESS', data });
export const FETCH_PUBLICATION_FILES_PDF_SUCCESS = (data) => ({ type: 'FETCH_PUBLICATION_FILES_PDF_SUCCESS', data });
export const FETCH_PUBLICATION_FILES_ERROR = (error) => ({ type: 'FETCH_PUBLICATION_FILES_ERROR', error });

export const UPLOAD_PUBLICATION_FILES = (data, category, attribute) => ({ type: 'UPLOAD_PUBLICATION_FILES', data, category, attribute });
export const UPLOAD_PUBLICATION_FILES_SUCCESS = (data, category, attribute) => ({ type: 'UPLOAD_PUBLICATION_FILES_SUCCESS', data, category, attribute });
export const UPLOAD_PUBLICATION_FILES_PDF_SUCCESS = (data, category, attribute) => ({ type: 'UPLOAD_PUBLICATION_FILES_PDF_SUCCESS', data, category, attribute });
export const UPLOAD_PUBLICATION_FILES_ERROR = (error, category, attribute) => ({ type: 'UPLOAD_PUBLICATION_FILES_ERROR', error, category, attribute });

export const DELETE_PUBLICATION_FILES = (data) => ({ type: 'DELETE_PUBLICATION_FILES', data });
export const DELETE_PUBLICATION_FILES_SUCCESS = (data) => ({ type: 'DELETE_PUBLICATION_FILES_SUCCESS', data });
export const DELETE_PUBLICATION_FILES_PDF_SUCCESS = (data) => ({ type: 'DELETE_PUBLICATION_FILES_PDF_SUCCESS', data });
export const DELETE_PUBLICATION_FILES_ERROR = (error) => ({ type: 'DELETE_PUBLICATION_FILES_ERROR', error });

export const DOWNLOAD_PUBLICATION_FILES = (data) => ({ type: 'DOWNLOAD_PUBLICATION_FILES', data });


export const DELETE_PUBLICATION_BY_ID = (data) => ({ type: 'DELETE_PUBLICATION_BY_ID', data });
export const DELETE_PUBLICATION_BY_ID_SUCCESS = (data) => ({ type: 'DELETE_PUBLICATION_BY_ID_SUCCESS', data });
export const DELETE_PUBLICATION_BY_ID_ERROR = (error) => ({ type: 'DELETE_PUBLICATION_BY_ID_ERROR', error });

export const linkProjectWithPublication = (data) => ({ type: LINK_PROJECT_WITH_PUBLICATION, data });
export const linkProjectWithPublicationError = (error) => ({
  type: LINK_PROJECT_WITH_PUBLICATION_ERROR,
  error,
});
export const linkProjectWithPublicationSuccess = (data) => ({
  type: LINK_PROJECT_WITH_PUBLICATION_SUCCESS,
  data,
});

export const unlinkProjectFromPublication = (data) => ({
  type: UNLINK_PROJECT_FROM_PUBLICATION,
  data,
});
export const unlinkProjectFromPublicationError = (error) => ({
  type: UNLINK_PROJECT_FROM_PUBLICATION_ERROR,
  error,
});
export const unlinkProjectFromPublicationSuccess = (data) => ({
  type: UNLINK_PROJECT_FROM_PUBLICATION_SUCCESS,
  data,
});

export const deletePublication = createAction(DELETE_PUBLICATION);
export const deletePublicationError = createAction(DELETE_PUBLICATION_ERROR);
export const deletePublicationSuccess = createAction(DELETE_PUBLICATION_SUCCESS);
