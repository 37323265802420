import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Overlay, Popover,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../index';
import {
  MUTATE_PERSON_PHD,
  CREATE_EXTERNAL_PEOPLE,
} from '../../../state/person/personActions';
import ClickText from '../../clickText/clickText';
import { selectPeopleInclExternalList } from '../../../state/people/selectors';
import { fetchAllPeopleInclExternal } from '../../../state/people/peopleActions';
import { customFilterMatchAll } from '../../publicationFormatTypes/typeHelper';

const EditPeoplePhdForm = (props) => {
  const {
    loading,
    id,
    preliminaryPhdTopic,
    finalPhdTopic,
    phdStartDate,
    phdEndDate,
    nineMonthPresentation,
    intermediatePresentationDate,
    privateDefenseDate,
    publicDefenseDate,
    phdCommitteeMembers,
    allCommitteeMembers,
    mutatePersonPhd,
    currentStatusType,
    fetchAllPeople,
    createExternalPeople,
  } = props;

  useEffect(() => {
    fetchAllPeople();
  }, [fetchAllPeople]);

  const SignupSchema = Yup.object().shape({
    preliminaryPhdTopic: Yup.string()
      .required('^ mandatory field'),
    finalPhdTopic: Yup.string(),
    phdStartDate: Yup.date().required('^ mandatory field').typeError('Required, invalid date format'),
    phdEndDate: Yup.date().typeError('Invalid date format').nullable(),
    nineMonthPresentation: Yup.date().typeError('Invalid date format').nullable(),
    intermediatePresentationDate: Yup.date().typeError('Invalid date format').nullable(),
    privateDefenseDate: Yup.date().typeError('Invalid date format').nullable(),
    publicDefenseDate: Yup.date().typeError('Invalid date format').nullable(),
    phdCommitteeMembers: Yup.array(),
  });

  const addNewPersonSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('^ mandatory field'),
    lastname: Yup.string()
      .required('^ mandatory field'),
  });

  const comOpts = allCommitteeMembers
    .filter((el) => el.id !== id)
    .map((m) => (
      { value: m.id, label: `${m.last} ${m.first}` }
    ));

  const checkIfResearchAssistant = (values, setFieldValue) => {
    if (currentStatusType && currentStatusType.includes('research') && currentStatusType.includes('assistant')) {
      return setFieldValue('phdStartDate', moment(values.phdEndDate).add(4, 'years').format('YYYY-MM-DD'));
    }
    return values.phdEndDate;
  };

  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <Formik
            enableReinitialize
            initialValues={{
              id,
              preliminaryPhdTopic: preliminaryPhdTopic || '',
              finalPhdTopic: finalPhdTopic || '',
              phdStartDate: (phdStartDate) ? moment(phdStartDate).format('YYYY-MM-DD') : '',
              phdEndDate: (phdEndDate) ? moment(phdEndDate).format('YYYY-MM-DD') : '',
              nineMonthPresentation: (nineMonthPresentation) ? moment(nineMonthPresentation).format('YYYY-MM-DD') : '',
              intermediatePresentationDate: (intermediatePresentationDate) ? moment(intermediatePresentationDate).format('YYYY-MM-DD') : '',
              privateDefenseDate: (privateDefenseDate) ? moment(privateDefenseDate).format('YYYY-MM-DD') : '',
              publicDefenseDate: (publicDefenseDate) ? moment(publicDefenseDate).format('YYYY-MM-DD') : '',
              phdCommitteeMembers: phdCommitteeMembers.map((el) => (
                { value: el.id, label: `${el.commiteeMember.gn} ${el.commiteeMember.sn}` }
              )) || [],
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              mutatePersonPhd({
                ...values,
                phdCommitteeMembers: values.phdCommitteeMembers.map((el) => el.value),
              });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Preliminary PhD topic</Form.Label>
                    <Form.Control
                      type="text"
                      name="preliminaryPhdTopic"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.preliminaryPhdTopic}
                      className="form-control"
                    />
                    {displayError(errors.preliminaryPhdTopic, touched.preliminaryPhdTopic)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Final PhD topic</Form.Label>
                    <Form.Control
                      type="text"
                      name="finalPhdTopic"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.finalPhdTopic}
                      className="form-control"
                    />
                    {displayError(errors.finalPhdTopic, touched.finalPhdTopic)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Start date</Form.Label>
                    <Datetime
                      name="phdStartDate"
                      value={values.phdStartDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('phdStartDate', moment(val).format('YYYY-MM-DD'))}
                    />
                    {displayError(errors.phdStartDate, touched.phdStartDate)}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Estimated PhD end date</Form.Label>
                    <Datetime
                      name="phdEndDate"
                      value={checkIfResearchAssistant(values, setFieldValue)}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('phdEndDate', val ? moment(val).format('YYYY-MM-DD') : null)}
                    />
                    {displayError(errors.phdEndDate, touched.phdEndDate)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>9th month presentation</Form.Label>
                    <Datetime
                      name="nineMonthPresentation"
                      value={values.nineMonthPresentation}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('nineMonthPresentation', val ? moment(val).format('YYYY-MM-DD') : null)}
                    />
                    {displayError(errors.nineMonthPresentation, touched.nineMonthPresentation)}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Intermediate presentation</Form.Label>
                    <Datetime
                      name="intermediatePresentationDate"
                      value={values.intermediatePresentationDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('intermediatePresentationDate', val ? moment(val).format('YYYY-MM-DD') : null)}
                    />
                    {displayError(
                      errors.intermediatePresentationDate,
                      touched.intermediatePresentationDate,
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Private defense</Form.Label>
                    <Datetime
                      name="privateDefenseDate"
                      value={values.privateDefenseDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('privateDefenseDate', val ? moment(val).format('YYYY-MM-DD') : null)}
                    />
                    {displayError(errors.privateDefenseDate, touched.privateDefenseDate)}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Public defense</Form.Label>
                    <Datetime
                      name="publicDefenseDate"
                      value={values.publicDefenseDate}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(val) => setFieldValue('publicDefenseDate', val ? moment(val).format('YYYY-MM-DD') : null)}
                    />
                    {displayError(errors.publicDefenseDate, touched.publicDefenseDate)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>PhD committee members</Form.Label>
                    {' '}
                    <ClickText
                      onClick={handleClick}
                      text="Add new person"
                    />
                    <Select
                      name="phdCommitteeMembers"
                      closeMenuOnSelect={false}
                      isMulti
                      onChange={(val) => setFieldValue('phdCommitteeMembers', val || [])}
                      options={comOpts}
                      filterOption={customFilterMatchAll}
                      value={values.phdCommitteeMembers}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%' }}>
                      Save
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      <Overlay
        show={show}
        target={target}
        placement="top"
        container={ref.current}
        containerPadding={20}
        width={500}
        rootClose
        rootCloseEvent="click"
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained" className="add-new-person">
          <Popover.Title as="h3">Add new person</Popover.Title>
          <Popover.Content>
            <Formik
              validationSchema={addNewPersonSchema}
              enableReinitialize
              initialValues={{ firstname: '', lastname: '' }}
              onSubmit={(values) => {
                createExternalPeople(values);
                setShow(false);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="text"
                        name="firstname"
                        className={errors.firstname ? 'error' : null}
                        onChange={handleChange}
                        placeholder="Firstname"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="text"
                        name="lastname"
                        className={errors.lastname ? 'error' : null}
                        onChange={handleChange}
                        placeholder="Lastname"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Button type="submit" className="btn btn-success" size="sm" style={{ width: '100%' }} block>
                        Add
                      </Button>
                    </Form.Group>
                  </Row>
                </Form>
              )}
            </Formik>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

EditPeoplePhdForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  id: PropTypes.string,
  preliminaryPhdTopic: PropTypes.string,
  finalPhdTopic: PropTypes.string,
  phdStartDate: PropTypes.string,
  phdEndDate: PropTypes.string,
  nineMonthPresentation: PropTypes.string,
  intermediatePresentationDate: PropTypes.string,
  privateDefenseDate: PropTypes.string,
  publicDefenseDate: PropTypes.string,
  allCommitteeMembers: PropTypes.arrayOf(PropTypes.any),
  phdCommitteeMembers: PropTypes.arrayOf(PropTypes.any),
  mutatePersonPhd: PropTypes.func.isRequired,
  currentStatusType: PropTypes.string.isRequired,
  fetchAllPeople: PropTypes.func.isRequired,
  createExternalPeople: PropTypes.func.isRequired,
};

EditPeoplePhdForm.defaultProps = {
  id: '',
  preliminaryPhdTopic: '',
  finalPhdTopic: '',
  phdStartDate: '',
  phdEndDate: '',
  nineMonthPresentation: '',
  intermediatePresentationDate: '',
  privateDefenseDate: '',
  publicDefenseDate: '',
  phdCommitteeMembers: [],
  allCommitteeMembers: [],
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  id: state.person.data.id,
  preliminaryPhdTopic: state.person.data.preliminaryPhdTopic,
  finalPhdTopic: state.person.data.finalPhdTopic,
  phdStartDate: state.person.data.phdStartDate,
  phdEndDate: state.person.data.phdEndDate,
  nineMonthPresentation: state.person.data.nineMonthPresentation,
  intermediatePresentationDate: state.person.data.intermediatePresentationDate,
  privateDefenseDate: state.person.data.privateDefenseDate,
  publicDefenseDate: state.person.data.publicDefenseDate,
  phdCommitteeMembers: state.person.data.phdCommitteeMembers,
  allCommitteeMembers: selectPeopleInclExternalList(state),
  currentStatusType: state.person.data.currentStatus,
});

const mapDispatchToProps = (dispatch) => ({
  mutatePersonPhd: (data) => dispatch(MUTATE_PERSON_PHD(data)),
  fetchAllPeople: () => dispatch(fetchAllPeopleInclExternal()),
  createExternalPeople: (data) => dispatch(CREATE_EXTERNAL_PEOPLE(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeoplePhdForm);
