const defaultState = {
  isLoadingJournal: true,
  isLoadingConference: true,
  isLoadingDates: true,
  isVisitorsLoading: true,
  isPhdLoading: true,
  isStatusHistoryCountLoading: true,
  isVisitorsBetweenLoading: true,
  isBestPaperLoading: true,
  isAwardsLoading: true,
  isProjectsLoading: true,
  isGrantLoading: true,
  isBilateralLoading: true,
  isTotalLoading: true,
  isLocationLoading: true,
  isFunderLoading: true,
  journal: [],
  conference: [],
  dates: [],
  visitors: 0,
  phd: 0,
  postdoc: 0,
  visitorsBetween: 0,
  bestPaper: 0,
  awards: 0,
  projectSuccess: 0,
  grantSuccess: 0,
  bilateralSuccess: 0,
  totalSuccess: 0,
  location: [],
  funder: [],
  error: '',
};

const category = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_CONFERENCE_BY_YEAR':
      return {
        ...state, isLoadingConference: true, conference: [], error: '',
      };

    case 'FETCH_CONFERENCE_BY_YEAR_SUCCESS':
      return {
        ...state,
        isLoadingConference: false,
        conference: action.data.data.Category.conferencesVenue,
      };

    case 'FETCH_CONFERENCE_BY_YEAR_ERROR':
      return {
        ...state, isLoadingConference: false, error: action.error,
      };

    case 'FETCH_JOURNAL_BY_YEAR':
      return {
        ...state, isLoadingJournal: true, journal: [], error: '',
      };

    case 'FETCH_JOURNAL_BY_YEAR_SUCCESS':
      return {
        ...state, isLoadingJournal: false, journal: action.data.data.Category.journalVenue,
      };

    case 'FETCH_JOURNAL_BY_YEAR_ERROR':
      return {
        ...state, isLoadingJournal: false, error: action.error,
      };

    case 'FETCH_STATUS_HISTORY_COUNT_BY_YEAR':
      return {
        ...state, isStatusHistoryCountLoading: true, statusHistoryCount: {}, error: '',
      };

    case 'FETCH_STATUS_HISTORY_COUNT_BY_YEAR_SUCCESS':
      return {
        ...state, isStatusHistoryCountLoading: false, statusHistoryCount: action.data.data.statusHistoryCount,
      };

    case 'FETCH_STATUS_HISTORY_COUNT_BY_ERROR':
      return {
        ...state, isStatusHistoryCountLoading: false, error: action.error,
      };

    case 'FETCH_BEST_PAPER_BY_YEAR':
      return {
        ...state, isBestPaperLoading: true, bestPaper: 0, error: '',
      };

    case 'FETCH_BEST_PAPER_BY_YEAR_SUCCESS':
      return {
        ...state, isBestPaperLoading: false, bestPaper: action.data.data.bestPaperAmounts,
      };

    case 'FETCH_BEST_PAPER_BY_YEAR_ERROR':
      return {
        ...state, isBestPaperLoading: false, error: action.error,
      };

    case 'FETCH_AWARDS_BY_YEAR':
      return {
        ...state, isAwardsLoading: true, awards: 0, error: '',
      };

    case 'FETCH_AWARDS_BY_YEAR_SUCCESS':
      return {
        ...state, isAwardsLoading: false, awards: action.data.data.awardsWon,
      };

    case 'FETCH_AWARDS_BY_YEAR_ERROR':
      return {
        ...state, isAwardsLoading: false, error: action.error,
      };
    case 'FETCH_PROJECTS_RATE':
      return {
        ...state, isProjectsLoading: true, projectSuccess: 0, error: '',
      };

    case 'FETCH_PROJECTS_RATE_SUCCESS':
      return {
        ...state, isProjectsLoading: false, projectSuccess: action.data.data.projectSuccessRate,
      };

    case 'FETCH_PROJECTS_RATE_ERROR':
      return {
        ...state, isProjectsLoading: false, error: action.error,
      };
    case 'FETCH_GRANT_RATE':
      return {
        ...state, isGrantLoading: true, grantSuccess: 0, error: '',
      };

    case 'FETCH_GRANT_RATE_SUCCESS':
      return {
        ...state, isGrantLoading: false, grantSuccess: action.data.data.grantSuccessRate,
      };

    case 'FETCH_GRANT_RATE_ERROR':
      return {
        ...state, isGrantLoading: false, error: action.error,
      };
    case 'FETCH_BILATERAL_RATE':
      return {
        ...state, isBilateralLoading: true, bilateralSuccess: 0, error: '',
      };

    case 'FETCH_BILATERAL_RATE_SUCCESS':
      return {
        ...state,
        isBilateralLoading: false,
        bilateralSuccess: action.data.data.bilateralSuccessRate,
      };

    case 'FETCH_BILATERAL_RATE_ERROR':
      return {
        ...state, isBilateralLoading: false, error: action.error,
      };

    case 'FETCH_TOTAL_RATE':
      return {
        ...state, isTotalLoading: true, totalSuccess: 0, error: '',
      };

    case 'FETCH_TOTAL_RATE_SUCCESS':
      return {
        ...state,
        isTotalLoading: false,
        totalSuccess: action.data.data.totalSuccessRate,
      };

    case 'FETCH_TOTAL_RATE_ERROR':
      return {
        ...state, isTotalLoading: false, error: action.error,
      };

    case 'FETCH_LOCATION_RATE':
      return {
        ...state, isLocationLoading: true, error: '',
      };

    case 'FETCH_LOCATION_RATE_SUCCESS':
      return {
        ...state,
        isLocationLoading: false,
        location: action.data.data.projectLocation,
      };

    case 'FETCH_LOCATION_RATE_ERROR':
      return {
        ...state, isLocationLoading: false, error: action.error,
      };

    case 'FETCH_FUNDER_RATE':
      return {
        ...state, isFunderLoading: false, error: action.error,
      };

    case 'FETCH_FUNDER_RATE_SUCCESS':
      return {
        ...state,
        isFunderLoading: false,
        funder: action.data.data.projectFunder,
      };

    case 'FETCH_FUNDER_RATE_ERROR':
      return {
        ...state, isFunderLoading: false, error: action.error,
      };

    default:
      return state;
  }
};

export default category;
