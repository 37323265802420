export const authors = [
  { value: 'author 1', label: 'author 1' },
  { value: 'author 2', label: 'Author 2' },
  { value: 'author 3', label: 'Author 3' },
];

export const publicationTypes = [
  { value: 'article', label: 'article (conf/journal/book)' },
  { value: 'report', label: 'report' },
  { value: 'book', label: 'book' },
  { value: 'these', label: 'thesis (all)' },
  { value: 'thesePhd', label: 'thesis (PhD)' },
  { value: 'proceeding', label: 'proceedings (editor of)' },
  { value: 'patent', label: 'patent' },
  { value: 'rump', label: 'rump' },
  { value: 'abstractOfTalk', label: 'abstract of talk' },
  { value: 'talk', label: 'talk' },
];
export const publicationTypesAdmin = [...publicationTypes,
  { value: 'conferencePaper', label: 'conference article' },
  { value: 'journalArticle', label: 'journal article' },
  { value: 'bookChapter', label: 'book chapter' },
];

export const years = [
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
  { value: '2018', label: '2018' },
  { value: '2017', label: '2017' },
  { value: '2016', label: '2016' },
  { value: '2015', label: '2015' },
];

export const teams = [
  { value: '1', label: 'Symmetric Key Cryptography' },
  { value: '2', label: 'Public Key Cryptography' },
  { value: '3', label: 'Embedded Security' },
  { value: '4', label: 'Privacy Technologies' },
  { value: '5', label: 'Identity Management' },
  { value: '6', label: 'Mobile and Wireless Security' },
  { value: '7', label: 'General' },
];

export const statusTypes = [
  { value: 'HEAD', label: 'HEAD' },
  { value: 'FACULTY', label: 'FACULTY' },
  { value: 'REASEARCHER', label: 'RESEARCHER' },
  { value: 'POSTDOC', label: 'POSTDOC' },
  { value: 'ATP', label: 'ATP' },
  { value: 'VISITOR', label: 'VISITOR' },
  { value: 'FREE', label: 'FREE' },
  { value: 'JOBSTUDENT', label: 'JOBSTUDENT' },
  { value: 'PHD', label: 'PHD' },
  { value: 'INTERNAL', label: 'INTERNAL' },
  { value: 'EXTERNAL', label: 'EXTERNAL' },
  { value: 'RESMAN', label: 'RESMAN' },
];

export const eventTypes = [
  {
    label: 'School',
    value: 'school',
  },
  {
    label: 'Workshop',
    value: 'workshop',
  },
  {
    label: 'Seminar (COSIC)',
    value: 'seminar (COSIC)',
  },
  {
    label: 'Seminar (other)',
    value: 'seminar (other)',
  },
  {
    label: 'Conference',
    value: 'conference',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const projectStatuses = [
  { value: 'proposal', label: 'Proposal' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'rejected', label: 'Rejected' },
];
export const projectDateStatuses = [
  { value: 'RUNNING', label: 'Running' },
  { value: 'FINISHED', label: 'Finished' },
  { value: 'NOT_YET_STARTED', label: 'Not yet started' },
];

export const projectTeamRoles = [
  { value: 'coordinator', label: 'Coordinator' },
  { value: 'coPromotor', label: 'Co-promoter' },
  { value: 'promotor', label: 'Promoter' },
  { value: 'projectLeader', label: 'Project Leader' },
  { value: 'researcherWorkingOnProject', label: 'Researcher Working on Project' },
  { value: 'proposalManager', label: 'Proposal Manager' },
  { value: 'wpLeader', label: 'WP Leader' },
  { value: 'teamMember', label: 'Team member' },
  { value: 'leadingResearcher', label: 'Leading researcher' },
  { value: 'leadingProfessor', label: 'Leading professor' },
  { value: 'professorLinkedToTheProject', label: 'Professor linked to the project' },
];
export const cosicContactRoles = [
  { value: 'coordinator', label: 'Coordinator' },
  { value: 'partner', label: 'Partner' },
  { value: 'other', label: 'Other' },
];

export const genderOptions = [
  { value: 'Other', label: 'Other' },
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export const publicationStatusOptions = [
  { value: 'published', label: 'Published' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];

export const yesNoOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const talkTypeOptions = [
  'Conference talk without publication',
  'Keynote talk',
  'Invited talk at a conference/workshop',
  'Invited talk at an event',
  'Invited talk/seminar in academia',
  'Invited talk/seminar in industry',
  'Member of panel',
  'Invited seminar',
  'Invited lecture at a school',
  'Non-invited talk',
].map((talkTypes) => ({ label: talkTypes, value: talkTypes }));


export const theseTypeOptions = [
  'PhD',
  'Master',
  'Bachelor',
].map((label) => ({ value: label.toLowerCase(), label }));

export const projectTypes = [
  { value: 'academy', label: 'Academy' },
  { value: 'industry', label: 'Industry' },
  { value: 'other', label: 'Other' },
];
