import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Loader } from '../../../component/index';
import {
  fetchOneProjectCall as fetchOneProjectCallAction,
  updateProjectCall as updateProjectCallAction,
} from '../../../state/projectCall/projectCallActions';
import '../../../styles/forms.scss';

function EditProjectCall(props) {
  const { id } = useParams();
  const {
    loading, projectCall, fetchProjectCall, updateProjectCall,
  } = props;

  useEffect(() => {
    fetchProjectCall({ id: id !== 'new' ? id : null });
  }, [
    fetchProjectCall,
    id,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required('The Project Call name is mandatory'),
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{projectCall.id ? 'Edit Project Call' : 'Create Project Call'}</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        id: projectCall.id !== 'new' ? projectCall.id : null,
                        name: projectCall.name,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        updateProjectCall({
                          ...values,
                        });
                        setSubmitting(false);
                      }}
                    >
                      { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Type name</Form.Label>
                                <Form.Control placeholder="Project Call name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} className={touched.name && errors.name ? 'error' : null} />
                                { touched.name && errors.name ? (<div className="error-message">{errors.name}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Button variant="primary" type="submit" disabled={isSubmitting || errors.name}>
                                  {projectCall.id ? 'Edit Project Call' : 'Create Project Call'}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditProjectCall.propTypes = {
  loading: PropTypes.bool.isRequired,
  projectCall: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchProjectCall: PropTypes.func.isRequired,
  updateProjectCall: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.projectCall.isLoading,
  projectCall: state.projectCall.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectCall: (data) => dispatch(fetchOneProjectCallAction(data)),
  updateProjectCall: (data) => dispatch(updateProjectCallAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectCall);
