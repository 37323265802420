import {createSelector} from 'reselect';
import {get} from 'lodash/fp';

export const selectPublication = get('publication');
export const selectFilter = get('publication.filter');
export const selectFilterAuthors = get('publication.filter.authors');
export const selectFilterYears = get('publication.filter.years');
export const selectFilterPublishingDateFrom = get('publication.filter.publishingDateFrom');
export const selectFilterPublishingDateTo = get('publication.filter.publishingDateTo');
export const selectFilterConferenceDateFrom = get('publication.filter.conferenceDateFrom');
export const selectFilterConferenceDateTo = get('publication.filter.conferenceDateTo');
export const selectFilterPublicationTypes = get('publication.filter.publicationTypes');
export const selectFilterTeam = get('publication.filter.team');
export const selectLimitToReviewed = createSelector(
  get('publication.filter.limitReviewed'),
  (flag) => (flag || false),
);
export const selectLimitToInternational = createSelector(
  get('publication.filter.limitInternational'),
  (flag) => (flag || false),
);
export const selectLimitToAuthor = createSelector(
  get('publication.filter.limitAuthor'),
  (flag) => (flag || false),
);
export const selectStatus = createSelector(
  get('publication.filter.status'),
  (flag) => (flag || ''),
);

export const selectFilterAuthorList = createSelector(
  selectFilterAuthors,
  (authors) => (authors ? [...authors.map((author) => `"${author.value}"`)] : []),
);

export const selectFilterYearList = createSelector(
  selectFilterYears,
  (years) => (years ? [...years.map((year) => year.value)] : []),
);

export const selectFilterPublicationTypeList = createSelector(
  selectFilterPublicationTypes,
  (publicationTypes) => (
    publicationTypes
      ? [...publicationTypes.map((publicationType) => `"${publicationType.value}"`)]
      : []
  ),
);

export const selectPublicationDetail = createSelector(
  selectPublication,
  get('detail.data'),
);

export const selectProjectsForPublication = createSelector(
  selectPublicationDetail,
  (data) => (data.projects ? data.projects : []),
);

export const selectPublicationTeams = createSelector(selectPublication, get('teams'));
export const selectPublicationTeamsAsOptions = createSelector(
  selectPublicationTeams,
  (teams) => teams.map((team) => ({ label: team.name, value: team.id })),
);
export const selectPublicationAuthors = createSelector(
  selectPublication,
  get('authorsOption'),
);

export const selectConferences = createSelector(
  selectPublication,
  get('conferencesOption'),
);

export const selectSeries = createSelector(
  selectPublication,
  get('seriesOption'),
);

export const selectBooks = createSelector(
  selectPublication,
  get('booksOption'),
);

export const selectProceedings = createSelector(
  selectPublication,
  get('proceedingsOption'),
);

export const selectJournals = createSelector(
  selectPublication,
  get('journalOption'),
);
