import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { years, authors, publicationTypes } from '../config/optionValues';

function SearchForm() {
  return (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Authors</Form.Label>
        <Select options={authors} isMulti />

      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Type of publications</Form.Label>
        <Select options={publicationTypes} isMulti />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Years</Form.Label>
        <Select options={years} isMulti />
      </Form.Group>

      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Status</Form.Label>
        <Form.Check type="checkbox" label="In print" />
        <Form.Check type="checkbox" label="Appeared" />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Limit</Form.Label>
        <Form.Check type="checkbox" label="Limit to international" />
        <Form.Check type="checkbox" label="Limit to peer-reviewed" />
        <Form.Check type="checkbox" label="Limit to author" />

      </Form.Group>
      <Button variant="primary" type="submit">
                Search Publications
      </Button>
    </Form>
  );
}

export default SearchForm;
