import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Loader } from '../../../component/index';
import {
  deleteType as deleteTypeAction,
  fetchAllTypes as fetchAllTypesAction,
  setTypeFilterName as setTypeFilterNameAction,
} from '../../../state/types/typesActions';
import { filteredTypes } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Types = (props) => {
  const {
    types,
    filter,
    loading,
    fetchAllTypes,
    setTypeFilterName,
    deleteType,
  } = props;
  const history = useHistory();

  useEffect(() => { fetchAllTypes(); }, [fetchAllTypes]);

  const data = filteredTypes(types, filter);

  const [deleteTypeInfo, setDeleteTypeInfo] = useState(null);

  const onConfirmDelete = () => {
    deleteType(deleteTypeInfo.id);
    setDeleteTypeInfo(null);
  };


  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Children', accessor: 'children', Cell: (cellInfo) => (cellInfo.original.childs.length || 0) },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/admin/settings/projecttypes/${row.original.id}`)}
          >
Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
            onClick={() => setDeleteTypeInfo(row.original)}
          >
Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Type name</Form.Label>
                      <Form.Control placeholder="Type name" value={filter} onChange={(val) => setTypeFilterName(val.target.value)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Types
              <Button
                onClick={() => {
                  history.push('/admin/settings/projecttypes/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loader />
              )
                : (
                  <ReactTable
                    columns={columns}
                    data={data}
                    defaultSorted={[
                      {
                        id: 'name',
                        desc: false,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              <DeleteConfirmation
                text={deleteTypeInfo !== null ? `Delete project type ${deleteTypeInfo.name}` : ''}
                show={deleteTypeInfo !== null}
                onCancel={() => setDeleteTypeInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Types.propTypes = {
  loading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.string.isRequired,
  fetchAllTypes: PropTypes.func.isRequired,
  setTypeFilterName: PropTypes.func.isRequired,
  deleteType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.types.isLoading,
  types: state.types.data,
  filter: state.types.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllTypes: () => dispatch(fetchAllTypesAction()),
  setTypeFilterName: (value) => dispatch(setTypeFilterNameAction(value)),
  deleteType: (data) => dispatch(deleteTypeAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Types);
