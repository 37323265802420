import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Loader } from '../../../component/index';
import {
  deleteProjectCall as deleteProjectCallAction,
  fetchAllProjectCalls as fetchAllProjectCallsAction,
  setProjectCallFilterName as setProjectCallFilterNameAction,
} from '../../../state/projectCalls/projectCallsActions';
import { filteredProjectCalls } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const ProjectCalls = (props) => {
  const {
    projectCalls,
    filter,
    loading,
    fetchAllProjectCalls,
    setProjectCallFilterName,
    deleteProjectCall,
  } = props;
  const history = useHistory();

  useEffect(() => { fetchAllProjectCalls(); }, [fetchAllProjectCalls]);

  const data = filteredProjectCalls(projectCalls, filter);

  const [deleteProjectCallInfo, setDeleteProjectCallInfo] = useState(null);

  const onConfirmDelete = () => {
    deleteProjectCall(deleteProjectCallInfo.id);
    setDeleteProjectCallInfo(null);
  };

  const columns = [
    { Header: 'Name', accessor: 'name' },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/admin/settings/projectCalls/${row.original.id}`)}
          >
            Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
            onClick={() => setDeleteProjectCallInfo(row.original)}
          >
            Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Project Call name</Form.Label>
                      <Form.Control placeholder="Project Call name" value={filter} onChange={(val) => setProjectCallFilterName(val.target.value)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Project Calls
              <Button
                onClick={() => {
                  history.push('/admin/settings/projectCalls/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loader />
              )
                : (
                  <ReactTable
                    columns={columns}
                    data={data}
                    defaultSorted={[
                      {
                        id: 'name',
                        desc: false,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              <DeleteConfirmation
                text={deleteProjectCallInfo !== null ? `Delete project call ${deleteProjectCallInfo.name}` : ''}
                show={deleteProjectCallInfo !== null}
                onCancel={() => setDeleteProjectCallInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ProjectCalls.propTypes = {
  loading: PropTypes.bool.isRequired,
  projectCalls: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.string.isRequired,
  fetchAllProjectCalls: PropTypes.func.isRequired,
  setProjectCallFilterName: PropTypes.func.isRequired,
  deleteProjectCall: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.projectCalls.isLoading,
  projectCalls: state.projectCalls.data,
  filter: state.projectCalls.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllProjectCalls: () => dispatch(fetchAllProjectCallsAction()),
  setProjectCallFilterName: (value) => dispatch(setProjectCallFilterNameAction(value)),
  deleteProjectCall: (data) => dispatch(deleteProjectCallAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCalls);
