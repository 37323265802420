import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, Form, FormControl, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Loader } from '../../../component/index';
import {
  fetchAllTypes as fetchAllTypesAction,
} from '../../../state/types/typesActions';
import {
  fetchOneType as fetchOneTypeAction,
  updateType as updateTypeAction,
} from '../../../state/type/typeActions';
import '../../../styles/forms.scss';

function EditType(props) {
  const { id } = useParams();
  const {
    loading, type, types, fetchType, fetchAllTypes, updateType,
  } = props;

  useEffect(() => {
    fetchAllTypes();
    fetchType({ id: id !== 'new' ? id : null });
  }, [
    fetchAllTypes,
    fetchType,
    id,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required('The type name is mandatory'),
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{type.id ? 'Edit Type' : 'Create Type'}</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        id: type.id !== 'new' ? type.id : null,
                        name: type.name,
                        parent: ((type.parent) ? type.parent.id : null),
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        updateType({
                          ...values,
                          parent: values.parent === 'None' ? null : values.parent,
                        });
                        setSubmitting(false);
                      }}
                    >
                      { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Type name</Form.Label>
                                <Form.Control placeholder="Type name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} className={touched.name && errors.name ? 'error' : null} />
                                { touched.name && errors.name ? (<div className="error-message">{errors.name}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Form.Label>Parent</Form.Label>
                                <FormControl
                                  as="select"
                                  value={values.parent}
                                  name="parent"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value={undefined}>None</option>
                                  { types
                                    .filter((el) => el.parent === null)
                                    .sort((a, b) => (
                                      (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1))
                                    .map((el) => <option value={el.id} key={`parent_${el.id}`}>{ el.name }</option>)}
                                </FormControl>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Button variant="primary" type="submit" disabled={isSubmitting || errors.name}>
                                  {type.id ? 'Edit Type' : 'Create Type'}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditType.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.objectOf(PropTypes.any).isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchType: PropTypes.func.isRequired,
  fetchAllTypes: PropTypes.func.isRequired,
  updateType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.type.isLoading,
  type: state.type.data,
  types: state.types.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchType: (data) => dispatch(fetchOneTypeAction(data)),
  fetchAllTypes: () => dispatch(fetchAllTypesAction()),
  updateType: (data) => dispatch(updateTypeAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditType);
