import React, { useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faDownload,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import AuthenticatedLink from '../../../component/AuthenticatedLink';
import { projectFileBackendUrl, projectFileFrontendUrl } from '../../../utils';
import { Loader } from '../../../component';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '150px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const activeStyle = {
  borderColor: '#2196f3',
};
const acceptStyle = {
  borderColor: '#00e676',
};
const rejectStyle = {
  borderColor: '#ff1744',
};
function RepositoryContainer({
  files,
  numberOfFilesUploading,
  addFile,
  deleteFile,
  id,
}) {
  const onDrop = (acceptedFiles, attribute) => {
    acceptedFiles.forEach((acceptedFile) => {
      const data = new FormData();
      data.append('file', acceptedFile);
      data.append('projectId', id);
      data.append('attribute', attribute);
      data.append('type', 'project');
      addFile(data);
    });
  };


  const deleteFileFunc = (fileName, attribute) => {
    const data = { fileName, attribute, id };
    deleteFile(data);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*' });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragAccept,
    isDragActive,
    isDragReject,
  ]);
  return (
    <div className="text-center mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Title>Public files (max. size per file 500MB)</Card.Title>
            <Card.Body>
              {
                files.length === 0 || files.filter((fileObj) => fileObj.visibility === 'public').length === 0
                  ? <p>Add files to see them here (max. size per file 500MB)</p>
                  : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Download</th>
                          <th>URL</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          files.filter((fileObj) => fileObj.visibility === 'public').map((file) => (
                            <tr key={file.fileName + file.visibility}>
                              <td><a href={projectFileFrontendUrl(id, file.visibility, file.fileName)}>{file.fileName}</a></td>
                              <td>
                                <AuthenticatedLink url={projectFileBackendUrl(id, file.visibility, file.fileName)} filename={file.fileName}>
                                  <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faDownload} color="#357341" />
                                </AuthenticatedLink>
                              </td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(projectFileFrontendUrl(id, file.visibility, file.fileName)); }} icon={faCopy} color="#4682B4" /></td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => deleteFileFunc(file.fileName, file.visibility)} icon={faTrashAlt} color="red" /></td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  )
              }
              <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'public')}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag and drop some files here, or click to select files</p>
                    }
                    {
                      numberOfFilesUploading.public > 0 && (
                        <>
                          <p>
                            Uploading
                            {' '}
                            {numberOfFilesUploading.public}
                            {' '}
                            file(s)...
                          </p>
                          <Loader centered={false} />
                        </>
                      )
                    }
                  </div>
                )}
              </Dropzone>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Title>Private files (max. size per file 500MB)</Card.Title>
            <Card.Body>
              {
                files.length === 0 || files.filter((fileObj) => fileObj.visibility === 'private').length === 0
                  ? <p>Add files to see them here (max. size per file 500MB)</p>
                  : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Download</th>
                          <th>URL</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          files.filter((fileObj) => fileObj.visibility === 'private').map((file) => (
                            <tr key={file.fileName + file.visibility}>
                              <td><a href={projectFileFrontendUrl(id, file.visibility, file.fileName)}>{file.fileName}</a></td>
                              <td>
                                <AuthenticatedLink url={projectFileBackendUrl(id, file.visibility, file.fileName)} filename={file.fileName}>
                                  <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faDownload} color="#357341" />
                                </AuthenticatedLink>
                              </td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(projectFileFrontendUrl(id, file.visibility, file.fileName)); }} icon={faCopy} color="#4682B4" /></td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => deleteFileFunc(file.fileName, file.visibility)} icon={faTrashAlt} color="red" /></td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  )
              }
              <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'private')}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag and drop some files here, or click to select files</p>
                    }
                    {
                      numberOfFilesUploading.private > 0 && (
                        <>
                          <p>
                            Uploading
                            {' '}
                            {numberOfFilesUploading.private}
                            {' '}
                            file(s)...
                          </p>
                          <Loader centered={false} />
                        </>
                      )
                    }
                  </div>
                )}
              </Dropzone>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

RepositoryContainer.propTypes = {
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  numberOfFilesUploading: PropTypes.objectOf(PropTypes.any).isRequired,
  addFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default RepositoryContainer;
