import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { axiosClientNoGraphl } from '../axios';

function AuthenticatedLink({
  url, filename, className, children, onClick,
}) {
  const link = createRef();

  const handleAction = async () => {
    if (link.current.href) { return; }

    const result = await axiosClientNoGraphl.get(url, { responseType: 'blob' });

    const blob = result.data;
    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;

    link.current.click();

    if (onClick) {
      return onClick();
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a role='button' ref={link} className={className} onClick={handleAction}>{children}</a>
    </>
  );
}

AuthenticatedLink.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

AuthenticatedLink.defaultProps = {
  className: '',
  children: '',
  onClick: () => {},
};

export default AuthenticatedLink;
