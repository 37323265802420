
const QueryAllPeopleTypes = `
query{
  peopleTypes{
    id
    name
    kultype
    col
    formercol
    abbrv
    inactive
    weight
    statuses {
      id
    }
  }
}`;

export default QueryAllPeopleTypes;
