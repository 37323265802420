import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import { events } from '../../graphql/queries';
import {
  CREATE_EVENT,
  createEventError,
  createEventSuccess,
  DELETE_EVENT,
  deleteEventError,
  deleteEventSuccess,
  FETCH_ONE_EVENT,
  fetchOneEventError,
  fetchOneEventSuccess,
  UPDATE_EVENT,
  updateEventError,
  updateEventSuccess,
} from './eventActions';
import catchErrors from '../../graphql/sagas';
import { fetchAllEvents } from '../events/eventsActions';

const {
  createOneEvent,
  deleteOneEvent,
  updateOneEvent,
  queryOneEvent,
} = events;

export function* createEvent(action) {
  const response = yield call(axios.post, '/', { query: createOneEvent(action.data) });
  yield put(createEventSuccess(response.data.data.createEvent));
  yield put(push('/admin/events'));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Event created successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* updateEvent(action) {
  const response = yield call(axios.post, '/', { query: updateOneEvent(action.data) });
  yield put(updateEventSuccess(response.data.data.updateEvent));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Event updated successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* getOneEvent(action) {
  const response = yield call(axios.post, '/', { query: queryOneEvent(action.data.id) });
  yield put(fetchOneEventSuccess(response.data.data.event));
}


export function* deleteEvent(action) {
  const response = yield call(axios.post, '/', { query: deleteOneEvent(action.eventId) });

  yield put(deleteEventSuccess(response.data));
  yield put(fetchAllEvents());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Event deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export default function* () {
  yield takeLatest(
    FETCH_ONE_EVENT,
    catchErrors({
      onErrorAction: fetchOneEventError,
      overrideErrorMessage: 'Unable to retrieve event',
    })(getOneEvent),
  );

  yield takeLatest(
    DELETE_EVENT,
    catchErrors({
      onErrorAction: deleteEventError,
      overrideErrorMessage: 'Unable to delete event',
    })(deleteEvent),
  );

  yield takeLatest(
    CREATE_EVENT,
    catchErrors({
      onErrorAction: createEventError,
      overrideErrorMessage: 'Unable to create event',
    })(createEvent),
  );

  yield takeLatest(
    UPDATE_EVENT,
    catchErrors({
      onErrorAction: updateEventError,
      overrideErrorMessage: 'Unable to update event',
    })(updateEvent),
  );
}
