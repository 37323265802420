import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authorNameFormatter, getYearFromDate, idFormatter, replaceEdition, statusFormatter,
} from './typeHelper';
import {
  findPdf,
  pagesView,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function Rump(props) {
  const {
    id, title, status, canEdit, pages, from, edition, authors, index, proceedings, webVersion, webVersionRecording, visibility, files, isAuthenticated,
  } = props;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  return (
    <tr>
      <td>{index}</td>
      <td>
        {statusFormatter(status)}
        {isAuthenticated && `${idFormatter(id)} `}
        {authorNameFormatter(authors, true)}
        ,
        {' '}

        &quot;
        {titleView(id, title, visibility, pdfFile, showPublication, 'rump', isAdmin)}
        &quot;
        ,
        {' '}
        {replaceEdition(edition, proceedings, from)}
        {pagesView(pages)}
        ,
        {' '}
        {getYearFromDate(from)}
        .
        {' '}
        {showPublication && containsPdf
          ? pdfIcon(id, pdfFile, 'rump')
          : null}
        {webVersionRecordingIcon(webVersionRecording)}
        {webVersionIcon(webVersion)}
        <EditButton canEdit={canEdit} to={`/researcher/publications/updateConferencePaper/${id}`} />
      </td>
    </tr>
  );
}

Rump.propTypes = {
  id: PropTypes.string.isRequired,
  edition: PropTypes.number,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pages: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  proceedings: PropTypes.string.isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

Rump.defaultProps = {
  edition: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(Rump);
