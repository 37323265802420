import {
  CREATE_JOURNAL,
  CREATE_JOURNAL_ERROR,
  CREATE_JOURNAL_SUCCESS,
  FETCH_JOURNAL,
  FETCH_JOURNAL_ERROR,
  FETCH_JOURNAL_SUCCESS,
  LOAD_JOURNAL_REQUIREMENTS_SUCCESS,
  UPDATE_JOURNAL,
  UPDATE_JOURNAL_ERROR,
  UPDATE_JOURNAL_SUCCESS,
} from './actions';
import {calculatePageCount} from '../../../../utils';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateJournalRespones = (response) => ({
  types: 'journal',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  earlyAccess: response.earlyAccess,
  country: response.country,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
  publication: response.publication.id,
  volume: response.publication.volume,
  number: response.publication.number,
  journal: response.publication.journal.id,
  reviewed: response.reviewed,
  complete: response.complete,
  preprint: response.preprint,
  projects: response.projects,
  editors: response.editors.map((editor) => ({
    value: editor.id, label: `${editor.last}, ${editor.first}`,
  })),
});

const publicationJournal = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_JOURNAL_SUCCESS:
    case FETCH_JOURNAL_SUCCESS:
      return { ...state, details: dehydrateJournalRespones(action.payload), isLoading: false };

    case CREATE_JOURNAL_ERROR:
    case UPDATE_JOURNAL_ERROR:
    case FETCH_JOURNAL_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_JOURNAL_SUCCESS:
    case LOAD_JOURNAL_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_JOURNAL:
    case FETCH_JOURNAL:
    case UPDATE_JOURNAL:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationJournal;
