import moment from 'moment';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faLink, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import AuthenticatedLink from '../component/AuthenticatedLink';
import { urlMakerFrontend, urlMakerBackend } from '../component/publicationFormatTypes/typeHelper';
import config from '../config/config';
import Event from '../tracking';

export const filterList = (list, field, filter, characterSize = 1) => {
  if (filter && filter.length > characterSize) {
    return list.filter((el) => el[field].toLowerCase().includes(filter.toLowerCase()));
  }

  return list;
};

export const filteredCountries = (countries, filter) => filterList(
  filterList(countries, 'name', filter.name),
  'code',
  filter.code,
  0,
);

export const filteredFunders = (funders, filter) => filterList(funders, 'name', filter);
export const filteredPartners = (partners, filter) => filterList(partners, 'name', filter);
export const filteredTypes = (types, filter) => filterList(types, 'name', filter);
export const filteredProjectCalls = (types, filter) => filterList(types, 'name', filter);
export const filteredVenues = (journals, conferences, filter) => {
  const combined = [];

  journals.forEach((journal) => {
    combined.push({ id: journal.id, title: journal.title, type: 'journal' });
  });

  conferences.forEach((conference) => {
    combined.push({ id: conference.id, title: conference.conference, type: 'conference' });
  });

  return filterList(combined, 'title', filter);
};

export const generateAuthors = (people) => [
  ...people.slice(0).reverse().map(
    (person) => ({ value: person.id, label: `${person.first} ${person.last}` }),
  ),
];

export const generateYears = (allDates) => [
  ...allDates.slice(0).map((year) => ({ value: year, label: year })),
];

export const uniqueArray = (array) => array.filter((v, i, a) => a.findIndex((v2) => (JSON.stringify(v2) === JSON.stringify(v))) === i);

export const userWithPermission = (
  userPermissions,
  permission,
) => userPermissions.includes(permission);

export const filterEvents = (events, filters) => {
  let filteredEvents = [...events];

  if (filters.title) {
    filteredEvents = filterList(events, 'title', filters.title);
  }

  if (filters.type) {
    filteredEvents = filteredEvents.filter((event) => event.type === filters.type);
  }

  if (filters.startDate) {
    const startDateFilter = moment(filters.startDate);

    filteredEvents = filteredEvents.filter(
      (event) => moment(event.date).isSameOrAfter(startDateFilter),
    );
  }

  if (filters.endDate) {
    const endDateFilter = moment(filters.endDate);

    filteredEvents = filteredEvents.filter(
      (event) => moment(event.date).isSameOrBefore(endDateFilter),
    );
  }

  return filteredEvents;
};

const calculatePageCountFromRange = (pages) => {
  const valueList = pages.split('-');
  if (valueList.length === 2) {
    const [firstVal, secondVal] = valueList;
    const intFirst = parseInt(firstVal, 10);
    const intSecond = parseInt(secondVal, 10);
    if (!Number.isNaN(intFirst) && !Number.isNaN(intSecond)) {
      return intSecond - intFirst + 1;
    }
  }
  return false;
};
const calculatePageCountFromSingleValue = (pages) => {
  const intNumber = parseInt(pages, 10);
  return !Number.isNaN(intNumber) && intNumber > 0 ? intNumber : false;
};

export const calculatePageCount = (pages) => calculatePageCountFromRange(pages) || calculatePageCountFromSingleValue(pages) || 0;

export const pagesView = (pages) => {
  if (calculatePageCountFromRange(pages)) {
    return `, pp. ${pages}`;
  } if (calculatePageCountFromSingleValue(pages)) {
    return `, ${pages} pages`;
  }
  return `, ${pages}`;
};

export const withHost = (url) => `${window.location.protocol ? window.location.protocol : 'http:'}//${window.location.hostname}${url}`;
export const projectFileBackendUrl = (id, attribute, fileName) => withHost(`${config.API_URL}/files/downloadFile/project/${id}/${attribute}/${fileName}`);
export const projectFileFrontendUrl = (id, attribute, fileName) => withHost(`/files/${attribute}/project/${id}/${fileName}`);

export const findPdf = (files) => {
  let pdfFile;
  if (files) {
    pdfFile = files.find((f) => f?.path && f.path.includes('PDF'));
  }
  return pdfFile;
};

export const titleView = (id, title, visibility, file, showPublication, type, isAdmin) => {
  if (showPublication) {
    if (visibility || isAdmin) {
      return (<a href={urlMakerFrontend(id, type, visibility)} onClick={() => Event('DOWNLOAD', 'PUBLICATION DOWNLOAD', id)}>{title}</a>);
    }
    return (title);
  }
  return (title);
};

export const webVersionRecordingIcon = (link) => {
  if (link) {
    return (
      <a
        href={link}
        className="publication-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faPlayCircle} />
      </a>
    );
  }
  return '';
};

export const webVersionIcon = (link) => {
  if (link) {
    return (
      <a
        href={link}
        className="publication-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLink} />
      </a>
    );
  }
  return '';
};

export const pdfIcon = (id, file, type) => (
  <AuthenticatedLink
    url={urlMakerBackend(id, type)}
    filename={file.fileName}
    className="publication-pdf"
    onClick={() => Event('DOWNLOAD', 'PUBLICATION DOWNLOAD', id)}
  >
    <FontAwesomeIcon icon={faFilePdf} color="red" />
  </AuthenticatedLink>
);

export const shouldShowPublication = (isAdmin, containsPdf, visibility) => (isAdmin && containsPdf) || (containsPdf && (visibility || false));

export const addPublicPrivate = (s, p) => {
  const items = s.split('/');
  const last = items.pop();
  return `${items.join('/')}/${p}/${last}`;
};

export const serializeMoment = (val) => (val ? `"${moment(val).format('YYYY-MM-DD')}"` : null);
