import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authorNameFormatter, authorNameFormatterText, getYearFromDate, idFormatter, statusFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function Patent(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);
  const {
    id,
    title,
    status,
    description,
    date,
    authors,
    index,
    applicant,
    number,
    webVersion,
    webVersionRecording,
    visibility,
    files,
    canEdit,
    isAuthenticated,
  } = props;
  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };
  const bibTex = (`
    @MISC{patent${id},
      author =    {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      title =     {${title}},
      year =      {${getYearFromDate(date)}},
      note =      {${applicant && number ? `Patent number ${number} , ${applicant} (applicant)` : ''}},
    }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title} ,'' Patent number ${number || ''}, { ${applicant || ''} (applicant)}, ${getYearFromDate(date)}.
  `);
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;
                {titleView(id, title, visibility, pdfFile, showPublication, 'patent', isAdmin)}
                &quot;
                {description ? `, ${description}` : ''}
                ,
                {' '}
                {getYearFromDate(date)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'patent')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updatePatent/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

Patent.propTypes = {
  id: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  applicant: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

Patent.defaultProps = {
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(Patent);
