import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Card, Image, Container,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { Loader } from '../../index';
import {
  MUTATE_PERSON_UPDATE_RESEARCHDATA, UPLOAD_RESEARCHDATA_IMAGE,
} from '../../../state/person/personActions';
import config from '../../../config/config';
import defaultImage from '../../../assets/image.png';
import { FETCH_ALL_PEOPLE_PROMOTERS } from '../../../state/people/peopleActions';

const EditPeopleResearchDataForm = (props) => {
  const {
    loading,
    person,
    researchData,
    zaps,
    peoplePromoters,
    countries,
    mutateUpdateResearchData,
    isResearchImageLoading,
    uploadResearchImage,
    fetchPeoplePromoters,
  } = props;
  useEffect(() => {
    fetchPeoplePromoters();
  }, [fetchPeoplePromoters]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type.includes('image')) {
      const data = new FormData();
      data.append('file', acceptedFiles[0]);
      data.append('id', person.id);
      uploadResearchImage(data);
    }
  };

  const SignupSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    institution: Yup.string()
      .required('Institution is mandatory')
      .nullable(),
    researchDescription: Yup.string().required('Research description is required').nullable(),
    image: Yup.string().nullable(),
    zap: Yup.number()
      .required('Zap Responsible is mandatory'),
    promotor: Yup.number()
      .required('Promotor is mandatory')
      .nullable(),
    country: Yup.number()
      .required('Country is mandatory')
      .nullable(),
  });

  const countriesOptions = countries.map(
    (ccy) => (<option value={ccy.id} key={`countriesOptions_${ccy.id}`}>{ccy.name}</option>),
  );
  const zapsOptions = zaps.map(
    (zap) => (
      <option value={zap.id} key={`zapsOptions_${zap.id}`}>
        {zap.gn}
        &nbsp;
        {zap.sn}
      </option>
    ),
  );
  const promotorsOptions = peoplePromoters.map(
    (pp) => (
      <option value={pp.id} key={`promotorsOptions_${pp.id}`}>
        {pp.first || pp.firstname}
        &nbsp;
        {pp.last || pp.lastname}
      </option>
    ),
  );

  const displayForm = () => (
    <Formik
      initialValues={
      {
        personid: person.id,
        id: researchData?.id || null,
        degree: researchData?.degree || null,
        institution: researchData?.institution,
        researchDescription: researchData?.researchDescription || null,
        image: researchData?.image || null,
        zap: researchData?.zapResponsible?.id,
        promotor: researchData?.promotor?.id,
        country: researchData?.country?.id,
      }
}
      enableReinitialize
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        mutateUpdateResearchData(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Promotor</Form.Label>
              <Form.Control
                as="select"
                name="promotor"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.promotor}
                className="form-control"
              >
                <option />
                {promotorsOptions}
              </Form.Control>
              {touched.promotor && errors.promotor ? (<div className="error-message">{errors.promotor}</div>) : null}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>ZAP Responsible</Form.Label>
              <Form.Control
                as="select"
                name="zap"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zap}
                className="form-control"
              >
                <option />
                {zapsOptions}
              </Form.Control>
              {touched.zap && errors.zap ? (<div className="error-message">{errors.zap}</div>) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Highest obtained degree</Form.Label>
              <Form.Control
                name="degree"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.degree}
                className="form-control"
              />
              {touched.degree && errors.degree ? (<div className="error-message">{errors.degree}</div>) : null}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Institution of highest obtained degree</Form.Label>
              <Form.Control
                type="text"
                name="institution"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.institution}
                className="form-control"
              />
              {touched.institution && errors.institution ? (<div className="error-message">{errors.institution}</div>) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Country of highest obtained degree</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                className="form-control"
              >
                <option />
                {countriesOptions}
              </Form.Control>
              {touched.country && errors.country ? (<div className="error-message">{errors.country}</div>) : null}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Research Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="researchDescription"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.researchDescription}
                maxLength={8000}
                className="form-control"
                placeholder="What is your research about?"
              />
              {touched.researchDescription && errors.researchDescription ? (<div className="error-message">{errors.researchDescription}</div>) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%', marginTop: '1em' }}>
                Save
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
  return (
    <div>
      {loading
        ? <Loader />
        : (
          <>
            <Row>
              <Col xs={3}>
                <Card>
                  <Card.Header>Research image</Card.Header>
                  <Card.Body>
                    {
                      isResearchImageLoading || !(person.id) ? (
                        <Container>
                          <Row className="justify-content-center">
                            <Loader />
                          </Row>
                        </Container>
                      ) : (
                        <Image
                          src={`${config.API_URL}/files/downloadResearchPicture/${person.id}`}
                          onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                          style={{
                            display: 'block',
                            margin: 'auto',
                            height: '100%',
                            width: 'auto',
                          }}
                          fluid
                          rounded
                        />
                      )
                    }
                  </Card.Body>
                  <Card.Footer>
                    <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'public')}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Drag and drop research image here, or click to select image.</p>
                          <p>Please add a research image that adequately illustrates your research or research field. Make sure to respect copyright laws (use copyright-free images).</p>
                        </div>
                      )}
                    </Dropzone>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            {displayForm()}
          </>
        )}
    </div>
  );
};

EditPeopleResearchDataForm.propTypes = {
  loading: PropTypes.bool,
  person: PropTypes.objectOf(PropTypes.any).isRequired,
  researchData: PropTypes.objectOf(PropTypes.any),
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  zaps: PropTypes.arrayOf(PropTypes.any).isRequired,
  peoplePromoters: PropTypes.arrayOf(PropTypes.any).isRequired,
  mutateUpdateResearchData: PropTypes.func.isRequired,
  isResearchImageLoading: PropTypes.bool.isRequired,
  uploadResearchImage: PropTypes.func.isRequired,
  fetchPeoplePromoters: PropTypes.func.isRequired,
};

EditPeopleResearchDataForm.defaultProps = {
  loading: false,
  researchData: {},
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  person: state.person.data,
  researchData: state.person.data.researchData,
  zaps: state.person.zaps,
  peoplePromoters: state.people.peoplePromoters,
  countries: state.countries.data,
  isResearchImageLoading: state.person.isResearchImageLoading,
});

const mapDispatchToProps = (dispatch) => ({
  mutateUpdateResearchData: (data) => dispatch(MUTATE_PERSON_UPDATE_RESEARCHDATA(data)),
  uploadResearchImage: (data) => dispatch(UPLOAD_RESEARCHDATA_IMAGE(data)),
  fetchPeoplePromoters: () => dispatch(FETCH_ALL_PEOPLE_PROMOTERS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleResearchDataForm);
