
export const QueryAllVenues = `
{
  journals{
    id
    acronym
    title
    category
    attributes
  }
  conferences{
    id
    acronym
    conference
    category
    attributes
  }
}`;

export const QueryOneVenue = (id) => `
query {
  locationById (id:"${id}"){
    id
    venue
  }
}`;


export const QueryOneJournal = (id) => `
{
  journalById(id: ${id}) {
    id
    acronym
    title
    category
    attributes
    publisher
  }
}`;


export const QueryOneConference = (id) => `
{
  conferenceById(id: ${id}){
    id
    acronym
    conference
    category
    attributes
    publisher
    proceedings
  }
}`;

export const MutateCreateJournal = (venue) => `
mutation{
  createJournal(input: { id: null, title: """${venue.venue}""", category: ${venue.category}, attributes: "${venue.attributes}", acronym: """${venue.acronym}""", publisher: ${venue.publisher ? `"${venue.publisher}"` : null} }){
    id
    acronym
    title
    category
    attributes
    publisher
  }
}`;

export const MutateCreateConference = (venue) => `
mutation{
  createConferenceInput(input: { id: null, title: """${venue.proceedings}""", conference: """${venue.venue}""", category: ${venue.category}, attributes: "${venue.attributes}", acronym: """${venue.acronym}""", publisher: ${venue.publisher ? `"${venue.publisher}"` : null} }){
    id
    acronym
    conference
    category
    attributes
    proceedings
    publisher
  }
}`;


export const MutateUpdateJournal = (venue) => `
mutation{
  updateJournal(input: { id: ${venue.id}, title: """${venue.venue}""", category: ${venue.category}, attributes: "${venue.attributes}", acronym: """${venue.acronym}""", publisher: ${venue.publisher ? `"${venue.publisher}"` : null} }){
    id
    acronym
    title
    category
    attributes
    publisher
  }
}`;

export const MutateUpdateConference = (venue) => `
mutation{
  updateConferenceInput(input: { id: ${venue.id}, title: """${venue.proceedings}""", conference: """${venue.venue}""", category: ${venue.category}, attributes: "${venue.attributes}", acronym: """${venue.acronym}""", publisher: ${venue.publisher ? `"${venue.publisher}"` : null} }){
    id
    acronym
    conference
    category
    attributes
    publisher
    proceedings
  }
}`;

export const MutateDeleteJournal = (journalId) => `
mutation {
  deleteJournal(id:${journalId})
}`;

export const MutateDeleteConference = (conferenceId) => `
mutation {
  deleteConferenceInput(id:${conferenceId})
}`;
