import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import { Loader } from '../../../component/index';
import {
  deleteVenue as deleteVenueAction,
  fetchAllVenues as fetchAllVenuesAction,
  setVenueFilterName as setVenueFilterNameAction,
} from '../../../state/venues/venuesActions';
import { filteredVenues } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Venues = (props) => {
  const {
    loading,
    filter,
    journals,
    conferences,
    fetchAllVenues,
    setVenueFilterName,
    deleteVenue,
  } = props;
  const history = useHistory();

  useEffect(() => { fetchAllVenues(); }, [fetchAllVenues]);

  const data = filteredVenues(journals, conferences, filter);

  const [deleteVenueInfo, setDeleteVenueInfo] = useState(null);

  const onConfirmDelete = () => {
    deleteVenue(deleteVenueInfo);
    setDeleteVenueInfo(null);
  };

  const columns = [
    { Header: 'Name', accessor: 'title' },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/admin/settings/venues/${row.original.type}/${row.original.id}`)}
          >
Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
            onClick={() => setDeleteVenueInfo(row.original)}
          >
Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Venue name</Form.Label>
                      <Form.Control placeholder="Venue name" value={filter} onChange={(val) => setVenueFilterName(val.target.value)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Venues
              <Button
                onClick={() => {
                  history.push('/admin/settings/venues/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <ReactTable
                    columns={columns}
                    data={data}
                    defaultSorted={[
                      {
                        id: 'title',
                        desc: false,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              <DeleteConfirmation
                text={deleteVenueInfo !== null ? `Delete venue ${deleteVenueInfo.title}` : ''}
                show={deleteVenueInfo !== null}
                onCancel={() => setDeleteVenueInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Venues.propTypes = {
  loading: PropTypes.bool.isRequired,
  journals: PropTypes.arrayOf(PropTypes.any).isRequired,
  conferences: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.string.isRequired,
  fetchAllVenues: PropTypes.func.isRequired,
  setVenueFilterName: PropTypes.func.isRequired,
  deleteVenue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.venues.isLoading,
  journals: state.venues.journals,
  conferences: state.venues.conferences,
  filter: state.venues.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllVenues: () => dispatch(fetchAllVenuesAction()),
  setVenueFilterName: (value) => dispatch(setVenueFilterNameAction(value)),
  deleteVenue: (data) => dispatch(deleteVenueAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Venues);
