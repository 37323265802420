import React from 'react';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';

const AutoCompleteField = (props) => {
  const {
    dataSource,
    onChange,
    defaultValue,
    isLoading,
    filterValue,
  } = props;

  const filterOptions = (data, inputValue) => (data ? (
    data.filter((d) => d[filterValue].includes(inputValue)).map((fd) => ({
      label: (
        <span>
          <strong style={{ color: 'red' }}>
              Exists |
          </strong>
          {` ${fd[filterValue]}`}
        </span>
      ),
      value: fd[filterValue],
      disabled: true,
    }))
  ) : []);

  const loadOptions = (inputValue, callback) => {
    const filteredOptions = filterOptions(dataSource, inputValue);
    callback(filteredOptions);
  };

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      value={{ value: defaultValue, label: defaultValue }}
      onChange={onChange}
      isLoading={isLoading}
      isOptionDisabled={(option) => option.disabled === true}
      noOptionsMessage={() => (
        <span>
          <strong style={{ color: 'green' }}>
            ✓
          </strong>
          {' '}
          <em>
            Available
          </em>
        </span>
      )}
    />
  );
};

AutoCompleteField.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultValue: PropTypes.string,
  filterValue: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

AutoCompleteField.defaultProps = {
  defaultValue: undefined,
  filterValue: 'title',
  isLoading: false,
  onChange: () => {},
};

export default AutoCompleteField;
