import * as yup from 'yup';
import moment from 'moment';

function isValidCountryName(ref, msg) {
  return yup.mixed().test({
    name: 'isValidCountry',
    exclusive: false,
    message: msg || 'Must be a valid country name',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value;
    },
  });
}

yup.addMethod(yup.string, 'isValidCountryName', isValidCountryName);

function timeGreaterThen(ref, msg) {
  return yup.mixed().test({
    name: 'timeGreaterThen',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must end after ${reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      const start = this.resolve(ref);
      return start !== undefined
        ? moment(value, 'HH:mm').isAfter(moment(start, 'HH:mm'))
        : true;
    },
  });
}

yup.addMethod(yup.string, 'timeGreaterThen', timeGreaterThen);


export default yup;
