import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';

import PropTypes from 'prop-types';
import Select from 'react-select';
import { Loader } from '../../index';

const MemberOfTeams = ({
  loading,
  addMembership,
  allMemberships,
  memberships,
  peopleId,
  removeMembership,
  canEdit,
}) => {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [formattedMemberships, setFormattedMemberships] = useState([]);

  const handleMembershipChange = (val) => {
    setSelectedMembership(val);
  };

  const handleAddMembership = () => {
    addMembership(peopleId, selectedMembership.value);

    setFormattedMemberships(
      formattedMemberships.filter((membership) => membership.id !== selectedMembership.value),
    );
    setSelectedMembership(null);
  };

  const handleRemoveMembership = (teamId) => {
    removeMembership(peopleId, teamId);
  };

  useEffect(() => {
    const filtered = [];
    allMemberships.filter(
      (l) => !memberships.map((ls) => ls.id).includes(l.id),
    ).forEach((membership) => {
      filtered.push({ value: membership.id, label: membership.name });
    });
    setFormattedMemberships(filtered);
  }, [allMemberships, memberships]);

  return (
    <Container fluid>

      { canEdit
        && (
        <>
          <Row>
            <Col>
              <Select
                name="type"
                options={formattedMemberships}
                onChange={(val) => handleMembershipChange(val)}
                value={selectedMembership}
              />
            </Col>
            <Col>
              <Button disabled={!selectedMembership} variant="primary" onClick={() => handleAddMembership()}>Add membership</Button>
            </Col>
          </Row>
          <hr />
        </>
        )}
      <Row>
        {loading
          ? <Loader />
          : (
            <Col>
              <Card className="filter-card">
                <table className="table table-card table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Team Name</th>
                      {canEdit && <th scope="col">Delete</th> }
                    </tr>
                  </thead>
                  <tbody>
                    {
                  memberships.map((membership) => (
                    <tr key={membership.id}>
                      <th>{membership.name}</th>
                      {canEdit && (
                      <td className="text-right" style={{ width: 1 }}>
                        <button onClick={() => handleRemoveMembership(membership.id)} className="btn btn-sm btn-danger ml-auto">Delete</button>
                      </td>
                      ) }
                    </tr>
                  ))
                }
                  </tbody>
                </table>
              </Card>
            </Col>
          )}
      </Row>
    </Container>
  );
};

MemberOfTeams.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  addMembership: PropTypes.func.isRequired,
  allMemberships: PropTypes.arrayOf(PropTypes.object).isRequired,
  memberships: PropTypes.arrayOf(PropTypes.object).isRequired,
  peopleId: PropTypes.string.isRequired,
  removeMembership: PropTypes.func.isRequired,
};

export default MemberOfTeams;
