export const FETCH_ONE_PROJECT_CALL = 'FETCH_ONE_PROJECT_CALL';
export const FETCH_ONE_PROJECT_CALL_SUCCESS = 'FETCH_ONE_PROJECT_CALL_SUCCESS';
export const FETCH_ONE_PROJECT_CALL_ERROR = 'FETCH_ONE_PROJECT_CALL_ERROR';
export const UPDATE_PROJECT_CALL = 'UPDATE_PROJECT_CALL';
export const UPDATE_PROJECT_CALL_SUCCESS = 'UPDATE_PROJECT_CALL_SUCCESS';
export const UPDATE_PROJECT_CALL_ERROR = 'UPDATE_PROJECT_CALL_ERROR';

export const fetchOneProjectCall = (data) => ({ type: FETCH_ONE_PROJECT_CALL, data });
export const fetchOneProjectCallError = (error) => ({ type: FETCH_ONE_PROJECT_CALL_ERROR, error });
export const fetchOneProjectCallSuccess = (data) => ({ type: FETCH_ONE_PROJECT_CALL_SUCCESS, data });
export const updateProjectCall = (data) => ({ type: UPDATE_PROJECT_CALL, data });
export const updateProjectCallError = (error) => ({ type: UPDATE_PROJECT_CALL_ERROR, error });
export const updateProjectCallSuccess = (data) => ({ type: UPDATE_PROJECT_CALL_SUCCESS, data });
