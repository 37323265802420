import React from 'react';
import PropType from 'prop-types';
import ByYearWrapper from '../../../component/publicationFormatTypes/ByYearWrapper';

const ProjectPublicationsContainer = ({ publications }) => (
  <ByYearWrapper pby={publications} />
);

ProjectPublicationsContainer.propTypes = {
  publications: PropType.arrayOf(PropType.any).isRequired,
};

export default ProjectPublicationsContainer;
