import {call, fork, put, select, take, takeLatest,} from 'redux-saga/effects';
import {store} from 'react-notifications-component';
import {push} from 'connected-react-router';
import Axios from 'axios';
import {failedNotificationTimer} from '../../config/constants';
import catchErrors from '../../graphql/sagas';
import {
  createAbstractOfTalk,
  createBook,
  createBookArticle,
  createConferenceArticle,
  createJournalArticle,
  createPatent,
  createProceeding,
  createReport,
  createTalk,
  createThese,
  DeletePublication,
  linkProjectWithPublication as linkProjectWithPublicationQuery,
  QueryAllPublications,
  QuerybookArticleById,
  QuerybookById,
  QueryconferenceArticleById,
  QueryjournalArticleById,
  QuerypatentById,
  QueryproceedingById,
  QueryProceedings,
  QueryPublicationPeople,
  QueryreportById,
  QuerytalkArticleById,
  QuerytalkById,
  QueryTeams,
  QuerytheseById,
  QueryVolumes,
  unlinkProjectFromPublication as unlinkProjectFromPublicationQuery,
  updateAbstractOfTalk,
  updateBook,
  updateBookArticle,
  updateConferenceArticle,
  updateJournalArticle,
  updatePatent,
  updateProceeding,
  updateReport,
  updateTalk,
  updateThese,
} from '../../graphql/queries/publications';
import {
  DELETE_PUBLICATION,
  DELETE_PUBLICATION_BY_ID_ERROR,
  DELETE_PUBLICATION_BY_ID_SUCCESS,
  DELETE_PUBLICATION_FILES_ERROR,
  DELETE_PUBLICATION_FILES_PDF_SUCCESS,
  DELETE_PUBLICATION_FILES_SUCCESS,
  deletePublicationError,
  deletePublicationSuccess,
  FETCH_ALL_PUBLICATIONS_ERROR,
  FETCH_ALL_PUBLICATIONS_SUCCESS,
  FETCH_PUBLICATION_ERROR,
  FETCH_PUBLICATION_FILES_ERROR,
  FETCH_PUBLICATION_FILES_PDF_SUCCESS,
  FETCH_PUBLICATION_FILES_SUCCESS,
  FETCH_PUBLICATION_PEOPLE_ERROR,
  FETCH_PUBLICATION_PEOPLE_SUCCESS,
  FETCH_PUBLICATION_PROCEEDINGS_ERROR,
  FETCH_PUBLICATION_PROCEEDINGS_SUCCESS,
  FETCH_PUBLICATION_SUCCESS,
  FETCH_PUBLICATION_TEAMS_ERROR,
  FETCH_PUBLICATION_TEAMS_SUCCESS,
  FETCH_PUBLICATION_VOLUMES_ERROR,
  FETCH_PUBLICATION_VOLUMES_SUCCESS,
  LINK_PROJECT_WITH_PUBLICATION,
  linkProjectWithPublicationError,
  linkProjectWithPublicationSuccess,
  UNLINK_PROJECT_FROM_PUBLICATION,
  unlinkProjectFromPublicationError,
  unlinkProjectFromPublicationSuccess,
  UPLOAD_PUBLICATION_FILES_ERROR,
  UPLOAD_PUBLICATION_FILES_PDF_SUCCESS,
  UPLOAD_PUBLICATION_FILES_SUCCESS,
} from './publicationActions';
import axios, {axiosClientNoGraphl} from '../../axios';
// import store from '../store';
import {
  selectFilterAuthorList,
  selectFilterConferenceDateFrom,
  selectFilterConferenceDateTo,
  selectFilterPublicationTypeList,
  selectFilterPublishingDateFrom,
  selectFilterPublishingDateTo,
  selectFilterTeam,
  selectFilterYearList,
  selectLimitToAuthor,
  selectLimitToInternational,
  selectLimitToReviewed,
  selectStatus,
} from './selectors';
import config from '../../config/config';
import {loadAbstractOfTalkRequirements} from '../../container/admin/publications/AbstractOfTalk/actions';
import {loadBookRequirements} from '../../container/admin/publications/Book/actions';
import {loadBookchapterRequirements} from '../../container/admin/publications/Bookchapter/actions';
import {loadConferencePaperRequirements} from '../../container/admin/publications/ConferencePaper/actions';
import {loadJournalRequirements} from '../../container/admin/publications/Journal/actions';
import {loadPatentRequirements} from '../../container/admin/publications/Patent/actions';
import {loadProceedingRequirements} from '../../container/admin/publications/Proceeding/actions';
import {loadReportRequirements} from '../../container/admin/publications/Report/actions';
import {loadTalkRequirements} from '../../container/admin/publications/Talk/actions';
import {loadTheseRequirements} from '../../container/admin/publications/These/actions';

const { addNotification } = store;
let cancelToken;
export function* fetchAllPublicationsWithFilter() {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = Axios.CancelToken.source();
  try {
    const years = yield select(selectFilterYearList);
    const authors = yield select(selectFilterAuthorList);
    const publicationTypes = yield select(selectFilterPublicationTypeList);
    const teamId = yield select(selectFilterTeam);
    const limitReviewed = yield select(selectLimitToReviewed);
    const limitInternational = yield select(selectLimitToInternational);
    const limitAuthor = yield select(selectLimitToAuthor);
    const status = yield select(selectStatus);
    const publicationDateFrom = yield select(selectFilterPublishingDateFrom);
    const publicationDateTo = yield select(selectFilterPublishingDateTo);
    const conferenceDateFrom = yield select(selectFilterConferenceDateFrom);
    const conferenceDateTo = yield select(selectFilterConferenceDateTo);

    const response = yield call(axios.post, '/', {
      query: QueryAllPublications(
        [],
        years,
        publicationDateFrom,
        publicationDateTo,
        conferenceDateFrom,
        conferenceDateTo,
        authors,
        publicationTypes,
        limitInternational,
        limitReviewed,
        limitAuthor,
        status ? [`"${status}"`] : [],
        teamId,
      ),
    }, { cancelToken: cancelToken.token });

    yield put(FETCH_ALL_PUBLICATIONS_SUCCESS(response.data.data.publicationsFilterAdmin));
  } catch (e) {
    if (!Axios.isCancel(e)) {
      yield put(FETCH_ALL_PUBLICATIONS_ERROR(e));
    }
  }
}

function* deletePublicationById(action) {
  try {
    const { id, type } = action.data;
    yield call(axios.post, '/', { query: DeletePublication(id, type) });
    yield put(DELETE_PUBLICATION_BY_ID_SUCCESS());
    yield put(push('/admin/publications'));

    store.addNotification({
      title: 'SUCCESS!',
      message: 'Publication succesfully Deleted!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  } catch (e) {
    store.addNotification({
      title: 'Something went wrong',
      message: 'Unable to delete Publication' || e.message,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    yield put(DELETE_PUBLICATION_BY_ID_ERROR(e));
  }
}

function* getPublicationPeople() {
  try {
    const response = yield call(axios.post, '/', { query: QueryPublicationPeople });
    yield put(FETCH_PUBLICATION_PEOPLE_SUCCESS(response.data.data.allPeoplePeople));
  } catch (e) {
    yield put(FETCH_PUBLICATION_PEOPLE_ERROR(e));
  }
}

function* getVolumes() {
  try {
    const response = yield call(axios.post, '/', { query: QueryVolumes });
    yield put(FETCH_PUBLICATION_VOLUMES_SUCCESS(response.data.data.volumes));
  } catch (e) {
    yield put(FETCH_PUBLICATION_VOLUMES_ERROR(e));
  }
}

function* getTeams() {
  try {
    const response = yield call(axios.post, '/', { query: QueryTeams });
    yield put(FETCH_PUBLICATION_TEAMS_SUCCESS(response.data.data.teams));
  } catch (e) {
    yield put(FETCH_PUBLICATION_TEAMS_ERROR(e));
  }
}

function* getProceedings() {
  try {
    const response = yield call(axios.post, '/', { query: QueryProceedings });
    yield put(FETCH_PUBLICATION_PROCEEDINGS_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_PROCEEDINGS_ERROR(e));
  }
}

function* createJournalPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createJournalArticle(action.data) });
    const { id } = response.data.data.createJournalArticle;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createJournalArticle));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/journal/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to Create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createThesisPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createThese(action.data) });
    const { id } = response.data.data.createThese;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createThese));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/these/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to Create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createReportPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createReport(action.data) });
    const { id } = response.data.data.createReport;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createReport));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/report/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to Create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createProceedingPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createProceeding(action.data) });
    const { id } = response.data.data.createProceeding;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createProceeding));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/proceeding/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createConferenceArticlePublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createConferenceArticle(action.data) });
    const { id } = response.data.data.createConferenceArticle;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createConferenceArticle));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/conferencepaper/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createBookChapterPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createBookArticle(action.data) });
    const { id } = response.data.data.createBookArticle;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createBookArticle));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/bookchapter/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createBookPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createBook(action.data) });
    const { id } = response.data.data.createBook;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createBook));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/book/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createAbstractOfTalkPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createAbstractOfTalk(action.data) });
    const { id } = response.data.data.createTalkArticle;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createTalkArticle));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/abstractOfTalk/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createTalkPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createTalk(action.data) });
    const { id } = response.data.data.createTalk;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createTalk));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/talk/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createPatentPublication(action) {
  try {
    const { isResearcher } = action.data;
    const response = yield call(axios.post, '/', { query: createPatent(action.data) });
    const { id } = response.data.data.createPatent;
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.createPatent));
    addNotification({
      title: 'SUCCESS!',
      message: 'Submission succesfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/${isResearcher ? 'researcher' : 'admin'}/publications/patent/${id}`));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to create Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateJournalPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateJournalArticle(action.data) });
    const data = {
      ...response.data.data.updateJournalArticle,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateProceedingsPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateProceeding(action.data) });
    const data = {
      ...response.data.data.updateProceeding,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateConferenceArticlePublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateConferenceArticle(action.data) });
    const data = {
      ...response.data.data.updateConferenceArticle,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateBookPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateBook(action.data) });
    const data = {
      ...response.data.data.updateBook,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateReportPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateReport(action.data) });
    const data = {
      ...response.data.data.updateReport,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateBookChapterPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateBookArticle(action.data) });
    const data = {
      ...response.data.data.updateBookArticle,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updatePatentPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updatePatent(action.data) });
    const data = {
      ...response.data.data.updatePatent,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateThesisPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateThese(action.data) });
    const data = {
      ...response.data.data.updateThese,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateAbstractOfTalkPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateAbstractOfTalk(action.data) });
    const data = {
      ...response.data.data.updateTalkArticle,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateTalkPublication(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateTalk(action.data) });
    const data = {
      ...response.data.data.updateTalk,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
    addNotification({
      title: 'SUCCESS!',
      message: 'Changes have been saved.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Unable to update Submission',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* QueryJournalArticlePublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryjournalArticleById(action.data.id) });
    yield put(FETCH_PUBLICATION_SUCCESS(response.data.data.journalArticleById));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: '/admin/publications/journal/error',
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryProceedingsPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryproceedingById(action.data.id) });
    const data = {
      ...response.data.data.proceedingById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryTalkPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerytalkById(action.data.id) });
    const data = {
      ...response.data.data.talkById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryThesisPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerytheseById(action.data.id) });
    const data = {
      ...response.data.data.theseById,
      type: action.data.type,
      thesisType: response.data.data.theseById.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryPatentPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerypatentById(action.data.id) });
    const data = {
      ...response.data.data.patentById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryReportPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryreportById(action.data.id) });
    const data = {
      ...response.data.data.reportById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryAbstractOfTalkById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerytalkArticleById(action.data.id) });
    const data = {
      ...response.data.data.talkArticleById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QuerybookPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerybookById(action.data.id) });
    const data = {
      ...response.data.data.bookById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryBookChapterPublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerybookArticleById(action.data.id) });
    const data = {
      ...response.data.data.bookArticleById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}

function* QueryConferenceArticlePublicationById(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryconferenceArticleById(action.data.id) });
    const data = {
      ...response.data.data.conferenceArticleById,
      type: action.data.type,
    };
    yield put(FETCH_PUBLICATION_SUCCESS(data));
  } catch (e) {
    yield put(FETCH_PUBLICATION_ERROR(e));
    yield put(push({
      pathname: `/admin/publications/${action.data.type}/error`,
      state: {
        entity: 'publication',
        id: action.data.id,
      },
    }));
  }
}
function* fetchFiles(action) {
  try {
    const response = yield call(axiosClientNoGraphl.get, `/publications/files/listPublications/${action.pubType}/${action.id}/${action.category}`);
    if (action.category === 'PDF') {
      yield put(FETCH_PUBLICATION_FILES_PDF_SUCCESS(response.data));
    } else {
      yield put(FETCH_PUBLICATION_FILES_SUCCESS(response.data));
    }
  } catch (e) {
    yield put(FETCH_PUBLICATION_FILES_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: `Failed to fetch files [id: ${action.id}]`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* addFile(action) {
  try {
    const response = yield call(axiosClientNoGraphl.post, '/publications/files/uploadPublication', action.data, { headers: { 'content-type': 'multipart/form-data' } });
    if (action.category === 'PDF') {
      yield put(UPLOAD_PUBLICATION_FILES_PDF_SUCCESS(response.data, action.category, action.attribute));
    } else {
      yield put(UPLOAD_PUBLICATION_FILES_SUCCESS(response.data, action.category, action.attribute));
    }
  } catch (e) {
    yield put(UPLOAD_PUBLICATION_FILES_ERROR(e, action.category, action.attribute));
    addNotification({
      title: 'ERROR!',
      message: 'Failed to add file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* deleteFile(action) {
  try {
    yield call(axiosClientNoGraphl.delete, `/publications/files/deletePublication/${action.data.publicationType}/${action.data.id}/${action.data.category}/${action.data.attribute}/${action.data.fileName}`);
    if (action.data.category === 'PDF') {
      yield put(DELETE_PUBLICATION_FILES_PDF_SUCCESS(action.data));
    } else {
      yield put(DELETE_PUBLICATION_FILES_SUCCESS(action.data));
    }
  } catch (e) {
    yield put(DELETE_PUBLICATION_FILES_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: 'Failed to delete file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function downloadFile(action) {
  try {
    window.open(`${config.API_URL}/publications/files/downloadPublication/${action.data.publicationType}/${action.data.id}/${action.data.category}/${action.data.attribute}/${action.data.fileName}`);
  } catch (e) {
    addNotification({
      title: 'ERROR!',
      message: 'Failed to download file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

export function* fetchAllPublicationsWithFilterSagaWatcher() {
  while (true) {
    const action = yield take([
      'FETCH_ALL_PUBLICATIONS',
      'SET_PUBLICATION_FILTER_YEARS',
      'SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM',
      'SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO',
      'SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM',
      'SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO',
      'SET_PUBLICATION_FILTER_AUTHORS',
      'SET_PUBLICATION_FILTER_PUBLICATIONTYPES',
      'SET_PUBLICATION_FILTER_STATUS',
      'SET_PUBLICATION_FILTER_INTERNATIONAL',
      'SET_PUBLICATION_FILTER_REVIEWED',
      'SET_PUBLICATION_FILTER_AUTHOR_LIMIT',
      'SET_PUBLICATION_FILTER_TEAM',
    ]);
    yield fork(fetchAllPublicationsWithFilter, action);
  }
}

export function* fetchPublicationPeoplesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PUBLICATION_PEOPLE');
    yield fork(getPublicationPeople, action);
  }
}

export function* fetchPublicationVolumesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PUBLICATION_VOLUMES');
    yield fork(getVolumes, action);
  }
}

export function* fetchPublicationTeamsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PUBLICATION_TEAMS');
    yield fork(getTeams, action);
  }
}

export function* fetchPublicationProceedingsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PUBLICATION_PROCEEDINGS');
    yield fork(getProceedings, action);
  }
}
export function* fetchPublicationFilesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PUBLICATION_FILES');
    yield fork(fetchFiles, action);
  }
}
export function* uploadPublicationFilesSagaWatcher() {
  while (true) {
    const action = yield take('UPLOAD_PUBLICATION_FILES');
    yield fork(addFile, action);
  }
}

export function* deletePublicationFilesSagaWatcher() {
  while (true) {
    const action = yield take('DELETE_PUBLICATION_FILES');
    yield fork(deleteFile, action);
  }
}

export function* downloadPublicationFilesSagaWatcher() {
  while (true) {
    const action = yield take('DOWNLOAD_PUBLICATION_FILES');
    yield fork(downloadFile, action);
  }
}
export function* createPublicationSagaWatcher() {
  while (true) {
    const action = yield take('CREATE_PUBLICATION');
    if (action.data.types === 'journal') {
      yield fork(createJournalPublication, action);
    } else if (action.data.types === 'proceeding') {
      yield fork(createProceedingPublication, action);
    } else if (action.data.types === 'conferencepaper') {
      yield fork(createConferenceArticlePublication, action);
    } else if (action.data.types === 'book') {
      yield fork(createBookPublication, action);
    } else if (action.data.types === 'abstractoftalk') {
      yield fork(createAbstractOfTalkPublication, action);
    } else if (action.data.types === 'talk') {
      yield fork(createTalkPublication, action);
    } else if (action.data.types === 'these') {
      yield fork(createThesisPublication, action);
    } else if (action.data.types === 'report') {
      yield fork(createReportPublication, action);
    } else if (action.data.types === 'patent') {
      yield fork(createPatentPublication, action);
    } else if (action.data.types === 'bookchapter') {
      yield fork(createBookChapterPublication, action);
    } else {
      yield put(FETCH_PUBLICATION_ERROR({ message: 'Invalid Submission Type' }));
      addNotification({
        title: 'ERROR!',
        message: 'Unable to create Submission',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: failedNotificationTimer,
          onScreen: true,
        },
      });
    }
  }
}

export function* updatePublicationSagaWatcher() {
  while (true) {
    const action = yield take('UPDATE_PUBLICATION');
    if (action.data.type === 'journal') {
      yield fork(updateJournalPublication, action);
    } else if (action.data.types === 'proceeding') {
      yield fork(updateProceedingsPublication, action);
    } else if (action.data.types === 'conferencepaper') {
      yield fork(updateConferenceArticlePublication, action);
    } else if (action.data.types === 'book') {
      yield fork(updateBookPublication, action);
    } else if (action.data.types === 'abstractoftalk') {
      yield fork(updateAbstractOfTalkPublication, action);
    } else if (action.data.types === 'talk') {
      yield fork(updateTalkPublication, action);
    } else if (action.data.types === 'these') {
      yield fork(updateThesisPublication, action);
    } else if (action.data.types === 'report') {
      yield fork(updateReportPublication, action);
    } else if (action.data.types === 'patent') {
      yield fork(updatePatentPublication, action);
    } else if (action.data.types === 'bookchapter') {
      yield fork(updateBookChapterPublication, action);
    }
  }
}

export function* fetchOnePublicationSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ONE_PUBLICATION');
    if (action.data.type === 'journal') {
      yield fork(QueryJournalArticlePublicationById, action);
    } else if (action.data.type === 'proceeding') {
      yield fork(QueryProceedingsPublicationById, action);
    } else if (action.data.type === 'conferencepaper') {
      yield fork(QueryConferenceArticlePublicationById, action);
    } else if (action.data.type === 'book') {
      yield fork(QuerybookPublicationById, action);
    } else if (action.data.type === 'abstractoftalk') {
      yield fork(QueryAbstractOfTalkById, action);
    } else if (action.data.type === 'talk') {
      yield fork(QueryTalkPublicationById, action);
    } else if (action.data.type === 'these') {
      yield fork(QueryThesisPublicationById, action);
    } else if (action.data.type === 'report') {
      yield fork(QueryReportPublicationById, action);
    } else if (action.data.type === 'patent') {
      yield fork(QueryPatentPublicationById, action);
    } else if (action.data.type === 'bookchapter') {
      yield fork(QueryBookChapterPublicationById, action);
    }
  }
}

export function* deletePublicationSagaWatcher() {
  while (true) {
    const action = yield take('DELETE_PUBLICATION_BY_ID');
    yield fork(deletePublicationById, action);
  }
}

export function* linkProjectWithPublication(action) {
  yield call(axios.post, '/', {
    query: linkProjectWithPublicationQuery(
      action.data.publicationId,
      action.data.projectId,
      action.data.publicationType,
    ),
  });

  switch (action.data.publicationType) {
    case 'abstractOfTalk':
      yield put(loadAbstractOfTalkRequirements({ id: action.data.publicationId }));
      break;
    case 'book':
      yield put(loadBookRequirements({ id: action.data.publicationId }));
      break;
    case 'bookChapter':
      yield put(loadBookchapterRequirements({ id: action.data.publicationId }));
      break;
    case 'conferencePaper':
      yield put(loadConferencePaperRequirements({ id: action.data.publicationId }));
      break;
    case 'journal':
      yield put(loadJournalRequirements({ id: action.data.publicationId }));
      break;
    case 'patent':
      yield put(loadPatentRequirements({ id: action.data.publicationId }));
      break;
    case 'report':
      yield put(loadReportRequirements({ id: action.data.publicationId }));
      break;
    case 'proceeding':
      yield put(loadProceedingRequirements({ id: action.data.publicationId }));
      break;
    case 'talk':
      yield put(loadTalkRequirements({ id: action.data.publicationId }));
      break;
    case 'these':
      yield put(loadTheseRequirements({ id: action.data.publicationId }));
      break;
    default:
      // do nothing
  }

  yield put(linkProjectWithPublicationSuccess());
}

export function* unlinkProjectFromPublication(action) {
  yield call(axios.post, '/', {
    query: unlinkProjectFromPublicationQuery(
      action.data.publicationId,
      action.data.projectId,
      action.data.publicationType,
    ),
  });

  switch (action.data.publicationType) {
    case 'abstractOfTalk':
      yield put(loadAbstractOfTalkRequirements({ id: action.data.publicationId }));
      break;
    case 'book':
      yield put(loadBookRequirements({ id: action.data.publicationId }));
      break;
    case 'bookChapter':
      yield put(loadBookchapterRequirements({ id: action.data.publicationId }));
      break;
    case 'conferencePaper':
      yield put(loadConferencePaperRequirements({ id: action.data.publicationId }));
      break;
    case 'journal':
      yield put(loadJournalRequirements({ id: action.data.publicationId }));
      break;
    case 'patent':
      yield put(loadPatentRequirements({ id: action.data.publicationId }));
      break;
    case 'report':
      yield put(loadReportRequirements({ id: action.data.publicationId }));
      break;
    case 'proceeding':
      yield put(loadProceedingRequirements({ id: action.data.publicationId }));
      break;
    case 'talk':
      yield put(loadTalkRequirements({ id: action.data.publicationId }));
      break;
    case 'these':
      yield put(loadTheseRequirements({ id: action.data.publicationId }));
      break;
    default:
      // do nothing
  }

  yield put(unlinkProjectFromPublicationSuccess());
}

// NEW SHIZZLE
export function* ensureTeams() {
  const response = yield call(axios.post, '/', { query: QueryTeams });
  yield put(FETCH_PUBLICATION_TEAMS_SUCCESS(response.data.data.teams));
}
export function* ensurePeople() {
  const response = yield call(axios.post, '/', { query: QueryPublicationPeople });
  yield put(FETCH_PUBLICATION_PEOPLE_SUCCESS(response.data.data.allPeoplePeople));
}
export function* ensureProceedings() {
  const response = yield call(axios.post, '/', { query: QueryProceedings });
  yield put(FETCH_PUBLICATION_PROCEEDINGS_SUCCESS(response.data.data));
}
export function* deletePublication({ payload: { id, type } }) {
  yield call(axios.post, '/', { query: DeletePublication(id, type) });
  yield put(deletePublicationSuccess());
  yield put(push('/admin/publications'));
}
// NEW SHIZZLE

export default function* () {
  yield takeLatest(
    LINK_PROJECT_WITH_PUBLICATION,
    catchErrors({
      onErrorAction: linkProjectWithPublicationError,
      overrideErrorMessage: 'Unable to save this update',
    })(linkProjectWithPublication),
  );

  yield takeLatest(
    UNLINK_PROJECT_FROM_PUBLICATION,
    catchErrors({
      onErrorAction: unlinkProjectFromPublicationError,
      overrideErrorMessage: 'Unable to save this update',
    })(unlinkProjectFromPublication),
  );

  yield takeLatest(
    DELETE_PUBLICATION,
    catchErrors({
      onErrorAction: deletePublicationError,
      overrideErrorMessage: 'Unable to delete this publication',
    })(deletePublication),
  );
}
