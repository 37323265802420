import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_TALK,
  createTalkError,
  createTalkSuccess,
  FETCH_TALK,
  fetchTalkError,
  fetchTalkSuccess,
  fetchTalk as fetchTalkAction,
  LOAD_TALK_REQUIREMENTS,
  loadTalkRequirementsError,
  loadTalkRequirementsSuccess,
  UPDATE_TALK,
  updateTalkError,
  updateTalkSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createTalk as createTalkMutation,
  QuerytalkById as fetchTalkQuery,
  updateTalk as updateTalkMutation,
} from '../../../../graphql/queries/publications';

export function* createTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: createTalkMutation(payload) });
  yield put(createTalkSuccess());

  const { id } = response.data.data.createTalk;
  yield put(push(`/researcher/publications/updateTalk/${id}`));
  showSuccessMessage('Talk created successfully');
}

export function* fetchTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchTalkQuery(payload.id) });
  yield put(fetchTalkSuccess(response.data.data.talkById));
}

export function* updateTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: updateTalkMutation(payload) });
  yield put(updateTalkSuccess(response.data.data.updateTalk));
  showSuccessMessage('Talk updated successfully');
}

export function* loadTalk({ payload }) {
  if (payload.id) {
    yield put(fetchTalkAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadTalkRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);
}

export default function* () {
  yield takeLatest(
    CREATE_TALK,
    catchErrors({
      onErrorAction: createTalkError,
      overrideErrorMessage: 'Could not create publication of type talk',
    })(createTalk),
  );

  yield takeLatest(
    FETCH_TALK,
    catchErrors({
      onErrorAction: fetchTalkError,
      overrideErrorMessage: 'Could not fetch talk',
    })(fetchTalk),
  );

  yield takeLatest(
    LOAD_TALK_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadTalkRequirementsError,
    })(loadTalk),
  );

  yield takeLatest(
    UPDATE_TALK,
    catchErrors({
      onErrorAction: updateTalkError,
      overrideErrorMessage: 'Could not update publication of type talk',
    })(updateTalk),
  );
}
