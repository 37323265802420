import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LOGOUT } from '../state/app/appActions';
import { userWithPermission } from '../utils';

/* NOTE: If stateless, consider a functional commponent */
/* eslint-disable react/prefer-stateless-function */
export default function (ComposedComponent, neededPermission) {
  class Authenticate extends React.Component {
    render() {
      // const { isAuthenticated, pathName } = this.props;
      const { isAuthenticated, permissions } = this.props;
      let hasPermission = false;
      if (isAuthenticated) {
        if (neededPermission) {
          hasPermission = userWithPermission(permissions, neededPermission);
        } else {
          hasPermission = true;
        }
      }

      return (
        <div>
          {hasPermission
            ? <ComposedComponent {...this.props} />
            : (
              <Row>
                <Col>
                  <div className="error-template text-center">
                    <h3>Unauthorized!</h3>

                    <div className="error-details">
                      You don't have permission to see this page!
                    </div>
                    {!isAuthenticated && (<a className="nav-link" href="/backend/authenticate">Login</a>)}
                  </div>
                </Col>
              </Row>
            )}
        </div>
      );
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  const mapStateToProps = (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    permissions: state.user.user.permissions,
    pathName: state.router.location.pathname,
    user: state.user.user,
  });

  const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout: () => dispatch(LOGOUT()),
  }, dispatch);

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Authenticate);
}
