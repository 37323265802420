import { put, call, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  MutateDeletePartner,
  QueryAllPartners,
} from '../../graphql/queries/partners';
import catchErrors from '../../graphql/sagas';
import {
  DELETE_PARTNER,
  deletePartnerError,
  deletePartnerSuccess,
  FETCH_ALL_PARTNERS,
  fetchAllPartners,
  fetchAllPartnersError,
  fetchAllPartnersSuccess,
} from './partnersActions';

export function* getAllPartners() {
  const response = yield call(axios.post, '/', { query: QueryAllPartners });
  yield put(fetchAllPartnersSuccess(response.data.data.partners));
}

export function* deletePartner(action) {
  const response = yield call(axios.post, '/', { query: MutateDeletePartner(action.partnerId) });
  if (response.data.data.deletePartner === false) {
    throw new Error('Partner could not be deleted');
  }

  yield put(deletePartnerSuccess(response.data));
  yield put(fetchAllPartners());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Partner deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export default function* () {
  yield takeLatest(
    DELETE_PARTNER,
    catchErrors({
      onErrorAction: deletePartnerError,
      overrideErrorMessage: 'Unable to delete partner',
    })(deletePartner),
  );

  yield takeLatest(
    FETCH_ALL_PARTNERS,
    catchErrors({
      onErrorAction: fetchAllPartnersError,
      overrideErrorMessage: 'Unable to retrieve partners',
    })(getAllPartners),
  );
}
