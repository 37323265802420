import React, {useEffect, useState} from 'react';
import {merge} from 'lodash/fp';
import {connect} from 'react-redux';
import {ErrorMessage, Formik} from 'formik';
import {createStructuredSelector} from 'reselect';
import {useParams} from 'react-router-dom';
import Select from 'react-select';
import {Button, Card, Col, Container, Form, Row,} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import {conferencePaperValidationSchema as validationSchema} from '../ValidationSchema';
import {Loader} from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';

import {
  createConferencePaper as createConferencePaperAction,
  loadConferencePaperRequirements as loadConferencePaperRequirementsAction,
  updateConferencePaper as updateConferencePaperAction,
} from './actions';
import {selectIsLoading, selectProjects, selectPublicationConferencePaperDetails,} from './selectors';
import {selectConferences, selectProceedings} from '../../../../state/publication/selectors';
import {calculatePageCount} from '../../../../utils';
import {yesNoOptions} from '../../../../config/optionValues';
import ProceedingsSelectorRow from '../../../../component/admin/publication/ProceedingsSelectorRow';

const ConferencePaper = ({
  proceedings,
  conferences,
  createConferencePaper,
  isLoading,
  isResearcher,
  loadConferencePaperRequirements,
  projects,
  conferencePaperInfo,
  updateConferencePaper,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadConferencePaperRequirements({ id, reset: true });
  }, [id, loadConferencePaperRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadConferencePaperRequirements({ reset: false });
    }
  }, [reloads, loadConferencePaperRequirements]);

  const initialFormValues = merge({
    types: 'conferencePaper',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    proceedings: [],
    date: '',
    acceptedDate: '',
    doi: '',
    series: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    earlyAccess: false,
    from: '',
    to: '',
    conference: null,
    publisher: '',
    state: '',
    city: '',
    volume: '',
    country: null,
    complete: false,
    presentedAtRump: false,
    proceedingsId: null,
    reviewed: null,
    pages: '',
    pageCount: 0,
    editors: [],
  }, conferencePaperInfo);

  const handleConferencePaperSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      editors: values.editors.map((editor) => editor.value),
    };

    if (id) {
      updateConferencePaper({ ...updatedValues, id });
    } else {
      createConferencePaper(updatedValues);
    }
    setSubmitting(false);
  };

  const handlePageChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('pages', value);
    setFieldValue('pageCount', calculatePageCount(value));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleConferencePaperSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="conferencePaper"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Conference Paper specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Check
                            name="earlyAccess"
                            label="Early access: no publishing date, pages numbers, volume or issue yet"
                            onChange={(e) => setFieldValue('earlyAccess', e.target.checked)}
                            checked={values.earlyAccess}
                            value={values.earlyAccess}
                            className={touched.earlyAccess && errors.earlyAccess ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="earlyAccess" />
                          </div>
                        </Col>
                      </Form.Row>
                      <ProceedingsSelectorRow proceedings={proceedings} conferences={conferences} field="proceedingsId" />
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Page(s)</Form.Label>
                          <Form.Control
                            name="pages"
                            onChange={(event) => handlePageChange(event, setFieldValue)}
                            value={values.pages}
                            className={touched.pages && errors.pages ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="pages" />
                          </div>
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Page Count</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Fill pages..."
                            value={values.pageCount}
                            className={values.pageCount && !parseInt(values.pageCount, 10) ? 'error' : null}
                          />
                          <ErrorMessage name="pageCount" />
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Paper is peer-reviewed</Form.Label>
                          <Select
                            name="reviewed"
                            onChange={(event) => {
                              setFieldValue('reviewed', event.value);
                            }}
                            options={yesNoOptions}
                            className={touched.reviewed && errors.reviewed ? 'error' : null}
                            value={
                              yesNoOptions.find((o) => (o.value === 'true') === values.reviewed)
}
                          />
                          <div className="error-message">
                            <ErrorMessage name="reviewed" />
                          </div>
                        </Col>
                        <Col sm={2}>
                          <Form.Check
                            name="presentedAtRump"
                            label="Presented at rump"
                            onChange={(e) => setFieldValue('presentedAtRump', e.target.checked)}
                            checked={values.presentedAtRump}
                            value={values.presentedAtRump}
                          />
                          <div className="error-message">
                            <ErrorMessage name="presentedAtRump" />
                          </div>
                        </Col>
                        <Col sm={2}>
                          <Form.Check
                            name="complete"
                            label="This paper is a complete article"
                            onChange={(e) => setFieldValue('complete', e.target.checked)}
                            checked={values.complete}
                            value={values.complete}
                            className={touched.complete && errors.complete ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="complete" />
                          </div>
                        </Col>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="conferencePaper"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={conferencePaperInfo.visibility}
        />
      )}
    </Container>
  );
};

ConferencePaper.propTypes = {
  conferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  proceedings: PropTypes.arrayOf(PropTypes.object).isRequired,
  createConferencePaper: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadConferencePaperRequirements: PropTypes.func.isRequired,
  conferencePaperInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateConferencePaper: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  proceedings: selectProceedings,
  conferences: selectConferences,
  isLoading: selectIsLoading,
  conferencePaperInfo: selectPublicationConferencePaperDetails,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createConferencePaper: createConferencePaperAction,
  loadConferencePaperRequirements: loadConferencePaperRequirementsAction,
  updateConferencePaper: updateConferencePaperAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferencePaper);
