import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Loader } from '../../../component/index';
import {
  deleteCountry as deleteCountryAction,
  fetchAllCountries as fetchAllCountriesAction,
  setCountryFilterName as setCountryFilterNameAction,
  setCountryFilterCode as setCountryFilterCodeAction,
} from '../../../state/countries/countriesActions';
import { filteredCountries } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  selectFilter,
  selectAllCountries,
  selectIsLoading,
} from '../../../state/countries/selectors';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Countries = ({
  loading,
  filter,
  countries,
  fetchAllCountries,
  setCountryFilterName,
  setCountryFilterCode,
  deleteCountry,
}) => {
  const history = useHistory();
  const [deleteCountryInfo, setDeleteCountryInfo] = useState(null);

  useEffect(() => {
    fetchAllCountries();
  }, [fetchAllCountries]);

  const data = filteredCountries(countries, filter);

  const onConfirmDelete = () => {
    deleteCountry(deleteCountryInfo.id);
    setDeleteCountryInfo(null);
  };

  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Code', accessor: 'code' },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/admin/settings/countries/${row.original.id}`)}
          >
Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
            onClick={() => setDeleteCountryInfo(row.original)}
          >
Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Country name</Form.Label>
                      <Form.Control
                        placeholder="Match country names containing this text... (2 letters minimum)"
                        value={filter.name}
                        onChange={(val) => setCountryFilterName(val.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Country code</Form.Label>
                      <Form.Control
                        placeholder="match country codes containing this text..."
                        value={filter.code}
                        onChange={(val) => setCountryFilterCode(val.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Countries
              <Button
                onClick={() => {
                  history.push('/admin/settings/countries/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loader />
              ) : (
                <ReactTable
                  columns={columns}
                  data={data}
                  defaultSorted={[
                    {
                      id: 'name',
                      desc: false,
                    },
                  ]}
                  pageSizeOptions={defaultTablePageSizeOptions}
                  defaultPageSize={defaultTablePageSize}
                  minRows={0}
                  className="-striped -highlight"
                  getTrProps={() => ({
                    style: {
                      cursor: 'pointer',
                    },
                  })}
                />
              )}
              <DeleteConfirmation
                text={deleteCountryInfo !== null ? `Delete country ${deleteCountryInfo.name}` : ''}
                show={deleteCountryInfo !== null}
                onCancel={() => setDeleteCountryInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Countries.propTypes = {
  loading: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAllCountries: PropTypes.func.isRequired,
  setCountryFilterCode: PropTypes.func.isRequired,
  setCountryFilterName: PropTypes.func.isRequired,
  deleteCountry: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading,
  countries: selectAllCountries,
  filter: selectFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCountries: () => dispatch(fetchAllCountriesAction()),
  setCountryFilterName: (value) => dispatch(setCountryFilterNameAction(value)),
  setCountryFilterCode: (value) => dispatch(setCountryFilterCodeAction(value)),
  deleteCountry: (countryId) => dispatch(deleteCountryAction(countryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
