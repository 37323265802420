import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';


function NotFound({ location }) {
  const { pathname } = location;
  return (
    <Container fluid>
      <Row>
        <Col>
          <div>
            <h3>
                Page not found 404
              <code>{pathname}</code>
            </h3>
          </div>

        </Col>
      </Row>
    </Container>
  );
}

NotFound.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NotFound;
