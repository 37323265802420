import { put, call, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryAllVenues,
  MutateDeleteJournal,
  MutateDeleteConference,
} from '../../graphql/queries/venues';
import catchErrors from '../../graphql/sagas';
import {
  DELETE_VENUE,
  deleteVenueError,
  deleteVenueSuccess,
  FETCH_ALL_VENUES,
  fetchAllVenues,
  fetchAllVenuesError,
  fetchAllVenuesSuccess,
} from './venuesActions';

export function* getAllVenues() {
  const response = yield call(axios.post, '/', { query: QueryAllVenues });
  yield put(fetchAllVenuesSuccess(response.data.data));
}

export function* deleteVenue(action) {
  const query = (action.data.type === 'journal')
    ? MutateDeleteJournal(action.data.id)
    : MutateDeleteConference(action.data.id);

  const response = yield call(axios.post, '/', { query });
  if (action.data.type === 'journal' && response.data.data.deleteJournal === false) {
    throw new Error('Journal could not be deleted');
  }
  if (action.data.type === 'conference' && response.data.data.deleteJournal === false) {
    throw new Error('Conference could not be deleted');
  }

  yield put(fetchAllVenues());
  yield put(deleteVenueSuccess(response.data));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Venue successfully deleted!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export default function* () {
  yield takeLatest(
    DELETE_VENUE,
    catchErrors({
      onErrorAction: deleteVenueError,
      overrideErrorMessage: 'Unable to delete venue',
    })(deleteVenue),
  );

  yield takeLatest(
    FETCH_ALL_VENUES,
    catchErrors({
      onErrorAction: fetchAllVenuesError,
      overrideErrorMessage: 'Unable to retrieve venues',
    })(getAllVenues),
  );
}
