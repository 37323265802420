
export const QueryAllTypes = `
{
  types {
    id
    name
    parent {
      id
    }
    childs {
      id
    }
  }
}`;

export const QueryOneType = (id) => `
{ 
  type (id:"${id}") {
    id
    name
    parent {
      id
    }
  }
}`;

export const MutateCreateType = (type) => `
mutation {
  createType (typeinput: { name:"${type.name}"}, parentId:${type.parent ? type.parent : null}, ) {
    id
    name
    parent {
      id
    }
  }
}`;

export const MutateUpdateType = (type) => `
mutation {
    updateType (id:"${type.id}", typeinput: { name:"${type.name}" }, parentId:${type.parent ? type.parent : null}) {
      id
      name
      parent {
        id
        name
      }
    }
}`;

export const MutateDeleteType = (typeId) => `
mutation {
  deleteType(id:${typeId})
}`;
