import moment from 'moment';
import {
  REMOVE_PROJECT,
  REMOVE_PROJECT_ERROR,
  REMOVE_PROJECT_SUCCESS,
} from './projectActions';
import { uniqueArray } from '../../utils';

const defaultState = {
  isLoading: false,
  initialValues: {
    status: '',
    abstract: '',
    name: '',
    number: '',
    funder: null,
    call: null,
    type: null,
    team: null,
    published: false,
    acronym: '',
    action: '',
    sap: '',
    beginDate: '',
    endDate: '',
    cosicContact: null,
    cosicContactRole: null,
    website: null,
    cosicBudget: '',
    webVersionRepository: '',
  },
  data: [],
  error: '',
  detail: {
    selectedId: null,
    data: {},
  },
  filters: {
    nameOrAcronym: null,
    status: null,
    beginDate: null,
    endDate: null,
    type: null,
  },
  workPackets: {
    data: [],
    isLoading: false,
  },
  types: [],
  calls: [],
  files: [],
  teams: [],
  numberOfFilesUploading: { public: 0, private: 0 },
};

const project = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_PROJECTS':
      return { ...state, data: action.data, isLoading: true };
    case 'FETCH_ALL_PROJECTS_SUCCESS':
      return { ...state, data: action.data, isLoading: false };
    case 'FETCH_ALL_PROJECTS_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_PROJECT_TYPES_SUCCESS':
      return { ...state, types: action.data };
    case 'FETCH_PROJECT_TYPES_ERROR':
      return { ...state, types: action.error };
    case 'FETCH_PROJECT_CALLS_SUCCESS':
      return {
        ...state,
        calls: action.data.calls,
        teams: action.data.teams,
        isLoading: false,
      };
    case 'FETCH_PROJECT_CALLS_ERROR':
      return {
        ...state,
        calls: action.error,
        teams: action.error,
        isLoading: false,
      };
    case 'FETCH_PROJECT_WORKPACKETS_SUCCESS':
      return {
        ...state,
        workPackets: {
          ...state.workPackets,
          data: action.data,
          isLoading: false,
        },
      };
    case 'FETCH_PROJECT_WORKPACKETS_ERROR':
      return {
        ...state,
        workPackets: {
          ...state.workPackets,
          data: action.error,
          isLoading: false,
        },
      };

    case 'FETCH_PROJECT':
      return {
        ...state,
        isLoading: true,
        detail: {
          ...state.detail,
          selectedId: action.selectedId,
        },
      };
    case 'FETCH_PROJECT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        detail: {
          ...state.detail,
          data: action.data,
        },
        initialValues: {
          ...action.data,
          cosicContact: action.data.cosicContact ? action.data.cosicContact.id : null,
          cosicContactRole: action.data.cosicContactRole,
          website: action.data.website,
          funder: action.data.funder ? action.data.funder.id : null,
          call: action.data.call ? action.data.call.id : null,
          type: action.data.type ? action.data.type.id : null,
          team: action.data.team ? (
            action.data.team.map((t) => ({
              label: t.name,
              value: t.id,
            }))) : '',
          abstract: action.data.abs,
          beginDate: action.data.beginDate
            ? moment(action.data.beginDate).format('YYYY-MM-DD')
            : '',
          endDate: action.data.endDate
            ? moment(action.data.endDate).format('YYYY-MM-DD')
            : '',
          abs: undefined,
          sap: action.data.sap || '',
          cosicBudget: action.data.cosicBudget || '',
          name: action.data.name || '',
          number: action.data.number || '',
          published: action.data.published || false,
          action: action.data.action || '',
          status: action.data.status || '',
          acronym: action.data.acronym || '',
          webVersionRepository: action.data.webVersionRepository || '',
        },
      };
    case 'FETCH_PROJECT_ERROR':
      return { ...state, data: action.error, isLoading: false };
    case 'SET_PROJECT_FILTERS':
      return { ...state, filters: action.data, isLoading: true };
    case 'FETCH_PROJECT_CALLS':
    case 'CREATE_ONE_PROJECT':
    case 'UPDATE_ONE_PROJECT':
    case 'FETCH_PROJECT_TYPES':
      return { ...state };
    case 'FETCH_PROJECT_WORKPACKETS':
    case 'UPDATE_PROJECT_WORKPACKET':
    case 'ADD_WORKPACKETS_TO_PROJECT':
      return {
        ...state,
        workPackets: {
          ...state.workPackets,
          isLoading: true,
        },
      };
    case 'DELETE_PROJECT_WORKPACKET':
      return {
        ...state,
        workPackets: {
          ...state.workPackets,
          data: state.workPackets.data.filter((d) => d.id !== action.id),
          isLoading: false,
        },
      };
    case 'CLEAR_PROJECT_FILTERS':
      return {
        ...state,
        filters: {
          nameOrAcronym: null,
          status: null,
          beginDate: null,
          endDate: null,
          type: null,
        },
        isLoading: true,
      };
    case 'CLEAR_PROJECT_FORM':
      return {
        ...state,
        initialValues: {
          status: '',
          abstract: '',
          name: '',
          number: '',
          funder: null,
          call: null,
          type: null,
          published: false,
          acronym: '',
          action: '',
          sap: '',
          team: null,
          beginDate: '',
          endDate: '',
          cosicContact: null,
          cosicContactRole: null,
          website: null,
          cosicBudget: '',
        },
      };
    case 'FETCH_PROJECT_FILES':
      return { ...state, isLoading: true };
    case 'FETCH_PROJECT_FILES_SUCCESS':
      return { ...state, files: action.data, isLoading: false };
    case 'FETCH_PROJECT_FILES_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'POST_PROJECT_FILE':
      return { ...state, numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.data.get('attribute')]: state.numberOfFilesUploading[action.data.get('attribute')] + 1 } };
    case 'POST_PROJECT_FILE_SUCCESS':
      return { ...state, files: uniqueArray([...state.files, action.data]), numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: state.numberOfFilesUploading[action.attribute] - 1 } };
    case 'POST_PROJECT_FILE_ERROR':
      return { ...state, error: action.error, numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: state.numberOfFilesUploading[action.attribute] - 1 } };
    case 'DELETE_PROJECT_FILE':
      return { ...state, isLoading: true };
    case 'DELETE_PROJECT_FILE_SUCCESS':
      return {
        ...state,
        files: state.files.filter((file) => file.fileName !== action.data.fileName
          || file.visibility !== action.data.attribute),
        isLoading: false,
      };
    case 'DELETE_PROJECT_FILE_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'REMOVE_PROJECT_PARTNER':
      return { ...state, isLoading: true };
    case 'REMOVE_PROJECT_PARTNER_SUCCESS':
      return {
        ...state,
        detail: {
          selectedId: action.data.data.data.removePartnerFromProject.id,
          data: action.data.data.data.removePartnerFromProject,
        },
        isLoading: false,
      };
    case 'REMOVE_PROJECT_PARTNER_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'ADD_PROJECT_PARTNER':
      return { ...state, isLoading: true };
    case 'ADD_PROJECT_PARTNER_SUCCESS':
      return {
        ...state,
        detail: {
          selectedId: action.data.data.data.addPartnerToProject.id,
          data: action.data.data.data.addPartnerToProject,
        },
        isLoading: false,
      };
    case 'ADD_PROJECT_PARTNER_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'REMOVE_PROJECT_MEMBER':
      return { ...state, isLoading: true };
    case 'REMOVE_PROJECT_MEMBER_SUCCESS':
      return {
        ...state,
        detail: {
          selectedId: action.data.data.data.removeTeamMemberFromProject.id,
          data: {
            ...state.detail.data,
            teamMembers: action.data.data.data.removeTeamMemberFromProject,
          },
        },
        isLoading: false,
      };
    case 'REMOVE_PROJECT_MEMBER_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'ADD_PROJECT_MEMBER':
      return { ...state, isLoading: true };
    case 'ADD_PROJECT_MEMBER_SUCCESS':
      return {
        ...state,
        detail: {
          selectedId: action.data.data.data.addTeamMemberToProject.id,
          data: {
            ...state.detail.data,
            teamMembers: action.data.data.data.addTeamMemberToProject,
          },
        },
        isLoading: false,
      };
    case 'ADD_PROJECT_MEMBER_ERROR':
      return { ...state, error: action.error, isLoading: false };

    case REMOVE_PROJECT:
      return { ...state, isLoading: true };
    case REMOVE_PROJECT_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case REMOVE_PROJECT_SUCCESS:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default project;
