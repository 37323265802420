import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import Datetime from 'react-datetime';
import * as Yup from 'yup';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  Modal,
  Table,
  Row,
} from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { Loader } from '../../index';
import {
  addHistoryStatus as addHistoryStatusAction,
  updateHistoryStatus as updateHistoryStatusAction,
  removeHistoryStatus as removeHistoryStatusAction,
} from '../../../state/person/personActions';
import {
  selectIsLoading,
  selectPersonId,
  selectPersonStatusHistory,
} from '../../../state/person/selectors';
import { selectPeopleTypesList } from '../../../state/peopleType/selectors';

const EditPeopleHistoryForm = (props) => {
  const {
    loading,
    statuses,
    personId,
    addHistoryStatus,
    updateHistoryStatus,
    removeHistoryStatus,
    peopleTypes,
    canEdit,
  } = props;

  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleShowMutate = (id, showPopup) => {
    setEditId(id); setShow(showPopup);
  };

  const handleShowDelete = (id) => {
    setDeleteId(id);
    setShowDelete(true);
  };

  const handleDelete = () => {
    removeHistoryStatus(personId, deleteId);
    setShowDelete(false);
    setDeleteId(null);
  };

  const peopleTypesOptions = peopleTypes ? peopleTypes.map((peopleType) => ({
    label: `${peopleType.name}`,
    value: peopleType.id,
  })) : [];

  const historyStatusSchema = Yup.object().shape({
    peopleTypeId: Yup.number()
      .required('^ mandatory field'),
    startDate: Yup.string()
      .required('^ mandatory field'),
    endDate: Yup.string(),
  });

  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  const deleteFormModal = (history) => (
    <Modal
      show={showDelete}
      onHide={() => setShowDelete(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete status history entry?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteId && (
          <p>
            Delete entry '
            {deleteId}
            {' - '}
            {history.find((entry) => entry.id === deleteId).peopleType.name}
            '
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowDelete(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const editFormModal = () => (
    <Modal
      show={show}
      onHide={() => handleShowMutate(null, false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{editId == null ? 'New' : 'Edit'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={historyStatusSchema}
          initialValues={{
            personId,
            id: editId,
            peopleTypeId: editId ? statuses.find((s) => s.id === editId).peopleType.id : '',
            startDate: editId ? moment(statuses.find((s) => s.id === editId).startDate).format('YYYY-MM-DD') : '',
            endDate: editId ? statuses.filter((s) => s.id === editId).map((e) => (e.endDate ? moment(e.endDate).format('YYYY-MM-DD') : ''))[0] : '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (editId === null) {
              addHistoryStatus(values);
            } else {
              updateHistoryStatus(values);
            }
            setSubmitting(false);
            handleShowMutate(null, false);
          }}
        >
          {({
            setFieldValue,
            isSubmitting,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Status</Form.Label>
                  <Select
                    options={peopleTypesOptions}
                    onChange={(val) => setFieldValue('peopleTypeId', val.value)}
                    value={peopleTypesOptions.find(
                      (c) => c.value === values.peopleTypeId,
                    )}
                  />
                  {displayError(errors.peopleTypeId, touched.peopleTypeId)}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Start date</Form.Label>
                  <Datetime
                    name="startDate"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    value={values.startDate}
                    onChange={(val) => setFieldValue('startDate', val ? moment(val).format('YYYY-MM-DD') : '')}
                  />
                  {displayError(errors.startDate, touched.startDate)}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>End date</Form.Label>
                  <Datetime
                    name="endDate"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    value={values.endDate}
                    onChange={(val) => setFieldValue('endDate', val ? moment(val).format('YYYY-MM-DD') : '')}
                  />
                  {displayError(errors.endDate, touched.endDate)}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%', marginTop: '1em' }}>
                    Save
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleShowMutate(null, false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Status</th>
                  <th>Start date</th>
                  <th>End Date</th>
                  {canEdit && <th /> }
                </tr>
              </thead>
              <tbody>
                {statuses.sort((a, b) => moment(a.startDate) - moment(b.startDate)).map((status) => (
                  <tr key={`status_${status.id}`}>
                    <td>{status.id}</td>
                    <td>{status.peopleType.name}</td>
                    <td>{moment(status.startDate).format('ddd Do of MMMM YYYY')}</td>
                    <td>{status.endDate ? moment(status.endDate).format('ddd Do of MMMM YYYY') : ''}</td>
                    {canEdit && (
                    <td>
                      <Button className="btn btn-primary fixed-size-button" onClick={() => handleShowMutate(status.id, true)}>
                        Edit
                      </Button>
                      <Button className="btn btn-danger fixed-size-button" onClick={() => handleShowDelete(status.id)}>
                        Delete
                      </Button>
                    </td>
                    ) }
                  </tr>
                ))}
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  {canEdit && (
                  <td>
                    <Button className="btn btn-primary fixed-size-button" onClick={() => handleShowMutate(null, true)}>
                      New
                    </Button>
                  </td>
                  ) }
                </tr>
              </tbody>
            </Table>
            {deleteFormModal(statuses)}
            {editFormModal()}
          </div>
        )}
    </div>
  );
};

EditPeopleHistoryForm.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.any),
  addHistoryStatus: PropTypes.func.isRequired,
  updateHistoryStatus: PropTypes.func.isRequired,
  removeHistoryStatus: PropTypes.func.isRequired,
  personId: PropTypes.string.isRequired,
  peopleTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

EditPeopleHistoryForm.defaultProps = {
  statuses: [],
};

const mapStateToProps = createStructuredSelector({
  personId: selectPersonId,
  loading: selectIsLoading,
  statuses: selectPersonStatusHistory,
  peopleTypes: selectPeopleTypesList,
});

const mapDispatchToProps = (dispatch) => ({
  addHistoryStatus: (data) => dispatch(addHistoryStatusAction(data)),
  updateHistoryStatus: (data) => dispatch(updateHistoryStatusAction(data)),
  removeHistoryStatus: (peopleId, statusId) => dispatch(
    removeHistoryStatusAction(peopleId, statusId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleHistoryForm);
