import React from 'react';
import Container from 'react-bootstrap/Container';
import connect from 'react-redux/es/connect/connect';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
} from 'react-bootstrap';

import { Formik } from 'formik';

const CreateProject = () => (
  <Container fluid>
    <Row>
      <Col>
        <Card className="filter-card">
          <Card.Header>Create Project</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  // eslint-disable-next-line no-alert
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <Button type="submit" className="btn btn-success ml-auto" disabled={isSubmitting}>
                        Save
                    </Button>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label>Project Name</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className="form-control"
                      />
                      {errors.email && touched.email && errors.email}
                    </Col>
                    <Col>
                      <Form.Label>Project Code (assigned by funder)</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                    <Col>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                    <Col>
                      <Form.Label>Acronym</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                    <Col>
                      <Form.Label>Funder</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col>
                      <Form.Label>Project call</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                    <Col>
                      <Form.Label>COSIC Contact</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                    <Col>

                      <Form.Label>COSIC Budget</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col>
                      <Form.Label>Abstract</Form.Label>
                      <Form.Control
                        type="text"
                        name="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="form-control"
                      />
                      {errors.password && touched.password && errors.password}
                    </Col>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

CreateProject.propTypes = {
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
