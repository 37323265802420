
export const QueryAllFunders = `
{
  funders {
    id
    name
    childs {
      id
    }
    parent {
      id
      name
    }
    european
    belgian
  }
}`;

export const QueryOneFunder = (id) => `
query {
  funder (id:"${id}"){
    id
    name
    parent {
      id
      name
    }
    european
    belgian
  }
}`;

export const MutateCreateFunder = (funder) => `mutation {
    createFunder (funder:{name:"${funder.name}", european: ${funder.european}, belgian: ${funder.belgian}}, parentFunderId:${(!funder.parent || funder.parent === 'None') ? null : funder.parent}) {
      id
      name
      parent {
        id
        name
      }
      european
      belgian
    }
  }`;

export const MutateUpdateFunder = (funder) => `
mutation {
    updateFunder (id:"${funder.id}", funder: { name:"${funder.name}", european: ${funder.european}, belgian: ${funder.belgian} }, parentId:${(!funder.parent || funder.parent === 'None') ? null : funder.parent}) {
      id
      name
      parent {
        id
        name
      }
      european
      belgian
    }
}`;

export const MutateDeleteFunder = (funderId) => `
mutation {
  deleteFunder(id:"${funderId}")
}`;
