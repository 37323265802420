import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Loader } from '../../../component/index';
import {
  deleteFunder as deleteFunderAction,
  fetchAllFunders as fetchAllFundersAction,
  setFunderFilterName as setFunderFilterNameAction,
} from '../../../state/funders/fundersActions';
import { filteredFunders } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  selectFilter,
  selectAllFunders,
  selectIsLoading,
} from '../../../state/funders/selectors';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Funders = (props) => {
  const {
    funders,
    filter,
    loading,
    fetchAllFunders,
    setFunderFilterName,
    deleteFunder,
  } = props;
  const history = useHistory();

  useEffect(() => { fetchAllFunders(); }, [fetchAllFunders]);
  const data = filteredFunders(funders, filter);

  const [deleteFunderInfo, setDeleteFunderInfo] = useState(null);
  const onConfirmDelete = () => {
    deleteFunder(deleteFunderInfo.id);
    setDeleteFunderInfo(null);
  };

  const columns = [
    { Header: 'Name', accessor: 'name' },
    {
      Header: 'Parent',
      accessor: 'parent',
      Cell: (cellInfo) => (cellInfo.original.parent ? cellInfo.original.parent.name : ' - '),
    },
    {
      Header: 'Belgian',
      accessor: 'belgian',
      Cell: (cellInfo) => (cellInfo.original.belgian ? 'yes' : 'no'),
    },
    {
      Header: 'European',
      accessor: 'european',
      Cell: (cellInfo) => (cellInfo.original.european ? 'yes' : 'no'),
    },
    {
      Header: 'Children',
      accessor: 'children',
      Cell: (cellInfo) => (cellInfo.original.childs.length || 0),
    },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            variant="primary"
            size="sm"
            block
            onClick={() => history.push(`/admin/settings/funders/${row.original.id}`)}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => setDeleteFunderInfo(row.original)}
            block
          >
            Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Funder name</Form.Label>
                      <Form.Control placeholder="Funder name" value={filter} onChange={(val) => setFunderFilterName(val.target.value)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Funders
              <Button
                onClick={() => {
                  history.push('/admin/settings/funders/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loader />
              )
                : (
                  <ReactTable
                    columns={columns}
                    data={data}
                    defaultSorted={[
                      {
                        id: 'name',
                        desc: false,
                      },
                      {
                        id: 'children',
                        desc: false,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              <DeleteConfirmation
                text={deleteFunderInfo !== null ? `Delete funder ${deleteFunderInfo.name}` : ''}
                show={deleteFunderInfo !== null}
                onCancel={() => setDeleteFunderInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Funders.propTypes = {
  loading: PropTypes.bool.isRequired,
  funders: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.string.isRequired,
  fetchAllFunders: PropTypes.func.isRequired,
  setFunderFilterName: PropTypes.func.isRequired,
  deleteFunder: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading,
  funders: selectAllFunders,
  filter: selectFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllFunders: () => dispatch(fetchAllFundersAction()),
  setFunderFilterName: (value) => dispatch(setFunderFilterNameAction(value)),
  deleteFunder: (funderId) => dispatch(deleteFunderAction(funderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Funders);
