import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosClientNoGraphl } from '../../axios';
import config from '../../config/config';

function FileLoading({ backendPathGenerator, isPrivate }) {
  const { pathpart } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  useEffect(() => {
    async function fetchData() {
        const url = backendPathGenerator(pathpart);
        if(isPrivate){
            try {
            const result = await axiosClientNoGraphl.get(url, { responseType: 'blob' });
            const blob = result.data;
            const href = window.URL.createObjectURL(blob);
            setLoading(false);
            window.open(href,"_self");
            } catch (e) {
              setError(`Error: received statuscode ${e.response.status}`);
              setLoading(false);
            }
        } else {
            window.open(`${config.API_URL}/${url}`,"_self");
            setLoading(false);
        }        
    }
    fetchData()
  }, [backendPathGenerator, pathpart, isPrivate]);

  const result = () => error.length > 0 ? error : 'Loaded successfully';

  return (
    <>
      {loading ? "Loading" : result() }
    </>
  );
}

FileLoading.propTypes = {
    prefix: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool.isRequired,
};

FileLoading.defaultProps = {
};

export default FileLoading;
