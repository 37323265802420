import React, { useEffect, useState } from 'react';
import { merge } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Formik, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Tabs from '../Tabs';
import { proceedingValidationSchema as validationSchema } from '../ValidationSchema';
import { Loader } from '../../../../component';
import GeneralDataForm, { datetimeWorkaround } from '../GeneralDataForm';
import {
  createProceeding as createProceedingAction,
  loadProceedingRequirements as loadProceedingRequirementsAction,
  updateProceeding as updateProceedingAction,
} from './actions';
import {
  selectIsLoading,
  selectProjects,
  selectPublicationProceedingDetails,
} from './selectors';
import {
  selectConferences,
  selectPublicationAuthors, selectSeries,
} from '../../../../state/publication/selectors';
import {
  selectAllCountriesAsOptions,
} from '../../../../state/countries/selectors';
import CreateExternalPeopleModal from '../../../../component/admin/people/createExternalPeopleModal';
import { customFilterMatchAll } from '../../../../component/publicationFormatTypes/typeHelper';

const Proceeding = ({
  authors,
  conferences,
  series,
  countries,
  createProceeding,
  isLoading,
  isResearcher,
  loadProceedingRequirements,
  proceedingInfo,
  projects,
  updateProceeding,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadProceedingRequirements({ id, reset: true });
  }, [id, loadProceedingRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadProceedingRequirements({ reset: false });
    }
  }, [reloads, loadProceedingRequirements]);

  const initialFormValues = merge({
    types: 'proceeding',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    country: null,
    city: '',
    state: '',
    edition: '',
    volume: '',
    editors: [],
    conference: null,
    publisher: '',
    series: null,
    from: '',
    to: '',
  }, proceedingInfo);

  const handleDatetimeChange = (dt, fieldName, format, setFieldValue) => {
    if (typeof (dt) === 'string') {
      setFieldValue(fieldName, dt);
    } else {
      setFieldValue(fieldName, dt.format(format));
    }
  };

  const handleProceedingSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      editors: values.editors ? values.editors.map((editor) => editor.value) : [],
    };

    if (id) {
      updateProceeding({ ...updatedValues, id });
    } else {
      createProceeding(updatedValues);
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleProceedingSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="proceeding"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Proceeding specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={3}>
                          <Form.Label>Conference</Form.Label>
                          <Form.Text muted>
                          Missing conference? Add via settings -> venues (afterwards click on 'Reload Options' to see your added venue)
                          </Form.Text>
                          <Select
                            name="conference"
                            onChange={(event) => {
                              setFieldValue('conference', event.value);
                              const conference = conferences.find((c) => c.value === event.value);
                              setFieldValue('title', conference.proceedings);
                              setFieldValue('series', conference.series);
                            }}
                            options={conferences}
                            value={conferences && values.conference ? (
                              conferences.find(
                                (conference) => conference.value === values.conference,
                              )
                            ) : null}
                            className={touched.conference && errors.conference ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="conference" />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <Form.Label>Series</Form.Label>
                          <Form.Text muted>
                          Missing series? Contact Dana
                          </Form.Text>
                          <Select
                            name="series"
                            onChange={(event) => setFieldValue('series', event ? event.value : null)}
                            options={series}
                            isClearable
                            value={series && values.series ? (
                              series.find(
                                (s) => s.value === values.series,
                              )
                            ) : null}
                            className={touched.series && errors.series ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="series" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={1}>
                          <Form.Label>Edition</Form.Label>
                          <Form.Text muted>
                          e.g. 15th edition of ...
                          </Form.Text>
                          <Form.Control
                            type="number"
                            name="edition"
                            onChange={handleChange}
                            value={values.edition}
                            className={touched.edition && errors.edition ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="edition" />
                          </div>
                        </Col>
                        <Col sm={1}>
                          <Form.Label>Volume</Form.Label>
                          <Form.Control
                            name="volume"
                            onChange={handleChange}
                            value={values.volume}
                            className={touched.volume && errors.volume ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="volume" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Editor(s)</Form.Label>
                          {' '}
                          <CreateExternalPeopleModal />
                          <Select
                            name="editors"
                            isMulti
                            onChange={(editors) => setFieldValue('editors', editors)}
                            options={authors}
                            filterOption={customFilterMatchAll}
                            value={values.editors}
                            className={touched.editors && errors.editors ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="editors" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Conference/Workshop Date (From - Until)</Form.Label>
                          <Datetime
                            name="from"
                            placeholder="From..."
                            value={values.from}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            onChange={(date) => handleDatetimeChange(
                              date,
                              'from',
                              'YYYY-MM-DD',
                              setFieldValue,
                            )}
                            inputProps={{ // https://github.com/arqex/react-datetime/issues/760#issuecomment-1210475819
                              ...datetimeWorkaround(values.from),
                            }}
                            className={touched.from && errors.from ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="from" />
                          </div>
                        </Col>
                        <div style={{ marginTop: '2.5rem' }}>
                          <strong>{' — '}</strong>
                        </div>
                        <Col style={{ marginTop: '2rem' }}>
                          <Datetime
                            name="to"
                            placeholder="Until..."
                            value={values.to}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            onChange={(date) => handleDatetimeChange(
                              date,
                              'to',
                              'YYYY-MM-DD',
                              setFieldValue,
                            )}
                            inputProps={{ // https://github.com/arqex/react-datetime/issues/760#issuecomment-1210475819
                              ...datetimeWorkaround(values.to),
                            }}
                            className={touched.to && errors.to ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="to" />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <Form.Label>Publisher</Form.Label>
                          <Form.Text muted>
                          e.g. Springer-Verlag
                          </Form.Text>
                          <Form.Control
                            name="publisher"
                            onChange={handleChange}
                            value={values.publisher}
                            className={touched.publisher && errors.publisher ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="publisher" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                            className={touched.city && errors.city ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="city" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                            className={touched.state && errors.state ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="state" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            className={touched.country && errors.country ? 'error' : null}
                            onChange={(event) => setFieldValue('country', event.value)}
                            options={countries}
                            value={countries && values.country ? (
                              countries.find((country) => country.value === values.country)
                            ) : ''}
                          />
                          <div className="error-message">
                            <ErrorMessage name="country" />
                          </div>
                        </Col>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="proceeding"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={proceedingInfo.visibility}
        />
      )}
    </Container>
  );
};

Proceeding.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  conferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  createProceeding: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadProceedingRequirements: PropTypes.func.isRequired,
  proceedingInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateProceeding: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authors: selectPublicationAuthors,
  conferences: selectConferences,
  series: selectSeries,
  countries: selectAllCountriesAsOptions,
  isLoading: selectIsLoading,
  proceedingInfo: selectPublicationProceedingDetails,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createProceeding: createProceedingAction,
  loadProceedingRequirements: loadProceedingRequirementsAction,
  updateProceeding: updateProceedingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Proceeding);
