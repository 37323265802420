
export const QueryPerson = (id) => `
  {
    peopleDetail (id:${id}) {
      id
      userid
      uNumber
      firstname
      lastname
      currentStatus
      active
      profileImage
      website
      nationality {
        id
        name
      }
      gender
      speaksDutch
      fluently
      email
      privateAddress
      biography
      highestDegree
      nameOfDegree
      degreeCountry {
        id
      }
      institution
      reasearchDescription
      reasearchImage
      preliminaryPhdTopic
      finalPhdTopic
      phdEndDate
      phdStartDate
      nineMonthPresentation
      intermediatePresentationDate
      privateDefenseDate
      publicDefenseDate
      mobile
      birthDate
      needsDesk
      phone
      room
      postal
      privateEmail
      privatePhone
      role
      partner {
        id
        name
      }
      programCommittees {
        id
        venue
        year
        location
        type
        role
        venueName
      }
      kulType {
        id
        name
      }
      zapResponsible {
        id
        gn
        sn
      }
      promotors {
        author_id
        author
      }
      phdCommitteeMembers {
        id
        commiteeMember {
          id
          gn
          sn
        }
      }
      researchData{
        id
        degree
        institution
        country {
          id
          name
        }
        promotor {
          id
          gn
          sn
        }
        zapResponsible {
          id
          gn
          sn
        }
        researchDescription
        image
        people {
          id
          gn
          sn
        }
      }
      internshipOrResearches {
        id
        description
        startDate
        endDate
        city
        country {
          id
          name
        }
        partner {
          id
          name
        }
      }
      awards {
        id
        name
        venue
        title
        result
        date
        bestPaper
      }
      events {
        id
        title
        type
        location
        city
        country
        start
        end
      }
      statuses {
        id
        startDate
        endDate
        current
        type {
          id
          name
        }
      }
      teams {
        id
        name
      }
      alumnusData {
        alumnus
        formerPostdoc
        formerColleague
        currentSituation
        careerAfter
        hiddenWebsite
        currentUrl
      }
      publications{
        author
        publications {
          patents {
            id
            title
            description
            date
            timestamp
            authors {
              first
              last
            }
          }
          abstractOfTalks {
            id
            title
            pages
            city
            state
            country
            volume
            edition
            from
            proceedings
            publisher
            authors {
              first
              last
            }
          }
          talks {
            id
            title
            event
            city
            state
            country
            from
            authors {
              first
              last
            }
          }
          theses {
            id
            title
            type
            pages
            date
            authors {
              first
              last
            }
            promotors {
              first
              last
            }
          }
          proceedings {
            id
            volume
            conference
            appeared
            edition
            proceedings
            seriesName
            publisher
            editors {
              first
              last
            }
          }
          rumps {
            id
            title
            pages
            from
            proceedings
            edition
            authors {
              first
              last
            }
          }
          books {
            id
            title
            publisher
            volume
            date
            pages
            seriesName
            authors {
              first
              last
            }
            editors {
              first
              last
            }
          }
          reports {
            id
            title
            pages
            date
            note
            authors {
              first
              last
            }
          }
          bookArticles {
            id
            title
            pages
            bookTitle
            date
            publisher
            seriesName
          }
          journalArticles {
            id
            title
            pages
            number
            from
            volume
            journalTitle
            authors {
              first
              last
            }
          }
          conferenceArticles {
            id
            title
            pages
            city
            state
            country
            volume
            from
            publisher
            conference
            series
            authors {
              first
              last
            }
            editors {
              first
              last
            }
          }
        }
      }
      statusHistory
      {
        id
        peopleType {
          id
          name
        }
        startDate
        endDate
      }
    }
  }`;

/* set person role */
export const SetPersonRole = (personId, role) => (`
  mutation {
    setRoleForPeople(
      peopleId: ${personId},
      role: ${role}
    ) {
      role
    }
  }
`);

/* person general */
export const CreationPerson = (person) => (`
    mutation{
      createPeople(
        people:{
          userid: "${person.userid}",
          firstname: "${person.firstname}",
          lastname: "${person.lastname}",
          email: "${person.email}",
          postal: "${person.postal}",
          room: "${person.room}",
          phone: "${person.phone}",
          needsDesk: ${person.needsDesk},
          active: ${person.active},
          nationality: ${person.nationality ? `"${person.nationality}"` : null},
          gender: "${person.gender}",
          ${person.birthDate && person.birthDate !== 'Invalid date' ? `birthDate: "${person.birthDate}"` : ''},
          speaksDutch: ${person.speaksDutch},
          privateAddress: "${person.privateAddress}",
          privatePhone: "${person.privatePhone}",
          mobile: "${person.mobile}",
          privateEmail: "${person.privateEmail}",
          website: "${person.website}",
          unumber: "${person.uNumber}",
          biography: """${person.biography}\n"""
        }
        ${person.kulType ? `, kulType: ${person.kulType}` : ''}
        ${person.partner ? `, partnerId: ${person.partner}` : ''}
      ) {
        id
        active
        biography
        birthDate
        email
        gender      
        firstname
        needsDesk
        nationality {
          id
          name
        }
        postal
        privateAddress
        privatePhone
        privateEmail
        website
        mobile
        room
        lastname
        speaksDutch
        phone
        kulType {
          id
          name
        } 
        uNumber
        userid    
        partner {
          id
          name
        }
      }      
    }`
);

export const MutationPerson = (person) => (`
    mutation{
      updatePeople(
        id:${person.id},
        people:{
          userid: "${person.userid}",
          firstname: "${person.firstname}",
          lastname: "${person.lastname}",
          email: "${person.email}",
          postal: "${person.postal}",
          room: "${person.room}",
          phone: "${person.phone}",
          needsDesk: ${person.needsDesk},
          active: ${person.active},
          nationality: ${person.nationality ? `"${person.nationality}"` : null},
          gender: "${person.gender}",
          ${person.birthDate && person.birthDate !== 'Invalid date' ? `birthDate: "${person.birthDate}"` : ''},
          speaksDutch: ${person.speaksDutch},
          privateAddress: "${person.privateAddress}",
          mobile: "${person.mobile}",
          privatePhone: "${person.privatePhone}",
          privateEmail: "${person.privateEmail}",
          website: "${person.website}",
          unumber: "${person.uNumber}",
          biography: """${person.biography}\n"""
        }
        ${person.kulType ? `, kulType: ${person.kulType}` : ''}
        ${person.partner ? `, partnerId: ${person.partner}` : ''}
      ) {
      id
      active
      biography
      birthDate
      email
      gender      
      firstname
      needsDesk
      nationality {
        id
        name
      }
      postal
      privateAddress
      privateEmail
      privatePhone
      website
      mobile
      room
      lastname
      speaksDutch
      phone
      kulType {
        id
        name
      } 
      uNumber
      userid    
      partner {
        id
        name
      }      
      }      
    }`
);

/* PhD section */
export const MutationPersonPhDData = (person) => `
mutation{
  updatePeoplePhdData(
    id: ${person.id},
    phdData: {
      preliminaryPhdTopic: "${person.preliminaryPhdTopic}",
      finalPhdTopic: "${person.finalPhdTopic}",
      phdEndDate: "${person.phdEndDate}",
      phdStartDate: "${person.phdStartDate}",
      nineMonthPresentation: "${person.nineMonthPresentation}",
      intermediatePresentationDate: "${person.intermediatePresentationDate}",
      privateDefenseDate: "${person.privateDefenseDate}",
      publicDefenseDate: "${person.publicDefenseDate}",
    },
    committee: ${JSON.stringify(person.phdCommitteeMembers)}
  ){
    id
      preliminaryPhdTopic
      finalPhdTopic
      phdEndDate
      phdStartDate
      nineMonthPresentation
      intermediatePresentationDate
      privateDefenseDate
      publicDefenseDate
      phdCommitteeMembers{
        id
        commiteeMember{
          id
          gn
          sn
        }
      }
   }
}`;

export const mutateCreateExternalPeople = (data) => (`
mutation{
  createExternalPeople(firstname: "${data.firstname}" , lastname: "${data.lastname}"){
    id
    first
    last
  }
}
`);

/* Alumnus section */
export const MutationPersonAlumnusData = (person) => `
mutation{
  updatePeopleAlumnusData(
    id: ${person.id},
    alumnusData: {
      alumnus: ${person.alumnus},
      formerPostdoc: ${person.formerPostdoc},
      formerColleague: ${person.formerColleague},
      hiddenWebsite: ${person.hiddenWebsite},
      currentSituation: ${person.currentSituation ? `"""${person.currentSituation}\n"""` : null},
      careerAfter: ${person.careerAfter ? `"${person.careerAfter}"` : null},
      currentUrl: ${person.currentUrl ? `"${person.currentUrl}"` : null},
    }
  ){
    alumnusData {
      alumnus
      formerPostdoc
      formerColleague
      hiddenWebsite
      currentSituation
      careerAfter
      currentUrl
     }
   }
}`;

/* internship section */
export const QueryPersonInternships = (id) => `
{
  getPeople (id:${id}){
    InternshipOrLongResearches {
        id
        description
        startDate
        endDate
        city
        country {
          id
          name
        }
        reason
        partner {
          id
          name
        }
      }
  }
}`;

export const MutationPersonAddInternship = (ior) => `
mutation{
  addInternshipOrLongResearchToPeople(
    internshipOrLongResearch: {
      description: """${ior.description}\n""",
      startDate: "${ior.startDate}",
      endDate: "${ior.endDate}",
      city:"${ior.city}",
      country: { id: ${ior.country}, name:"${ior.country.name}" },
      reason: "${ior.reason}",
      partner: ${ior.partner}
    }, 
    peopleid: ${ior.personid},
  ){
    internshipOrResearches {
      id
      description
      startDate
      endDate
      city
      country {
        id
        name
      }
      reason
      partner {
        id
        name
      }
    }
  }
}`;

export const MutationPersonUpdateInternship = (person) => `
  mutation{
    updateInternshipOrLongResearch(id: ${person.id}, internshipOrResearch:{
      startDate: "${person.startDate}",
      endDate: "${person.endDate}",
      city: "${person.city}",
      description: """${person.description}\n""",
      partner: ${person.partner},
      reason: ""
    }, country: ${person.country}){
      internshipOrResearches {
        id
        description
        startDate
        endDate
        city
        country {
          id
          name
        }
        partner {
          id
          name
        }
      }
      }
  }`;

export const MutationPersonRemoveInternship = (person) => `
  mutation{
    removeInternshipOrLongResearchFromPeople(
      internshipOrLongResearchId: ${person.id}, 
      peopleid: ${person.personid}){
        internshipOrResearches {
          id
          description
          startDate
          endDate
          city
          country {
            id
            name
          }
          reason
          partner {
            id
            name
          }
        }
      }
  }
`;

/* researchData section */
export const MutationUpdateResearchData = (rd) => `
mutation{
  updateResearchDataToPeople(researchData: {
    id: ${rd.id}
    degree: ${rd.degree ? `"""${rd.degree}\n"""` : null},
    institution: "${rd.institution}"
    researchDescription: ${rd.researchDescription ? `"""${rd.researchDescription}\n"""` : null},
    image: ${rd.image ? `"""${rd.image}\n"""` : null},
  }, peopleId: ${rd.personid}, zap: ${rd.zap}, promotor: ${rd.promotor}, country: ${rd.country}) {
    researchData{
      id
      institution
      researchDescription
      degree
      image
      country {
        id
        name
      }
      promotor {
        id
        gn
        sn
      }
      zapResponsible {
        id
        gn
        sn
      }
    }
  }
}`;


/* Awards section */
export const QueryPersonAwards = (vals) => `
  {
    peopleDetail (id:${vals.personid}) {
      id
      awards {
        id
        name
        venue
        title
        result
        date
        bestPaper
      }
    }
  }
`;

export const MutationAddAwardToPeople = (award) => `
mutation{
  addAwardToPeople(award: {
    date: "${award.date}",
    name: """${award.name}\n""",
    title: """${award.title}\n""",
    result: ${award.result ? `"""${award.result}\n"""` : null},
    bestPaper: ${award.bestPaper},
    venue: """${award.venue}\n""",
  }, peopleid: ${award.personid}){
    id
    awards {
      id
      date
      name
      title
      result
      bestPaper
       venue
    }
  }
}`;

export const MutationUpdateAward = (award) => `
mutation{
  updateAward(id: ${award.id}, award:{
    date: "${award.date}",
    name: """${award.name}\n""",
    title: """${award.title}\n""",
    result: ${award.result ? `"""${award.result}\n"""` : null},
    bestPaper: ${award.bestPaper},
    venue: """${award.venue}\n""",
  }){
  id
  awards {
    id
    name
    venue
    title
    result
    date
    bestPaper
    }
  }
}`;

export const MutationRemoveAwardFromPeople = (awardid, personid) => `
mutation{
  removeAwardFromPeople(awardId: ${personid}, peopleid: ${awardid}){
    id
    awards {
      id
      name
      venue
      title
      result
      date
      bestPaper
    }
  }
}`;

/* misc list queries */
export const QuerPersonProjects = (userid) => `
query{
  projectsByPeopleUserId(userId: "${userid}"){
    id
    name
    status
    role
  }
}`;

export const QueryZapList = `
query {
  zap{
    id
    gn
    sn
  }
}`;

export const QueryTeams = `
query {
  teams {
    id
    name
  }
}`;

export const QueryProgramVenues = `
query {
  programVenues{
    id
    name
    type
  }
}`;


export const MutationUpdatePeopleProfile = (data) => `
mutation{
  updatePeople(id: ${data.id}, people:{
    privateEmail: "${data.data.privateEmail}",
    privatePhone: "${data.data.privatePhone}",
    privateAddress: "${data.data.privateAddress}",
    nationality: "${data.data.nationality}",
    gender: "${data.data.gender}",
    birthDate: "${data.data.birthDate}",
    website: "${data.data.website}",
    biography: """${data.data.biography}\n"""
  }){
    id
    privateEmail
    privatePhone
    privateAddress
    nationality {
      id
      name
    }
    gender
    birthDate
    website
    biography
  }
}`;

/* PgmComm section */
export const MutationAddPgmComm = (pgmcomm) => `
mutation{
  addProgramCommitteeToPeople(programCommittee: {
    venue: ${pgmcomm.venue.id}
    year: ${pgmcomm.year}
    location: "${pgmcomm.location}"
    type: "${pgmcomm.venue.type}"
    role: "${pgmcomm.role}"
  }, peopleId:${pgmcomm.personid}) {
    programCommittees {
      id
      venue
      year
      location
      type
      role
      venueName
    }
  }
}`;

export const MutationUpdatePgmComm = (pgmcomm) => `
mutation{
  updateProgramCommitteeFromPeople(programCommittee: {
    id: ${pgmcomm.id}
    venue: ${pgmcomm.venue.id}
    year: ${pgmcomm.year}
    location: "${pgmcomm.location}"
    type: "${pgmcomm.venue.type}"
    role: "${pgmcomm.role}"
  }, peopleId:${pgmcomm.personid}) {
    programCommittees {
      id
      venue
      year
      location
      type
      role
      venueName
    }
  }
}`;

export const MutationRemovePgmComm = (pgmcomm) => `
mutation{
  removeProgramCommitteeFromPeople(programId:${pgmcomm.id}, peopleId:${pgmcomm.personid}) {
    programCommittees {
      id
      venue
      year
      location
      type
      role
      venueName
    }
  }
}`;

export const addStatusHistoryToPeople = (statusEntry, personId) => `
mutation{
  addStatusHistoryToPeople(status: {
    peopleTypeId: ${statusEntry.peopleTypeId}
    startDate: "${statusEntry.startDate}"
    endDate: "${statusEntry.endDate}"
  }, peopleid: ${personId}){
    statusHistory{
      id
    }
  }
}
`;

export const updateStatusHistory = (statusEntry) => `
mutation{
  updateStatusHistory(status: {
    id: "${statusEntry.id}"
    peopleTypeId: ${statusEntry.peopleTypeId}
    startDate: "${statusEntry.startDate}"
    endDate: "${statusEntry.endDate}"
  }){
    statusHistory{
      id
    }
  }
}
`;

export const removeStatusHistoryFromPeople = (statusId, personId) => `
mutation{
  removeStatusHistoryFromPeople(status:${statusId}, peopleid: ${personId}){
    statusHistory{
      id
    }
  }
}
`;

/* team membership section */
export const addTeamMembership = (peopleId, teamId) => `
mutation{
  addTeamToPeople(peopleId:${peopleId}, teamId: ${teamId}){
    id
  }
}`;

export const removeTeamMembership = (peopleId, teamId) => `
mutation {
  removeTeamFromPeople(peopleId:${peopleId}, teamId: ${teamId}){
    id
  }
}`;


export default {
  QueryPerson,
  SetPersonRole,
  CreationPerson,
  MutationPerson,
  MutationPersonPhDData,

  QueryPersonAwards,
  MutationAddAwardToPeople,
  MutationUpdateAward,
  MutationRemoveAwardFromPeople,

  QueryPersonInternships,
  MutationPersonAddInternship,
  MutationPersonUpdateInternship,
  MutationPersonRemoveInternship,

  QueryZapList,
  QueryTeams,
  QueryProgramVenues,

  addStatusHistoryToPeople,
  removeStatusHistoryFromPeople,
  addTeamMembership,
  removeTeamMembership,
};
