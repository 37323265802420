import React, { useEffect, useState } from 'react';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import {
  fetchAllEvents as fetchAllEventsAction,
  setFilters as setFiltersAction,
} from '../../../state/events/eventsActions';
import Loader from '../../../component/loader/loader';
import {
  deleteEvent as deleteEventAction,
} from '../../../state/event/eventActions';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  selectFilters,
  selectIsLoading,
  selectFilteredEvents,
} from '../../../state/events/selectors';
import { eventTypes } from '../../../config/optionValues';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Events = (props) => {
  const {
    loading,
    events,
    fetchAllEvents,
    deleteEvent,
    filters,
    setFilters,
  } = props;

  const history = useHistory();

  useEffect(() => {
    fetchAllEvents();
  }, [fetchAllEvents]);

  const [deleteEventInfo, setDeleteEventInfo] = useState(null);
  const onConfirmDelete = () => {
    deleteEvent(deleteEventInfo.id);
    setDeleteEventInfo(null);
  };

  const [typeState, setType] = useState(null);

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (row) => eventTypes.find((e) => e.value === row.value).label,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (cellInfo) => `${cellInfo.original.date ? (
        new Date(Date.parse(cellInfo.original.date)).toDateString()
      ) : ''}`,
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'Country',
      accessor: 'country',
    },
    {
      id: 'button',
      events,
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/admin/events/${row.original.id}`)}
          >
Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
            onClick={() => setDeleteEventInfo(row.original)}
          >
Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  const handleTypeChange = (type) => {
    const newFilters = { ...filters, type: type.value };
    const newType = eventTypes.find((t) => (
      t.value === type.value
    ));
    setType(newType);
    setFilters(newFilters);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Search on Title</Form.Label>
                      <Form.Control
                        placeholder=""
                        value={filters.title ?? ''}
                        onChange={(val) => setFilters({ ...filters, title: val.target.value })}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Select
                        options={[
                          { label: 'All', value: '' },
                          ...eventTypes,
                        ]}
                        value={typeState}
                        onChange={(val) => handleTypeChange(val)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={filters.startDate ?? ''}
                        onChange={(val) => setFilters({ ...filters, startDate: val ? moment(val).format('YYYY-MM-DD') : null })}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={filters.endDate ?? ''}
                        onChange={(val) => setFilters({ ...filters, endDate: val ? moment(val).format('YYYY-MM-DD') : null })}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => setFilters({
                          name: '', type: '', startDate: '', endDate: '',
                        })}
                        style={{ marginTop: '32px' }}
                      >
                        Clear
                      </Button>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Events
              <Button onClick={() => { history.push('/admin/events/new'); }} style={{ fontSize: '.8rem', marginLeft: '1rem', padding: '.2rem' }}>New..</Button>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loader />
              ) : (
                <ReactTable
                  columns={columns}
                  data={events}
                  pagination
                  defaultSorted={[
                    {
                      id: 'date',
                      desc: true,
                    },
                    {
                      id: 'title',
                      desc: true,
                    },
                  ]}
                  pageSizeOptions={defaultTablePageSizeOptions}
                  defaultPageSize={defaultTablePageSize}
                  minRows={0}
                  className="-striped -highlight"
                  getTrProps={() => ({
                    style: {
                      cursor: 'pointer',
                    },
                  })}
                />
              )}
              <DeleteConfirmation
                text={deleteEventInfo !== null ? `Delete event ${deleteEventInfo.title}` : ''}
                show={deleteEventInfo !== null}
                onCancel={() => setDeleteEventInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Events.propTypes = {
  loading: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchAllEvents: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading,
  events: selectFilteredEvents,
  filters: selectFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllEvents: () => dispatch(fetchAllEventsAction()),
  deleteEvent: (eventId) => dispatch(deleteEventAction(eventId)),
  setFilters: (filters) => dispatch(setFiltersAction(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
