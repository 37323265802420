import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_PATENT,
  createPatentError,
  createPatentSuccess,
  FETCH_PATENT,
  fetchPatentError,
  fetchPatentSuccess,
  fetchPatent as fetchPatentAction,
  LOAD_PATENT_REQUIREMENTS,
  loadPatentRequirementsError,
  loadPatentRequirementsSuccess,
  UPDATE_PATENT,
  updatePatentError,
  updatePatentSuccess,
} from './actions';
import {
  ensurePeople,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createPatent as createPatentMutation,
  QuerypatentById as fetchPatentQuery,
  updatePatent as updatePatentMutation,
} from '../../../../graphql/queries/publications';

export function* createPatent({ payload }) {
  const response = yield call(axios.post, '/', { query: createPatentMutation(payload) });
  yield put(createPatentSuccess());

  const { id } = response.data.data.createPatent;
  yield put(push(`/researcher/publications/updatePatent/${id}`));
  showSuccessMessage('Patent created successfully');
}

export function* fetchPatent({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchPatentQuery(payload.id) });
  yield put(fetchPatentSuccess(response.data.data.patentById));
}

export function* updatePatent({ payload }) {
  const response = yield call(axios.post, '/', { query: updatePatentMutation(payload) });
  yield put(updatePatentSuccess(response.data.data.updatePatent));
  showSuccessMessage('Patent updated successfully');
}

export function* loadPatent({ payload }) {
  if (payload.id) {
    yield put(fetchPatentAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadPatentRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
  ]);
}

export default function* () {
  yield takeLatest(
    CREATE_PATENT,
    catchErrors({
      onErrorAction: createPatentError,
      overrideErrorMessage: 'Could not create publication of type patent',
    })(createPatent),
  );

  yield takeLatest(
    FETCH_PATENT,
    catchErrors({
      onErrorAction: fetchPatentError,
      overrideErrorMessage: 'Could not fetch patent',
    })(fetchPatent),
  );

  yield takeLatest(
    LOAD_PATENT_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadPatentRequirementsError,
    })(loadPatent),
  );

  yield takeLatest(
    UPDATE_PATENT,
    catchErrors({
      onErrorAction: updatePatentError,
      overrideErrorMessage: 'Could not update publication of type patent',
    })(updatePatent),
  );
}
