import React, {useEffect, useState} from 'react';
import {merge} from 'lodash/fp';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {useParams} from 'react-router-dom';
import {ErrorMessage, Formik} from 'formik';
import {Button, Card, Col, Container, Form, Row,} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import {abstractOfTalkValidationSchema as validationSchema} from '../ValidationSchema';
import {Loader} from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';

import {
  createAbstractOfTalk as createAbstractOfTalkAction,
  loadAbstractOfTalkRequirements as loadAbstractOfTalkRequirementsAction,
  updateAbstractOfTalk as updateAbstractOfTalkAction,
} from './actions';
import {selectIsLoading, selectProjects, selectPublicationAbstractOfTalkDetails,} from './selectors';
import {selectConferences, selectProceedings} from '../../../../state/publication/selectors';
import {calculatePageCount} from '../../../../utils';
import ProceedingsSelector from '../../../../component/admin/publication/ProceedingsSelectorRow';

const AbstractOfTalk = ({
  proceedings,
  conferences,
  createAbstractOfTalk,
  isLoading,
  isResearcher,
  loadAbstractOfTalkRequirements,
  projects,
  abstractOfTalkInfo,
  updateAbstractOfTalk,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadAbstractOfTalkRequirements({ id, reset: true });
  }, [id, loadAbstractOfTalkRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadAbstractOfTalkRequirements({ reset: false });
    }
  }, [reloads, loadAbstractOfTalkRequirements]);

  const initialFormValues = merge({
    types: 'abstractOfTalk',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    proceedings: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    pages: '',
    pageCount: 0,
    proceedingsId: null,
  }, abstractOfTalkInfo);

  const handlePageChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('pages', value);
    setFieldValue('pageCount', calculatePageCount(value));
  };
  const handleAbstractOfTalkSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      type: 'abstractOfTalk',
    };

    if (id) {
      updateAbstractOfTalk({ ...updatedValues, id });
    } else {
      createAbstractOfTalk(updatedValues);
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleAbstractOfTalkSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="abstractOfTalk"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Abstract of Talk specific</Card.Header>
                    <Card.Body>
                      <ProceedingsSelector proceedings={proceedings} conferences={conferences} field="proceedingsId" />
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Page(s)</Form.Label>
                          <Form.Control
                            name="pages"
                            onChange={(event) => handlePageChange(event, setFieldValue)}
                            value={values.pages}
                            className={touched.pages && errors.pages ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="pages" />
                          </div>
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Page Count</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Fill pages..."
                            value={values.pageCount}
                            className={values.pageCount && !parseInt(values.pageCount, 10) ? 'error' : null}
                          />
                          <ErrorMessage name="pageCount" />
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }} />
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="abstractOfTalk"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={abstractOfTalkInfo.visibility}
        />
      )}
    </Container>
  );
};

AbstractOfTalk.propTypes = {
  abstractOfTalkInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  conferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  proceedings: PropTypes.arrayOf(PropTypes.object).isRequired,
  createAbstractOfTalk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadAbstractOfTalkRequirements: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAbstractOfTalk: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  abstractOfTalkInfo: selectPublicationAbstractOfTalkDetails,
  conferences: selectConferences,
  proceedings: selectProceedings,
  isLoading: selectIsLoading,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createAbstractOfTalk: createAbstractOfTalkAction,
  loadAbstractOfTalkRequirements: loadAbstractOfTalkRequirementsAction,
  updateAbstractOfTalk: updateAbstractOfTalkAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AbstractOfTalk);
