import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes, { object } from 'prop-types';
import { Table } from 'react-bootstrap';
import { FETCH_ONE_PERSON_PROJECTS } from '../../../state/person/personActions';
import Loader from '../../loader/loader';

const EditPeopleProjectsForm = ({
  projects,
  isLoading,
  fetchProjects,
  userId,
}) => {
  useEffect(() => {
    if (userId) {
      fetchProjects(userId);
    }
  }, [userId, fetchProjects]);

  return (
    <div>
      {
        isLoading
          ? <Loader />
          : (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Project Status</th>
                    <th>Role in Project</th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project) => (
                    <tr key={project.id}>
                      <td>{project.name}</td>
                      <td>{project.status}</td>
                      <td>{project.role}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )
      }
    </div>
  );
};

EditPeopleProjectsForm.propTypes = {
  projects: PropTypes.arrayOf(object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  projects: state.person.projects,
  isLoading: state.person.isProjectsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: (data) => dispatch(FETCH_ONE_PERSON_PROJECTS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleProjectsForm);
