import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Loader } from '../../../component/index';
import {
  fetchAllFunders as fetchAllFundersAction,
} from '../../../state/funders/fundersActions';
import {
  fetchOneFunder as fetchOneFunderAction,
  updateFunder as updateFunderAction,
} from '../../../state/funder/funderActions';
import '../../../styles/forms.scss';

function EditFunder(props) {
  const { id } = useParams();
  const {
    loading,
    funder,
    funders,
    fetchFunder,
    fetchAllFunders,
    updateFunder,
    isFundersLoading,
  } = props;

  useEffect(() => {
    fetchAllFunders();
    if (id) {
      fetchFunder({ id });
    }
  }, [
    fetchAllFunders,
    fetchFunder,
    id,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string()
      .notOneOf(
        funders.map((f) => f.name).filter((elem) => elem !== funder.name),
        'Name already exists',
      )
      .required('The funder name is mandatory'),
    parent: yup.string()
      .nullable(),
    belgian: yup.boolean(),
    european: yup.boolean(),
  });

  const funderOptions = funders.map((f) => ({ label: f.name, value: f.id }));

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{id ? 'Edit funder' : 'Create funder'}</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <>
                    <Formik
                      initialValues={{
                        id: funder.id || undefined,
                        name: funder.name || '',
                        parent: funder.parent ? funder.parent.id : '',
                        belgian: funder.belgian || false,
                        european: funder.european || false,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        updateFunder({ ...values });
                        setSubmitting(false);
                      }}
                    >
                      { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Funder name</Form.Label>
                                <Form.Control
                                  placeholder="Funder name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.name && errors.name ? 'error' : null}
                                />
                                { touched.name && errors.name ? (<div className="error-message">{errors.name}</div>) : null}
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Parent</Form.Label>
                                <Select
                                  name="parent"
                                  options={funderOptions}
                                  onChange={(val) => setFieldValue('parent', val.value)}
                                  placeholder={isFundersLoading ? 'Loading...' : 'Select...'}
                                  isDisabled={isFundersLoading}
                                  value={funderOptions.find((s) => s.value === values.parent)}
                                  className={touched.parent && errors.parent ? 'error' : null}
                                />
                                { touched.parent && errors.parent ? (<div className="error-message">{errors.parent}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group controlId="european">
                                <Form.Check
                                  checked={values.european}
                                  type="checkbox"
                                  label="European"
                                  name="european"
                                  onChange={(event) => setFieldValue('european', event.target.checked)}
                                />
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group controlId="belgian">
                                <Form.Check
                                  checked={values.belgian}
                                  type="checkbox"
                                  label="Belgian"
                                  name="belgian"
                                  onChange={(event) => setFieldValue('belgian', event.target.checked)}
                                />
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Button variant="primary" type="submit" disabled={isSubmitting || errors.name}>
                                  {id ? 'Save' : 'Create'}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditFunder.propTypes = {
  loading: PropTypes.bool.isRequired,
  isFundersLoading: PropTypes.bool.isRequired,
  funder: PropTypes.objectOf(PropTypes.any).isRequired,
  funders: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchFunder: PropTypes.func.isRequired,
  fetchAllFunders: PropTypes.func.isRequired,
  updateFunder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.funder.isLoading,
  isFundersLoading: state.funders.isLoading,
  funder: state.funder.data,
  funders: state.funders.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFunder: (data) => dispatch(fetchOneFunderAction(data)),
  fetchAllFunders: () => dispatch(fetchAllFundersAction()),
  updateFunder: (data) => dispatch(updateFunderAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFunder);
