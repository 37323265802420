import React from 'react';
import {
  Row,
  Col,
  Card,
  Nav,
  Tab,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ByYearWrapper from '../../publicationFormatTypes/ByYearWrapper';
import {
  SET_PUBLICATION_FILTER_AUTHORS,
  SET_PUBLICATION_FILTER_PUBLICATIONTYPES,
  SET_PUBLICATION_FILTER_YEARS,
} from '../../../state/publication/publicationActions';
import ByAuthorWrapper from '../../publicationFormatTypes/ByAuthorWrapper';
import ByCategoryWrapper from '../../publicationFormatTypes/ByCategoryWrapper';


const PublicationsTabs = (props) => {
  const {
    publicationByYear, publicationByAuthor, publicationByCategory, canEdit,
  } = props;
  return (
    <Row>
      <Col>
        <Card>
          <Tab.Container id="left-tabs-example" defaultActiveKey="year">
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="year">By Year</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="category">By Category</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="author">By Author</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="year">
                  <ByYearWrapper pby={publicationByYear} canEdit={canEdit} />
                </Tab.Pane>
                <Tab.Pane eventKey="category">
                  <ByCategoryWrapper pbc={publicationByCategory} canEdit={canEdit} />
                </Tab.Pane>
                <Tab.Pane eventKey="author">
                  <ByAuthorWrapper pba={publicationByAuthor} canEdit={canEdit} />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Col>
    </Row>
  );
};
PublicationsTabs.propTypes = {
  publicationByYear: PropTypes.arrayOf(PropTypes.any).isRequired,
  publicationByAuthor: PropTypes.arrayOf(PropTypes.any).isRequired,
  publicationByCategory: PropTypes.objectOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.publication.isLoading,
  authors: state.people.authors,
  years: state.app.years,
  publicationTypes: state.app.publicationTypes,
  publicationByYear: state.publication.publicationByYear,
  publicationByAuthor: state.publication.publicationByAuthor,
  publicationByCategory: state.publication.publicationByCategory,
  filter: state.publication.filter,
});

const mapDispatchToProps = (dispatch) => ({
  SET_PUBLICATION_FILTER_AUTHORS: (value) => dispatch(SET_PUBLICATION_FILTER_AUTHORS(value)),
  SET_PUBLICATION_FILTER_YEARS: (value) => dispatch(SET_PUBLICATION_FILTER_YEARS(value)),
  SET_PUBLICATION_FILTER_PUBLICATIONTYPES: (value) => dispatch(
    SET_PUBLICATION_FILTER_PUBLICATIONTYPES(value),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicationsTabs);
