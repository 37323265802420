import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import Axios from 'axios';
import axios from '../../axios';
import { people } from '../../graphql/queries';
import {
  FETCH_ALL_PEOPLE_SUCCESS,
  FETCH_ALL_PEOPLE_ERROR,
  FETCH_ALL_FILTERED_PEOPLE_SUCCESS,
  FETCH_ALL_FILTERED_PEOPLE_ERROR,
  FETCH_ALL_PEOPLE_TYPES_ERROR,
  FETCH_ALL_PEOPLE_TYPES_SUCCESS,
  FETCH_ALL_PEOPLE_TEAMS_ERROR,
  FETCH_ALL_PEOPLE_TEAMS_SUCCESS,
  FETCH_ALL_PEOPLE_INCL_EXTERNAL,
  fetchAllPeopleInclExternalError,
  fetchAllPeopleInclExternalSuccess,
  FETCH_ALL_PEOPLE_PROMOTERS_SUCCESS,
  FETCH_ALL_PEOPLE_PROMOTERS_ERROR,
} from './peopleActions';
import catchErrors from '../../graphql/sagas';

const {
  QueryAllPeople,
  QueryAllFilteredPeople,
  QueryPeopleTypes,
  QueryPeoplePromoters,
  QueryTeams,
  QueryAllPeopleInclExternal,
} = people;
export const getFilter = (state) => state.people.filter;

let cancelToken;

function* getAllFilteredPeople() {
  try {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const filter = yield select(getFilter);
    const response = yield call(axios.post, '/', { query: QueryAllFilteredPeople(filter) }, { cancelToken: cancelToken.token });
    yield put(FETCH_ALL_FILTERED_PEOPLE_SUCCESS(response.data.data.filterPeopleFast));
  } catch (e) {
    if (!Axios.isCancel(e)) {
      yield put(FETCH_ALL_FILTERED_PEOPLE_ERROR(e));
    }
  }
}

export function* fetchFilteredPeoplesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_FILTERED_PEOPLE');
    yield fork(getAllFilteredPeople, action);
  }
}

function* getAllPromoters() {
  try {
    const response = yield call(axios.post, '/', { query: QueryPeoplePromoters });
    yield put(FETCH_ALL_PEOPLE_PROMOTERS_SUCCESS(response.data.data.peoplePromoters));
  } catch (e) {
    yield put(FETCH_ALL_PEOPLE_PROMOTERS_ERROR(e));
  }
}

export function* fetchAllPromotersSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PEOPLE_PROMOTERS');
    yield fork(getAllPromoters, action);
  }
}

function* getAllPeople() {
  try {
    const response = yield call(axios.post, '/', { query: QueryAllPeople });

    yield put(FETCH_ALL_PEOPLE_SUCCESS(response.data.data.filterPeopleFast));
  } catch (e) {
    yield put(FETCH_ALL_PEOPLE_ERROR(e));
  }
}

export function* fetchPeoplesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PEOPLE');
    yield fork(getAllPeople, action);
  }
}

function* getAllPeopleTypes() {
  try {
    const response = yield call(axios.post, '/', { query: QueryPeopleTypes });
    yield put(FETCH_ALL_PEOPLE_TYPES_SUCCESS(response.data.data.peopleTypes));
  } catch (e) {
    yield put(FETCH_ALL_PEOPLE_TYPES_ERROR(e));
  }
}

export function* fetchAllPeopleTypesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PEOPLE_TYPES');
    yield fork(getAllPeopleTypes, action);
  }
}

function* getAllTeams() {
  try {
    const response = yield call(axios.post, '/', { query: QueryTeams });
    yield put(FETCH_ALL_PEOPLE_TEAMS_SUCCESS(response.data.data.teams));
  } catch (e) {
    yield put(FETCH_ALL_PEOPLE_TEAMS_ERROR(e));
  }
}

export function* fetchTeamsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PEOPLE_TEAMS');
    yield fork(getAllTeams, action);
  }
}

export function* getAllPeopleInclExternal() {
  const response = yield call(axios.post, '/', { query: QueryAllPeopleInclExternal });
  yield put(fetchAllPeopleInclExternalSuccess(response.data.data.allPeoplePeople));
}

export default function* () {
  yield takeLatest(
    FETCH_ALL_PEOPLE_INCL_EXTERNAL,
    catchErrors({
      onErrorAction: fetchAllPeopleInclExternalError,
      overrideErrorMessage: 'Unable to retrieve all people',
    })(getAllPeopleInclExternal),
  );
}
