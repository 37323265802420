import {
  CREATE_BOOK,
  CREATE_BOOK_ERROR,
  CREATE_BOOK_SUCCESS,
  FETCH_BOOK,
  FETCH_BOOK_ERROR,
  FETCH_BOOK_SUCCESS,
  LOAD_BOOK_REQUIREMENTS_SUCCESS,
  UPDATE_BOOK,
  UPDATE_BOOK_ERROR,
  UPDATE_BOOK_SUCCESS,
} from './actions';
import {calculatePageCount} from '../../../../utils';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateBookRespones = (response) => ({
  types: 'book',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor?.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  volume: response.volume,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
  isbn: response.isbn,
  publisher: response.publisher,
  country: response.country,
  editors: response.editors.map((editor) => ({
    value: editor.id, label: `${editor.last}, ${editor.first}`,
  })),
  projects: response.projects,
});

const publicationBook = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_BOOK_SUCCESS:
    case FETCH_BOOK_SUCCESS:
      return { ...state, details: dehydrateBookRespones(action.payload), isLoading: false };

    case CREATE_BOOK_ERROR:
    case UPDATE_BOOK_ERROR:
    case FETCH_BOOK_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_BOOK_SUCCESS:
    case LOAD_BOOK_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_BOOK:
    case FETCH_BOOK:
    case UPDATE_BOOK:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationBook;
