export const ADD_HISTORY_STATUS = 'ADD_HISTORY_STATUS';
export const ADD_HISTORY_STATUS_ERROR = 'ADD_HISTORY_STATUS_ERROR';
export const ADD_HISTORY_STATUS_SUCCESS = 'ADD_HISTORY_STATUS_SUCCESS';
export const UPDATE_HISTORY_STATUS = 'UPDATE_HISTORY_STATUS';
export const UPDATE_HISTORY_STATUS_ERROR = 'UPDATE_HISTORY_STATUS_ERROR';
export const UPDATE_HISTORY_STATUS_SUCCESS = 'UPDATE_HISTORY_STATUS_SUCCESS';
export const ADD_MEMBERSHIP = 'ADD_MEMBERSHIP';
export const ADD_MEMBERSHIP_ERROR = 'ADD_MEMBERSHIP_ERROR';
export const ADD_MEMBERSHIP_SUCCESS = 'ADD_MEMBERSHIP_SUCCESS';
export const REMOVE_HISTORY_STATUS = 'REMOVE_HISTORY_STATUS';
export const REMOVE_HISTORY_STATUS_ERROR = 'REMOVE_HISTORY_STATUS_ERROR';
export const REMOVE_HISTORY_STATUS_SUCCESS = 'REMOVE_HISTORY_STATUS_SUCCESS';
export const REMOVE_MEMBERSHIP = 'REMOVE_MEMBERSHIP';
export const REMOVE_MEMBERSHIP_ERROR = 'REMOVE_MEMBERSHIP_ERROR';
export const REMOVE_MEMBERSHIP_SUCCESS = 'REMOVE_MEMBERSHIP_SUCCESS';

export const FETCH_PERSON_AWARD = 'FETCH_PERSON_AWARD';
export const FETCH_PERSON_AWARD_SUCCESS = 'FETCH_PERSON_AWARD_SUCCESS';
export const FETCH_PERSON_AWARD_ERROR = 'FETCH_PERSON_AWARD_ERROR';
export const MUTATE_PERSON_ADD_AWARD = 'MUTATE_PERSON_ADD_AWARD';
export const MUTATE_PERSON_ADD_AWARD_SUCCESS = 'MUTATE_PERSON_ADD_AWARD_SUCCESS';
export const MUTATE_PERSON_ADD_AWARD_ERROR = 'MUTATE_PERSON_ADD_AWARD_ERROR';
export const MUTATE_PERSON_REMOVE_AWARD = 'MUTATE_PERSON_REMOVE_AWARD';
export const MUTATE_PERSON_REMOVE_AWARD_SUCCESS = 'MUTATE_PERSON_REMOVE_AWARD_SUCCESS';
export const MUTATE_PERSON_REMOVE_AWARD_ERROR = 'MUTATE_PERSON_REMOVE_AWARD_ERROR';
export const MUTATE_PERSON_UPDATE_AWARD = 'MUTATE_PERSON_UPDATE_AWARD';
export const MUTATE_PERSON_UPDATE_AWARD_SUCCESS = 'MUTATE_PERSON_UPDATE_AWARD_SUCCESS';
export const MUTATE_PERSON_UPDATE_AWARD_ERROR = 'MUTATE_PERSON_UPDATE_AWARD_ERROR';

export const CLEAR_PERSON_FORM = () => ({ type: 'CLEAR_PERSON_FORM' });

// CREATE EXTERNAL
export const CREATE_EXTERNAL_PEOPLE = (data) => ({ type: 'CREATE_EXTERNAL_PEOPLE', data });
export const CREATE_EXTERNAL_PEOPLE_SUCCESS = (data) => ({ type: 'CREATE_EXTERNAL_PEOPLE_SUCCESS', data });
export const CREATE_EXTERNAL_PEOPLE_ERROR = (error) => ({ type: 'CREATE_EXTERNAL_PEOPLE_ERROR', error });

// FETCH PERSON
export const FETCH_ONE_PERSON = (data) => ({ type: 'FETCH_ONE_PERSON', data });
export const FETCH_ONE_PERSON_SUCCESS = (data) => ({ type: 'FETCH_ONE_PERSON_SUCCESS', data });
export const FETCH_ONE_PERSON_ERROR = (error) => ({ type: 'FETCH_ONE_PERSON_ERROR', error });
// SET PERSON ROLE
export const SET_PERSON_ROLE = (data) => ({ type: 'SET_PERSON_ROLE', data });
export const SET_PERSON_ROLE_SUCCESS = (data) => ({ type: 'SET_PERSON_ROLE_SUCCESS', data });
export const SET_PERSON_ROLE_ERROR = (error) => ({ type: 'SET_PERSON_ROLE_ERROR', error });
// MUTATE PERSON
export const MUTATE_PERSON = (data) => ({ type: 'MUTATE_PERSON', data });
export const MUTATE_PERSON_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_SUCCESS', data });
export const MUTATE_PERSON_ERROR = (error) => ({ type: 'MUTATE_PERSON_ERROR', error });
// CREATE PERSON
export const CREATE_PERSON = (data) => ({ type: 'CREATE_PERSON', data });
export const CREATE_PERSON_SUCCESS = (data) => ({ type: 'CREATE_PERSON_SUCCESS', data });
export const CREATE_PERSON_ERROR = (error) => ({ type: 'CREATE_PERSON_ERROR', error });
// FETCH PROJECT
export const FETCH_ONE_PERSON_PROJECTS = (data) => ({ type: 'FETCH_ONE_PERSON_PROJECTS', data });
export const FETCH_ONE_PERSON_PROJECTS_SUCCESS = (data) => ({ type: 'FETCH_ONE_PERSON_PROJECTS_SUCCESS', data });
export const FETCH_ONE_PERSON_PROJECTS_ERROR = (error) => ({ type: 'FETCH_ONE_PERSON_PROJECTS_ERROR', error });
// MUTATE PHD
export const MUTATE_PERSON_PHD = (data) => ({ type: 'MUTATE_PERSON_PHD', data });
export const MUTATE_PERSON_PHD_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_PHD_SUCCESS', data });
export const MUTATE_PERSON_PHD_ERROR = (error) => ({ type: 'MUTATE_PERSON_PHD_ERROR', error });
// MUTATE ALUMNUS DATA
export const MUTATE_PERSON_ALUMNUS_DATA = (data) => ({ type: 'MUTATE_PERSON_ALUMNUS_DATA', data });
export const MUTATE_PERSON_ALUMNUS_DATA_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_ALUMNUS_DATA_SUCCESS', data });
export const MUTATE_PERSON_ALUMNUS_DATA_ERROR = (error) => ({ type: 'MUTATE_PERSON_ALUMNUS_DATA_ERROR', error });
// INTERNSHIP
export const FETCH_PERSON_INTERNSHIP = (data) => ({ type: 'FETCH_PERSON_INTERNSHIP', data });
export const FETCH_PERSON_INTERNSHIP_SUCCESS = (data) => ({ type: 'FETCH_PERSON_INTERNSHIP_SUCCESS', data });
export const FETCH_PERSON_INTERNSHIP_ERROR = (error) => ({ type: 'FETCH_PERSON_INTERNSHIP_ERROR', error });

export const MUTATE_PERSON_UPDATE_INTERNSHIP = (data) => ({ type: 'MUTATE_PERSON_UPDATE_INTERNSHIP', data });
export const MUTATE_PERSON_UPDATE_INTERNSHIP_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_UPDATE_INTERNSHIP_SUCCESS', data });
export const MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR = (error) => ({ type: 'MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR', error });

export const MUTATE_PERSON_ADD_INTERNSHIP = (data) => ({ type: 'MUTATE_PERSON_ADD_INTERNSHIP', data });
export const MUTATE_PERSON_ADD_INTERNSHIP_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_ADD_INTERNSHIP_SUCCESS', data });
export const MUTATE_PERSON_ADD_INTERNSHIP_ERROR = (error) => ({ type: 'MUTATE_PERSON_ADD_INTERNSHIP_ERROR', error });

export const MUTATE_PERSON_REMOVE_INTERNSHIP = (data) => ({ type: 'MUTATE_PERSON_REMOVE_INTERNSHIP', data });
export const MUTATE_PERSON_REMOVE_INTERNSHIP_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_REMOVE_INTERNSHIP_SUCCESS', data });
export const MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR = (error) => ({ type: 'MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR', error });
// RESEARCHDATA
export const MUTATE_PERSON_UPDATE_RESEARCHDATA = (data) => ({ type: 'MUTATE_PERSON_UPDATE_RESEARCHDATA', data });
export const MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS', data });
export const MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR = (error) => ({ type: 'MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR', error });
export const UPLOAD_RESEARCHDATA_IMAGE = (data) => ({ type: 'UPLOAD_RESEARCHDATA_IMAGE', data });
export const UPLOAD_RESEARCHDATA_IMAGE_SUCCESS = (): ProfileAction => ({ type: 'UPLOAD_RESEARCHDATA_IMAGE_SUCCESS' });
export const UPLOAD_RESEARCHDATA_IMAGE_ERROR = (error): ProfileAction => ({ type: 'UPLOAD_RESEARCHDATA_IMAGE_ERROR', error });

// PGMCOMM
export const FETCH_ALL_PGMCOMM_VENUES = (data) => ({ type: 'FETCH_ALL_PGMCOMM_VENUES', data });
export const FETCH_ALL_PGMCOMM_VENUES_SUCCESS = (data) => ({ type: 'FETCH_ALL_PGMCOMM_VENUES_SUCCESS', data });
export const FETCH_ALL_PGMCOMM_VENUES_ERROR = (data) => ({ type: 'FETCH_ALL_PGMCOMM_VENUES_ERROR', data });

export const MUTATE_PERSON_UPDATE_PGMCOMM = (data) => ({ type: 'MUTATE_PERSON_UPDATE_PGMCOMM', data });
export const MUTATE_PERSON_UPDATE_PGMCOMM_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_UPDATE_PGMCOMM_SUCCESS', data });
export const MUTATE_PERSON_UPDATE_PGMCOMM_ERROR = (error) => ({ type: 'MUTATE_PERSON_UPDATE_PGMCOMM_ERROR', error });

export const MUTATE_PERSON_ADD_PGMCOMM = (data) => ({ type: 'MUTATE_PERSON_ADD_PGMCOMM', data });
export const MUTATE_PERSON_ADD_PGMCOMM_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_ADD_PGMCOMM_SUCCESS', data });
export const MUTATE_PERSON_ADD_PGMCOMM_ERROR = (error) => ({ type: 'MUTATE_PERSON_ADD_PGMCOMM_ERROR', error });

export const MUTATE_PERSON_REMOVE_PGMCOMM = (data) => ({ type: 'MUTATE_PERSON_REMOVE_PGMCOMM', data });
export const MUTATE_PERSON_REMOVE_PGMCOMM_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_REMOVE_PGMCOMM_SUCCESS', data });
export const MUTATE_PERSON_REMOVE_PGMCOMM_ERROR = (error) => ({ type: 'MUTATE_PERSON_REMOVE_PGMCOMM_ERROR', error });

// AWARD
export const fetchPersonAward = (data) => ({ type: FETCH_PERSON_AWARD, data });
export const fetchPersonAwardError = (error) => ({ type: FETCH_PERSON_AWARD_ERROR, error });
export const fetchPersonAwardSuccess = (data) => ({ type: FETCH_PERSON_AWARD_SUCCESS, data });

export const mutatePersonAddAward = (data) => ({ type: MUTATE_PERSON_ADD_AWARD, data });
export const mutatePersonAddAwardError = (error) => ({
  type: MUTATE_PERSON_ADD_AWARD_ERROR,
  error,
});
export const mutatePersonAddAwardSuccess = (data) => ({
  type: MUTATE_PERSON_ADD_AWARD_SUCCESS,
  data,
});

export const mutatePersonRemoveAward = (data) => ({ type: MUTATE_PERSON_REMOVE_AWARD, data });
export const mutatePersonRemoveAwardError = (error) => ({
  type: MUTATE_PERSON_REMOVE_AWARD_ERROR,
  error,
});
export const mutatePersonRemoveAwardSuccess = (data) => ({
  type: MUTATE_PERSON_REMOVE_AWARD_SUCCESS,
  data,
});

export const mutatePersonUpdateAward = (data) => ({ type: MUTATE_PERSON_UPDATE_AWARD, data });
export const mutatePersonUpdateAwardError = (error) => ({
  type: MUTATE_PERSON_UPDATE_AWARD_ERROR,
  error,
});
export const mutatePersonUpdateAwardSuccess = (data) => ({
  type: MUTATE_PERSON_UPDATE_AWARD_SUCCESS,
  data,
});

// Promotors
export const FETCH_PROMOTORS_SUCCESS = (data) => ({ type: 'FETCH_PROMOTORS_SUCCESS', data });
export const FETCH_PROMOTORS_ERROR = (error) => ({ type: 'FETCH_PROMOTORS_ERROR', error });

// Zaps
export const FETCH_ZAPS = (data) => ({ type: 'FETCH_ZAPS', data });
export const FETCH_ZAPS_SUCCESS = (data) => ({ type: 'FETCH_ZAPS_SUCCESS', data });
export const FETCH_ZAPS_ERROR = (error) => ({ type: 'FETCH_ZAPS_ERROR', error });

// Teams
export const FETCH_TEAMS = (data) => ({ type: 'FETCH_TEAMS', data });
export const FETCH_TEAMS_SUCCESS = (data) => ({ type: 'FETCH_TEAMS_SUCCESS', data });
export const FETCH_TEAMS_ERROR = (error) => ({ type: 'FETCH_TEAMS_ERROR', error });

// MUTATE PERSON PROFILE
export const MUTATE_PERSON_PROFILE = (data) => ({ type: 'MUTATE_PERSON_PROFILE', data });
export const MUTATE_PERSON_PROFILE_SUCCESS = (data) => ({ type: 'MUTATE_PERSON_PROFILE_SUCCESS', data });
export const MUTATE_PERSON_PROFILE_ERROR = (error) => ({ type: 'MUTATE_PERSON_PROFILE_ERROR', error });

export const FETCH_PERSON_PUBLICATIONS = 'FETCH_PERSON_PUBLICATIONS';
export const FETCH_PERSON_PUBLICATIONS_ERROR = 'FETCH_PERSON_PUBLICATIONS_ERROR';
export const FETCH_PERSON_PUBLICATIONS_SUCCESS = 'FETCH_PERSON_PUBLICATIONS_SUCCESS';

export const fetchPersonPublications = (personId) => ({
  type: FETCH_PERSON_PUBLICATIONS, personId,
});
export const fetchPersonPublicationsError = (error) => ({
  type: FETCH_PERSON_PUBLICATIONS_ERROR,
  error,
});
export const fetchPersonPublicationsSuccess = (data) => ({
  type: FETCH_PERSON_PUBLICATIONS_SUCCESS, data,
});

export const addHistoryStatus = (data) => ({ type: ADD_HISTORY_STATUS, data });
export const addHistoryStatusError = (error) => ({ type: ADD_HISTORY_STATUS_ERROR, error });
export const addHistoryStatusSuccess = () => ({ type: ADD_HISTORY_STATUS_SUCCESS });
export const updateHistoryStatus = (data) => ({ type: UPDATE_HISTORY_STATUS, data });
export const updateHistoryStatusError = (error) => ({ type: UPDATE_HISTORY_STATUS_ERROR, error });
export const updateHistoryStatusSuccess = () => ({ type: UPDATE_HISTORY_STATUS_SUCCESS });

export const removeHistoryStatus = (personId, statusId) => ({
  type: REMOVE_HISTORY_STATUS,
  personId,
  statusId,
});
export const removeHistoryStatusError = (error) => ({ type: REMOVE_HISTORY_STATUS_ERROR, error });
export const removeHistoryStatusSuccess = () => ({ type: REMOVE_HISTORY_STATUS_SUCCESS });

export const addMembership = (personId, teamId) => ({
  type: ADD_MEMBERSHIP,
  personId,
  teamId,
});
export const addMembershipError = (error) => ({ type: ADD_MEMBERSHIP_ERROR, error });
export const addMembershipSuccess = () => ({ type: ADD_MEMBERSHIP_SUCCESS });

export const removeMembership = (personId, teamId) => ({
  type: REMOVE_MEMBERSHIP,
  personId,
  teamId,
});
export const removeMembershipError = (error) => ({ type: REMOVE_MEMBERSHIP_ERROR, error });
export const removeMembershipSuccess = () => ({ type: REMOVE_MEMBERSHIP_SUCCESS });
