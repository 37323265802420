
export const QueryAllCountries = `
{
    countries {
      id
      name
      code
     }
}
`;

export const QueryOneCountry = (id) => `
query {
    country (id:"${id}"){
        id
        name
        code
    }
}`;

export const MutateCreateCountry = (country) => `
mutation {
    createCountry (country: { id: null, name:"${country.name}", code:"${country.code}"}) {
      id
      name
      code
    }
}`;

export const MutateUpdateCountry = (country) => `
mutation {
    updateCountry (id:"${country.id}", country: { name:"${country.name}", code:"${country.code}"}) {
      id
      name
      code
    }
}`;

export const MutateDeleteCountry = (countryId) => `
mutation {
  deleteCountry(id:"${countryId}")
}`;
