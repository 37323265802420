import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_REPORT,
  createReportError,
  createReportSuccess,
  FETCH_REPORT,
  fetchReportError,
  fetchReportSuccess,
  fetchReport as fetchReportAction,
  LOAD_REPORT_REQUIREMENTS,
  loadReportRequirementsError,
  loadReportRequirementsSuccess,
  UPDATE_REPORT,
  updateReportError,
  updateReportSuccess,
} from './actions';
import {
  ensurePeople,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createReport as createReportMutation,
  QueryreportById as fetchReportQuery,
  updateReport as updateReportMutation,
} from '../../../../graphql/queries/publications';

export function* createReport({ payload }) {
  const response = yield call(axios.post, '/', { query: createReportMutation(payload) });
  yield put(createReportSuccess());

  const { id } = response.data.data.createReport;
  yield put(push(`/researcher/publications/updateReport/${id}`));
  showSuccessMessage('Report created successfully');
}

export function* fetchReport({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchReportQuery(payload.id) });
  yield put(fetchReportSuccess(response.data.data.reportById));
}

export function* updateReport({ payload }) {
  const response = yield call(axios.post, '/', { query: updateReportMutation(payload) });
  yield put(updateReportSuccess(response.data.data.updateReport));
  showSuccessMessage('Report updated successfully');
}

export function* loadReport({ payload }) {
  if (payload.id) {
    yield put(fetchReportAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadReportRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
  ]);
}

export default function* () {
  yield takeLatest(
    CREATE_REPORT,
    catchErrors({
      onErrorAction: createReportError,
      overrideErrorMessage: 'Could not create publication of type report',
    })(createReport),
  );

  yield takeLatest(
    FETCH_REPORT,
    catchErrors({
      onErrorAction: fetchReportError,
      overrideErrorMessage: 'Could not fetch report',
    })(fetchReport),
  );

  yield takeLatest(
    LOAD_REPORT_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadReportRequirementsError,
    })(loadReport),
  );

  yield takeLatest(
    UPDATE_REPORT,
    catchErrors({
      onErrorAction: updateReportError,
      overrideErrorMessage: 'Could not update publication of type report',
    })(updateReport),
  );
}
